import React, { useState, useEffect } from "react";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Tiles } from "components/layouts/Tiles";
import { useIdle } from "react-use";
import { useStore } from "hooks/useStore";
import { useApplicationContext } from "hooks/useApplicationContext";
import { getProfile } from "lib/api/profile";
import { withTranslation } from "react-i18next";

const minutesToMs = (minutes) => minutes * 60 * 1000;
const IDLE_TIME_MS = minutesToMs(10);

export const IdleTracker = withTranslation()(({ t }) => {
  const isIdle = useIdle(IDLE_TIME_MS);
  const [isOpen, setIsOpen] = useState(null);
  const { currentUser } = useApplicationContext();
  const isSessionExpired = useStore((state) => state.sessionExpired);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (isIdle) {
      setIsOpen(true);
    }
  }, [isIdle, currentUser]);

  if (isSessionExpired) {
    return null;
  }

  const reactivateSession = async () => {
    try {
      await getProfile({ promptSessionExpired: true });
    } catch (e) {
      console.error(e);
    }
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        title="You are idle"
        onClose={() => setIsOpen(false)}
        onConfirm={() => setIsOpen(false)}
      >
        <Tiles columns={1}>
          <p>{t("common.session_idle")}</p>
          <Button onClick={reactivateSession}>
            {t("common.continue_using")}
          </Button>
        </Tiles>
      </Modal>
    </>
  );
});
