import React from "react";
import { Text } from "components/Text";
import { SignUpCard } from "components/Card";
import { Separator } from "components/Separator";
import { Box } from "@material-ui/core";
import { FormStepGroupContext } from "./FormStepGroupContext";

export const FormStepGroup = ({ title, children, index, confirmMode = false, topRightAction = null }) => {
  const shouldRenderSeparator = React.Children.count(children) > 1;

  return <FormStepGroupContext.Provider value={{confirmMode}}>
    <SignUpCard
      confirmMode={confirmMode}
      title={title}
      topRightAction={topRightAction}
      index={index}
      data-qa-selector="form-step-group"
    >
      {confirmMode && shouldRenderSeparator && <Separator />}
      {React.Children.map(children, (child, childIndex) => (
        <>
          {child}
          {childIndex < React.Children.count(children) - 1 && shouldRenderSeparator && <Separator />}
        </>
      ))}
    </SignUpCard>
  </FormStepGroupContext.Provider>
};
