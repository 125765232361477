import { SvgIcon } from "@material-ui/core";

export const MobileRouterIcon = ({ color = "#853358", fontSize = 60 }) => (
  <SvgIcon viewBox="0 0 82 65" width="82" height="65" style={{ fontSize: fontSize }}>
    <path
      d="m 11.907944,21.351614 c 0,-2.81759 0,-5.635185 2.8497,-5.635185 3.5622,0 18.5231,0 20.6604,0 2.1372,0 4.2745,0.704395 4.2745,5.635185 0,5.63517 0,37.33307 0,39.44627 0,2.1132 -1.4248,4.2264 -4.987,4.2264 -3.5621,0 -16.3857,0 -18.523,0 -2.1373,0 -4.2746,-0.7044 -4.2746,-3.522 0,-2.8176 0,-37.33308 0,-40.15067 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 25.444044,56.345984 c 1.5674,-0.2113 3.0635,1.3383 2.8497,2.8176 -0.1424,1.0566 -0.9974,1.9723 -2.1372,2.1132 -1.2824,0.2113 -2.636,-0.7044 -2.8497,-2.1132 -0.2138,-1.4088 0.8549,-2.6767 2.1372,-2.8176 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 7.6334645,20.421264 c -0.21372,0.21132 -2.27976,2.04275 -2.13727,4.93082 0.14248,2.4654 1.8523,3.9446 2.13727,4.2263"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 4.0713245,18.308714 c -0.35621,0.21132 -3.41964001,2.39495 -3.56212001,6.33958 -0.21373,4.36729 3.34839001,6.90309 3.56212001,7.04399"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 29.718644,17.744464 c -0.1425,0.49308 0.0713,1.19748 0.7124,1.4088 0.6412,0.21132 1.2824,-0.21132 1.4249,-0.7044 0.2137,-0.7044 -0.7124,-1.54968 -1.4249,-1.4088 -0.3562,0.07044 -0.6411,0.42264 -0.7124,0.7044 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 12.264244,21.125854 h 27.0721"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 11.907944,53.528184 h 27.7846"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 19.032244,18.166864 h 7.1243"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 39.82358,37.18142 h 36.995443 c 2.496,0 4.9919,0 4.9919,3.7946 v 9.4867 c 0,1.2649 -1.248,3.1623 -4.3679,3.1623 H 39.73211"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 65.587423,9.35402 c 1.1855,0.1897 2.4335,-0.75897 2.4959,-1.89737 0.0624,-0.82218 -0.5616,-1.58111 -1.248,-1.89733 -1.1231,-0.50596 -2.8079,0.06324 -3.1199,1.26489 -0.2496,1.07515 0.624,2.34004 1.872,2.52981 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 69.331223,2.39722 c 0.2496,0.18973 2.4959,2.02382 2.4959,5.05956 0,2.52974 -1.56,4.11094 -1.872,4.42714"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 73.075223,0.5 c 0.5616,0.63244 2.3711,2.846 2.4959,6.32445 0.1248,3.85797 -1.9967,6.38767 -2.4959,6.95687"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 63.715423,18.20832 c 0,-0.6324 0.312,-3.1622 2.1839,-3.1622 1.872,0 2.184,1.8974 2.184,2.5298 0,0.6324 1.248,15.8111 1.248,15.8111 0,0 -1.248,1.2649 -3.12,1.2649 -1.8719,0 -3.1199,-1.8973 -3.1199,-1.8973 0,0 0.624,-13.2814 0.624,-14.5463 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M 39.80107,30.85692 H 63.091323"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 69.331223,30.85692 h 1.8719 c 0,0 2.496,0 3.7439,1.2649 1.248,1.2649 5.6159,5.692 5.6159,5.692"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 51.859723,47.80672 c 1.4976,0.1265 3.1199,-1.0119 3.1199,-2.5298 0,-1.3281 -1.248,-2.4033 -2.4959,-2.5298 -1.4976,-0.1265 -3.1199,1.0119 -3.1199,2.5298 0,1.2649 1.2479,2.34 2.4959,2.5298 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 61.032223,47.93332 c 1.1232,0.0632 2.9951,-0.5692 2.9951,-2.0239 0,-1.3281 -0.9983,-3.0357 -2.3711,-3.3519 -1.6848,-0.3795 -3.5567,1.0751 -3.5567,2.6563 0,1.4546 1.5599,2.6562 2.9327,2.7195 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 70.579123,48.12312 c 1.4352,0 2.9952,-1.0119 3.12,-2.5297 0.1872,-1.7076 -1.4976,-3.1623 -3.12,-3.1623 -1.4351,0 -2.9951,1.0119 -3.1199,2.5298 -0.1872,1.6444 1.4976,3.099 3.1199,3.1622 z"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="m 66.835323,53.62552 c 0.312,0.3794 1.6848,2.2135 3.8687,2.2135 0.3744,0 2.3088,-0.0632 3.8687,-2.2135"
      stroke="#853358"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </SvgIcon>
);
