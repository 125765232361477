import { Button } from "components/Button";
import { Text } from "components/Text";
import { Stack } from "components/layouts/Stack";
import { useApplicationContext } from "hooks/useApplicationContext";
import { getProductsList } from "lib/api/products";
import { createTicket } from "lib/api/tickets";
import { Trans, useTranslation } from "react-i18next";
import { ProductPickerModal } from "../shared/ProductPicker";

const formatDataPlanSize = (mb) => {
  if (mb < 1024) {
    return `${mb} MB`;
  }

  return `${mb / 1024} GB`;
};

export const AdditionalDataModal = ({ isOpen, onClose, subscription }) => {
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();

  const getAdditionalDataProducts = async () => {
    const { data } = await getProductsList({
      subscriptionId: subscription.id,
      productType: "additional_data",
      isSharedBonds: subscription.is_shared_bonds,
    });

    return data;
  };

  const onSubmit = async (selectedProduct) => {
    return createTicket({
      body: "-",
      subject: "-",
      meta: [
        {
          key: "ticket_type",
          value: "additional_data",
        },
        {
          key: "phone_number",
          value: subscription.phone_number,
        },
        {
          key: "new_product_code",
          value: selectedProduct.code,
        },
        {
          key: "subscription_email",
          value: subscription.email,
        },
        {
          key: "language",
          value: currentUser.preferred_locale + "_ES",
        },
      ],
    });
  };

  return (
    <ProductPickerModal
      title={t("subscriptions.detail.additional_data")}
      submitText={t("subscriptions.detail.additional_data_modal.add_package")}
      selectedText={t(
        "subscriptions.detail.additional_data_modal.selected_package"
      )}
      descriptionText={t(
        "subscriptions.detail.additional_data_modal.description"
      )}
      confirmText={() => {
        return (
          <Text>{t("subscriptions.detail.additional_data_modal.confirm")}</Text>
        );
      }}
      renderConfirmationStep={() => {
        return (
          <Stack>
            <Text>
              <Trans i18nKey="subscriptions.detail.additional_data_modal.confirmation.title" />
            </Text>
            <Button onClick={onClose}>{t("common.accept")}</Button>
          </Stack>
        );
      }}
      isOpen={isOpen}
      onClose={onClose}
      subscription={subscription}
      productType="additional_data"
      getProducts={getAdditionalDataProducts}
      renderProductDescription={(product) => (
        <span>{formatDataPlanSize(Number(product.data_included))}</span>
      )}
      onSubmit={onSubmit}
      onClickClose={onClose}
    />
  );
};
