import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "components/Card";
import { Text } from "components/Text";
import { Button } from "components/Button";
import { Stack } from "components/layouts/Stack";
import { Tiles } from "components/layouts/Tiles";
import {
  formatDataPlanSize,
  formatMinutes,
  formatBandwith,
} from "lib/helpers/format";
import Box from "@material-ui/core/Box";
import { CircleCheckIcon } from "components/icons/CircleCheckIcon"

export const PriceTag = ({ price, originalPrice }) => {
  const { t } = useTranslation();
  const isOfferPrice = Boolean(originalPrice);
  const priceInCents = price * 100;

  const integer = Math.trunc(priceInCents / 100);
  const cents = priceInCents % 100;

  return (
    <Box
      height="100%"
      display="flex"
      mt={isOfferPrice ? 0 : 1}
      flexDirection="column"
      justifyContent="center"
    >
      {isOfferPrice && (
        <Box mb={1}>
          <Text size="md" lineThrough>
            {originalPrice} €/mes
          </Text>
        </Box>
      )}

      <Box height="2.25rem" display="flex" flexDirection="row">
        <Box>
          <Text lineHeight="2.25rem" bold size="3xl">
            {integer}
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Text lineHeight="1rem" limitToCap size="lg">
            ,{cents.toString().padStart(2, "0")}
          </Text>
          <Text lineHeight="0.65rem" size="sm">
            €/mes
          </Text>
        </Box>
      </Box>
      <div style={{ opacity: 0.5 }}>
        <Box mt={1}>
          <Stack spacing={0}>
            <Text lineHeight="1.0" size="xs">
              {t("price_tag.vat_included")}
            </Text>
            <Text lineHeight="1.0" size="xs">
              {t("price_tag.no_binding")}
            </Text>
          </Stack>
        </Box>
      </div>
    </Box>
  );
};

const Reason = ({ text }) => (
  <Box display="flex" alignItems="center">
    <CircleCheckIcon />
    <Box ml={2}>
      <Text color="white">{text}</Text>
    </Box>
  </Box>
);

export const PricePreview = ({
  tariff,
  legend,
  details = ["minutes", "data"],
  showSubmit = false,
  onSubmit,
  showReasons = true,
}) => {
  const { t } = useTranslation();

  return (
    <Tiles columns={showSubmit ? 2 : 1} spacing={4}>
      <Card variant="noGutter" shadow={false} isHighlighted>
        <Tiles columns={!showSubmit && showReasons ? 2 : 1}>
          <Box display="flex" flex={1} paddingX={4} paddingY={4}>
            <Box flex={1}>
              <Stack spacing={0}>
                <Box mb={1}>
                  <Text size="xs">{legend}</Text>
                </Box>
                {details.includes("bandwidth") && (
                  <Text lineHeight="1.0" bold size="xl">
                    {formatBandwith(tariff?.bandwidth)}
                  </Text>
                )}
                {details.includes("minutes") && (
                  <Text lineHeight="1.0" bold size="xl">
                    {formatMinutes(tariff?.minutes)} +
                  </Text>
                )}
                {details.includes("data") && (
                  <Text lineHeight="1.0" bold size="xl">
                    {formatDataPlanSize(tariff?.data)}
                  </Text>
                )}
                {details.includes("totalAmount") && (
                  <Text lineHeight="1.0" bold size="xl">
                    {t(`price_tag.totalAmount`)}
                  </Text>
                )}
              </Stack>
            </Box>
            <Box display="flex" flex={1} alignItems="center">
              <PriceTag
                price={tariff?.price}
                originalPrice={tariff?.original_price}
              />
            </Box>
          </Box>
          {!showSubmit && showReasons && (
            <Box
              bgcolor="info.main"
              flex={1}
              flexDirection="column"
              justifyContent="center"
              paddingX={4}
              paddingY={4}
              display="flex"
            >
              <Stack spacing={1}>
                <Reason text={t("price_tag.reason_1")} />
                <Reason text={t("price_tag.reason_2")} />
                <Reason text={t("price_tag.reason_3")} />
              </Stack>
            </Box>
          )}
        </Tiles>
      </Card>
      {showSubmit && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          width="100%"
        >
          <Button type="submit" onClick={onSubmit}>
            {t("common.contract")}
          </Button>
        </Box>
      )}
    </Tiles>
  );
};
