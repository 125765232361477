import React, { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& .MuiPaginationItem-page.Mui-disabled": {
      color: theme.palette.primary.dark,
    },
    "& .MuiPaginationItem-page": {
      color: theme.palette.primary.main,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const Paginator = ({
  totalItems,
  maxItemsInPage,
  onChangePage,
  offset,
}) => {
  const page = offset / maxItemsInPage + 1;
  const classes = useStyles();

  const count = Math.ceil(totalItems / maxItemsInPage);

  return (
    <Pagination
      count={count}
      page={page}
      onChange={(event, value) => onChangePage(value)}
      className={classes.pagination}
      showFirstButton
      showLastButton
    />
  );
};
