export const AddButtonIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9.5" stroke="#3E3382" />
    <line x1="4.7998" y1="9.8999" x2="15.1998" y2="9.8999" stroke="#3E3382" />
    <line x1="4.7998" y1="9.8999" x2="15.1998" y2="9.8999" stroke="#3E3382" />
    <line
      x1="9.90039"
      y1="15.2009"
      x2="9.90039"
      y2="4.80093"
      stroke="#3E3382"
    />
    <line
      x1="9.90039"
      y1="15.2009"
      x2="9.90039"
      y2="4.80093"
      stroke="#3E3382"
    />
  </svg>
);
