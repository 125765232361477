import React from "react";
import { FormStep } from "../FormStep";
import { Condition } from "../Condition";
import { AddressPicker } from "../AddressPicker";
import { FormStepGroup } from "../FormStepGroup";
import { TextField } from "components/TextField";
import { Checkbox } from "components/Checkbox";
import { RadioToggle } from "components/RadioToggle";
import { Button } from "components/Button";
import { Text } from "components/Text";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { useStore } from "hooks/useStore";
import { useDerivedState } from "hooks/useDerivedState";
import {
  required,
  matchIbanFormat,
  composeValidators,
} from "lib/form/validators";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { formatFullAddress } from "../formatFullAddress";
import { PaymentMethodPicker } from "../PaymentMethodPicker.js";
import compact from "lodash.compact";
import { formatIBAN } from "lib/helpers/format";

export const PaymentStepGroup = ({ confirmMode }) => {
  const saveAddress = useStore((state) => state.saveAddress);
  const { t } = useTranslation();
  const history = useHistory();

  const {
    mustPayMemberFee,
    mustPayMonthlyBill,
    mustShipmentSimCard,
    getAddressById,
  } = useDerivedState();
  const isOnlyShowingOneStep =
    compact([mustPayMonthlyBill, mustPayMemberFee]).length === 1;

  const gotoConfirmStep = () => {
    history.push("confirm");
  };

  return (
    <FormStepGroup
      index={5}
      title={t(
        mustShipmentSimCard
          ? "funnel.signup.data.steps.payment_shipment_step_group.title"
          : "funnel.signup.data.steps.payment_step_group.title"
      )}
      confirmMode={confirmMode}
    >
      {mustPayMonthlyBill && (
        <FormStep
          id="payment/monthly-bill"
          title={t("funnel.signup.data.steps.payment_monthly_bill.title")}
          renderSummary={(values) => {
            if (!confirmMode) {
              return null;
            }
            return (
              <ConfirmationSummary
                title={t(
                  "funnel.signup.data.steps.payment_monthly_bill.confirmation.title"
                )}
                i18nPrefix="funnel.signup.data.steps.payment_monthly_bill.confirmation"
                fields={{
                  iban: values.service_iban && formatIBAN(values.service_iban),
                  address: formatFullAddress(
                    getAddressById(values?.address?._id)
                  ),
                }}
              />
            );
          }}
          onSubmit={(values) => {
            saveAddress(values.address);

            if (mustShipmentSimCard) {
              return;
            } else if (mustPayMemberFee) {
              return;
            }

            gotoConfirmStep();
          }}
        >
          <Tiles columns={2} spacing={4}>
            <TileSpan span={2}>
              <PaymentMethodPicker
                name="service_iban"
                label={t("funnel.signup.data.steps.payment_monthly_bill.iban")}
              />
            </TileSpan>
            <TileSpan span={2}>
              <AddressPicker
                name="address"
                label={t("funnel.signup.data.steps.payment_data.address")}
                validate={required}
              />
            </TileSpan>
            <Button type="submit">{t("common.continue")}</Button>
          </Tiles>
        </FormStep>
      )}
      {mustShipmentSimCard && (
        <FormStep
          id="payment/shipment"
          title={t("funnel.signup.data.steps.shipment.title")}
          renderSummary={(values) => {
            if (!confirmMode) {
              return null;
            }
            return (
              <ConfirmationSummary
                title={t(
                  "funnel.signup.data.steps.shipment.confirmation.title"
                )}
                i18nPrefix="funnel.signup.data.steps.shipment.confirmation"
                fields={{
                  delivery_address: values?.delivery_address?._id
                    ? formatFullAddress(
                        getAddressById(values?.delivery_address?._id)
                      )
                    : null,
                }}
              />
            );
          }}
          onSubmit={(values) => {
            saveAddress(values.delivery_address);

            if (mustPayMemberFee) {
              return;
            }

            gotoConfirmStep();
          }}
        >
          <Tiles columns={1} spacing={4}>
            <TileSpan span="all">
              <AddressPicker
                validate={required}
                name="delivery_address"
                label={t(
                  "funnel.signup.data.steps.mobile_line_additional_data.delivery_address"
                )}
              />
            </TileSpan>
            <Button type="submit">{t("common.continue")}</Button>
          </Tiles>
        </FormStep>
      )}
      {mustPayMemberFee && (
        <FormStep
          id="payment/member-fee"
          title={t("funnel.signup.data.steps.payment_data.title")}
          initialValues={{
            pays_in_ten_terms: false,
            use_same_bank_account: true,
          }}
          onSubmit={(values) => {
            saveAddress(values.address);
            gotoConfirmStep();
          }}
          renderSummary={(values) => {
            if (!confirmMode) {
              return (
                <Text size="xs">
                  {[
                    values.iban && formatIBAN(values.iban),
                    formatFullAddress(values.address),
                  ].join(" · ")}
                </Text>
              );
            }

            return (
              <ConfirmationSummary
                title={t(
                  "funnel.signup.data.steps.payment_data.confirmation.title"
                )}
                i18nPrefix="funnel.signup.data.steps.payment_data.confirmation"
                fields={{
                  service_iban: values.iban && formatIBAN(values.iban),
                  pays_in_ten_terms: t(
                    `common.${values.pays_in_ten_terms ? "yes" : "no"}`
                  ),
                }}
              />
            );
          }}
        >
          <Tiles columns={2} spacing={4}>
            {!mustPayMonthlyBill ? (
              <>
                <TileSpan span={2}>
                  <PaymentMethodPicker
                    name="iban"
                    label={t("funnel.signup.data.steps.payment_data.iban")}
                  />
                </TileSpan>
                {isOnlyShowingOneStep && (
                  <TileSpan span={2}>
                    <AddressPicker
                      name="address"
                      label={t("funnel.signup.data.steps.payment_data.address")}
                      validate={required}
                    />
                  </TileSpan>
                )}
              </>
            ) : (
              <>
                <TileSpan span={2}>
                  <RadioToggle.FormField
                    name="use_same_bank_account"
                    leftLabel={t(
                      "funnel.signup.data.steps.payment_monthly_bill.use_same_bank_account_yes"
                    )}
                    leftValue={true}
                    rightLabel={t(
                      "funnel.signup.data.steps.payment_monthly_bill.use_same_bank_account_no"
                    )}
                    rightValue={false}
                  />
                </TileSpan>
                <Condition when="use_same_bank_account" is={false}>
                  <TileSpan span={2}>
                    <TextField.FormField
                      name="iban"
                      label={t("funnel.signup.data.steps.payment_data.iban")}
                      validate={composeValidators(required, matchIbanFormat)}
                    />
                  </TileSpan>
                </Condition>
                <TileSpan span={2}>
                  <Checkbox.FormField
                    initialValue={false}
                    name="pays_in_ten_terms"
                    label={t(
                      "funnel.signup.data.steps.payment_data.pays_in_ten_terms"
                    )}
                    validate={required}
                  />
                </TileSpan>
              </>
            )}
            <div>
              <Button fullWidth type="submit">
                {t("funnel.signup.data.steps.payment_monthly_bill.submit")}
              </Button>
            </div>
          </Tiles>
        </FormStep>
      )}
    </FormStepGroup>
  );
};
