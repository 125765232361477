import { get, post } from "axios";
import { preprocessUsernameForApi } from "../domain/somconnexio/auth";
import { noop } from "lib/fn/noop";

export const PROFILE_ENDPOINT = "/profile";
export const CHECK_AUTH_ENDPOINT = "/auth/info/";
export const LOGIN_ENDPOINT = "/auth/login/";
export const LOGIN_TOKEN_ENDPOINT = "/auth-token/login/";
export const LOGIN_CHECK_CREDENTIALS = "/auth/credentials/";

export const whoami = async () => {
  const { data } = await get(PROFILE_ENDPOINT);
  return data;
};

export const getInfo = async () => {
  return (await get(CHECK_AUTH_ENDPOINT)).data;
};

export const checkAuth = async () => {
  try {
    await getInfo();
    return true;
  } catch (e) {
    return false;
  }
};

export const tokenLogin = async (token) => {
  const headers = {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  await post(LOGIN_TOKEN_ENDPOINT, null, { headers });
  return true;
  // TODO check response to return true or false
};

export const checkCredentials = async (username, password) => {
  const credentials = btoa(`${username}:${password}`);
  const headers = {
    Authorization: `Basic ${credentials}`,
  };
  return await get(LOGIN_CHECK_CREDENTIALS, { headers });
};

export const logout = async (keycloackLogout = noop) => {
  post("/auth/logout/");
  keycloackLogout();
};

/**
 * @deprecated since keycloak SSO
 */
export const login = async (username, password) => {
  return post(LOGIN_ENDPOINT, {
    username: preprocessUsernameForApi(username),
    password,
  });
};

/**
 * @deprecated since keycloak SSO
 *
 * Prepending ES to DNI username
 *
 * TODO: remove somconnexio specific hack
 */
export const requestPasswordReset = async ({ username }) => {
  return post("/auth/password_reset/", {
    email: preprocessUsernameForApi(username),
  });
};

/**
 * @deprecated since keycloak SSO
 */
export const validatePasswordResetToken = async ({ token }) => {
  return post("/auth/password_reset/validate_token/", { token });
};

/**
 * @deprecated since keycloak SSO
 */
export const resetPassword = async ({ token, password }) => {
  return post("/auth/password_reset/confirm/", { token, password });
};
