/**
 * giving a list of contracts, return list with unique fiber code associated
 */
export const getAssociatedFiberCodes = (contracts) => [
  ...new Set(
    contracts
      .map(({ associated_fiber }) => associated_fiber)
      .filter((associated_fiber) => !!associated_fiber)
  ),
];

/**
 * giving a list of contracts, group this in packs.
 * is necessary to pass the contracts through decoratePackFiber method.
 */
export const contractsInPack = (contracts) =>
  contracts.reduce((packs, contract) => {
    if (!!contract.associated_fiber || !!contract.mobiles_associated) {
      const ref = !!contract.mobiles_associated
        ? contract.code
        : contract.associated_fiber;
      packs[ref] = packs[ref] ?? [];
      packs[ref].push(contract);
    }
    return packs;
  }, {});

export const decoratePackFiber = (contracts) => {
  const associatedFiberCodes = getAssociatedFiberCodes(contracts);
  return contracts.map((contract) =>
    associatedFiberCodes.includes(contract.code)
      ? {
          ...contract,
          mobiles_associated: contracts
            .filter(
              ({ associated_fiber }) => associated_fiber === contract.code
            )
            .map(({ code }) => code),
        }
      : { ...contract, mobiles_associated: undefined }
  );
};
