import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSharedDataContext } from "../context";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";

export const Success = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setShouldShowStepper } = useSharedDataContext();
  setShouldShowStepper(false);
  return (
    <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
      <Typography variant="h4" component="h1">
        {t("share_data.succes.title")}
      </Typography>
      <Typography>{t("share_data.succes.subtitle")}</Typography>
      <Box mt={5}>
        <Button
          onClick={() => {
            history.push("/");
          }}
        >
          {t("common.back_to_home")}
        </Button>
      </Box>
    </Box>
  );
};
