import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useStore } from "hooks/useStore";
import { usePackTariffs } from "hooks/queries/useTariffs";
import { getUrlQuery } from "lib/helpers/urls";

import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { Container } from "components/layouts/Container";
import { Tiles } from "components/layouts/Tiles";
import { StyledIframeSpinner } from "components/Spinner";
import { Text } from "components/Text";
import { CtaFooter } from "../shared/TariffsCtaFooter";
import { useEffect, useState } from "react";
import { PacksFilter } from "../shared/PacksFilter";
import { Pack } from "components/views/Pack";

const RecommendedTariffSection = ({
  recommendedTariffs,
  title,
  index,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    tariffsGrid: {
      display: "grid",
      gap: theme.spacing(4),
      gridTemplateColumns: "1fr 1fr",
      // mui breakpoints javascript-media-queries
      // value |0px     600px
      // key   |xs      sm
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "100%",
      },
    },
  }));

  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Box mt={index > 0 ? 4 : 0}>
      <Heading>{t(title)}</Heading>
      <Box className={styles.tariffsGrid}>
        {recommendedTariffs.map((recommendedTariff) => (
          <Pack
            pack={recommendedTariff}
            key={recommendedTariff.code}
            {...props}
          />
        ))}
      </Box>
    </Box>
  );
};

const RecommendedTariffsGrid = ({
  recommendedTariffs,
  filterComponent,
  ...props
}) => {
  const { t } = useTranslation();

  const groups = Object.entries(
    recommendedTariffs.reduce((groups, tariff) => {
      const phones = tariff.mobiles_in_pack;
      groups[phones] = (groups[phones] || []).concat(tariff);
      return groups;
    }, {})
  ).map(([phones, tariffs]) => {
    // TODO: Estem agregant per nombre de mòbils, però pot ser que hi hagi, dins d'un grup,
    // dos tipus de packs (compartides i bonificades). Pensar si el tipus de pack també ha de ser
    // un eix de segmentació de grups, o etiquetar-los amb literals que recullin la diversitat.
    const isMobileSharedData = phones > 1;
    return {
      recommendedTariffs: tariffs.sort(
        (a, b) => parseFloat(a.price) - parseFloat(b.price)
      ),
      title: `funnel.tariffs.recommended.${
        isMobileSharedData ? "title_shared_tariff" : "title_pack_tariff"
      }`,
      key: `${phones}_mobiles_phone`,
      isMobileSharedData: isMobileSharedData,
    };
  });

  return (
    <Tiles columns={1}>
      <Heading>{t("funnel.tariffs.recommended.page_title")}</Heading>
      <Box mb={2}>{filterComponent}</Box>
      {groups.map(
        ({ title, recommendedTariffs, key, isMobileSharedData }, index) => (
          <RecommendedTariffSection
            // title={title}
            recommendedTariffs={recommendedTariffs}
            index={index}
            key={key}
            isMobileSharedData={isMobileSharedData}
            {...props}
          />
        )
      )}
    </Tiles>
  );
};

export const RecommendedTariffsPicker = ({ openConfirmModal, queryParams }) => {
  const history = useHistory();
  const { isLoading, isError, refetch, data: packTariffs } = usePackTariffs();
  const { setLines, setIsSharedLines } = useStore();
  const { t } = useTranslation();

  const [mobileSelection, setMobileSelection] = useState(1);
  const [bandWidthSelection, setBandWidthSelection] = useState(300);
  const [filteredTariffs, setFilteredTariffs] = useState([]);
  useEffect(() => {
    if (isLoading) return;

    setFilteredTariffs(
      packTariffs.filter(
        (tariff) =>
          tariff.mobiles_in_pack === mobileSelection &&
          tariff.fiber_bandwidth === bandWidthSelection
      )
    );
  }, [isLoading, mobileSelection, bandWidthSelection]);

  const onClickFooter = () =>
    history.push(getUrlQuery("/tariffs/internet_and_mobile", queryParams));

  const onSelect = ({ category, products }) => {
    setLines(products);
    const isSharedLines = category === "mobile_shared_data";
    setIsSharedLines(isSharedLines);
    openConfirmModal(isSharedLines, products);
  };

  function getDistinctValues(tariffs, field) {
    return Object.keys(
      tariffs.reduce((acum, tariff) => {
        acum[tariff[field]] = true;
        return acum;
      }, {})
    );
  }

  if (isLoading) {
    return <StyledIframeSpinner />;
  }

  if (isError) {
    return (
      <Tiles columns={1}>
        <div>{t("common.errors.request_failed")}</div>
        <Button onClick={refetch}>{t("common.retry")}</Button>
      </Tiles>
    );
  }

  return (
    <>
      <Container variant="narrow">
        <Tiles columns={1} spacing={5}>
          <RecommendedTariffsGrid
            filterComponent={
              <PacksFilter
                mobileValues={getDistinctValues(packTariffs, "mobiles_in_pack")}
                mobileSelection={mobileSelection}
                setMobileSelection={setMobileSelection}
                bandWidthValues={getDistinctValues(
                  packTariffs,
                  "fiber_bandwidth"
                )}
                bandWidthSelection={bandWidthSelection}
                setBandWidthSelection={setBandWidthSelection}
              />
            }
            recommendedTariffs={filteredTariffs}
            onSelect={(productsList) => onSelect(productsList)}
          />
          <Box mb={4}>
            <Text size="md" semibold selectable={false}>
              <>{t("funnel.tariffs.recommended.footer_question")}</>
            </Text>
            <Text
              size="md"
              semibold
              selectable={false}
              underline
              onClick={() => onClickFooter()}
            >
              {t("funnel.tariffs.recommended.footer_answer")}
            </Text>
          </Box>
        </Tiles>
      </Container>
      <CtaFooter margingTop={5} />
    </>
  );
};
