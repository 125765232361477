import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

import { useStore } from "./useStore";
import { useApplicationContext } from "./useApplicationContext";
import { useLinesDerivedState } from "./useLinesDerivedState";

import { Button } from "components/Button";
import { Tiles } from "components/layouts/Tiles";
import { Text } from "components/Text";
import { ModalLayout } from "components/layouts/ModalLayout";
import { useFiberContracts } from "./queries/useContracts";
import { formatDataPlanSize, formatPrice } from "lib/helpers/format";

export function useOfferModal(tariffType, offers) {
  const currentUser = useApplicationContext()?.currentUser;
  const loggedIn_mobile = tariffType === "mobile" && Boolean(currentUser);

  const {
    setFiberLinesToAssociateMobile,
    fiberLinesToAssociateMobile,
    lines,
    updateLineAt,
  } = useStore();

  const {
    hasFiberLineToAssociateMobile,
    hasRelationshipFiberMobileOffer,
    internetLines,
    internetFiberLines,
    mobileLines,
    mobileLinesInOffer,
  } = useLinesDerivedState();

  const {
    isLoading,
    error,
    data: fiberContracts = [],
  } = useFiberContracts({
    userName: currentUser?.username,
    automatically_run: loggedIn_mobile,
  });

  useEffect(() => {
    if (fiberContracts.length) setFiberLinesToAssociateMobile(fiberContracts);
  }, [fiberContracts]);

  const [isOpen, setIsOpen] = useState(false);

  const isInedit = useRef(true);
  useEffect(() => {
    isInedit.current = isInedit.current && !isOpen;
  }, [isOpen]);

  const [canOpen, setCanOpen] = useState();
  useEffect(() => {
    // show modal advice offer in tariff/internet_and_mobile
    const check_case_internet_and_mobile =
      !hasRelationshipFiberMobileOffer && internetFiberLines.length > 0;

    // advice offer when user is logged with free fibers
    const check_case_login_user =
      hasFiberLineToAssociateMobile && mobileLinesInOffer.length === 0;

    setCanOpen(
      /* Open only one time */
      isInedit.current &&
        (check_case_internet_and_mobile || check_case_login_user) &&
        hasOfferCandidates()
    );
  }, [isInedit.current, lines, fiberLinesToAssociateMobile]);

  const [offerIndex, setOfferIndex] = useState();
  const [offer, setOffer] = useState({});
  const [offerableLine, setOfferableLine] = useState({});

  function hasOfferCandidates() {
    const lines = mobileLines.filter((l) => {
      return offers.map((t) => t.minutes).includes(l.minutes);
    });

    if (lines.length > 0) {
      const offer = offers.reduce((available, tariff) => {
        if (available.data < tariff.data) return available;
        return tariff;
      });
      const line = lines.reduce((offerable, line) => {
        if (offerable.data < line.data) return offerable;
        return line;
      });

      if (line.data < offer.data && line.price > offer.price) {
        setOffer(offer);
        setOfferableLine(line);
        return true;
      }
    }

    return false;
  }

  function switchToOffer() {
    const index = mobileLines.map(({ id }) => id).indexOf(offerableLine.id);
    setOfferIndex(index);
    updateLineAt(offer, "mobile", internetLines.length + index);
    setIsOpen(false);
  }

  const Modal = () => {
    const { t } = useTranslation();
    return (
      <ModalLayout isOpen={isOpen} disableBackdropClick={true}>
        <Text size="xl" lineHeight={1.25} bold>
          {t("common.important")}
        </Text>
        <Text>
          <Text>
            {t(
              `funnel.tariffs.offer_modal.${
                loggedIn_mobile
                  ? "content_user_logged_already_has_fiber"
                  : "content_new_customer"
              }`,
              {
                planSize: formatDataPlanSize(offerableLine.data),
                currentPrice: formatPrice(offerableLine.price, {
                  frequency: "monthly",
                }),
                offerPlanSize: formatDataPlanSize(offer.data),
                offerPrice: formatPrice(offer.price, {
                  frequency: "monthly",
                }),
              }
            )}
          </Text>
        </Text>
        <Tiles spacing={8}>
          <Button onClick={switchToOffer}>
            {t("funnel.tariffs.offer_modal.button_want", {
              offerPlanSize: formatDataPlanSize(offer.data),
              offerPrice: formatPrice(offer.price),
            })}
          </Button>
          <Button onClick={() => setIsOpen(false)}>
            {t("funnel.tariffs.offer_modal.button_dont_want", {
              planSize: formatDataPlanSize(offerableLine.data),
              currentPrice: formatPrice(offerableLine.price),
            })}
          </Button>
        </Tiles>
      </ModalLayout>
    );
  };

  return {
    offer,
    index: offerIndex,
    isOpen,
    setIsOpen,
    canOpen,
    isLoading,
    error,
    Modal,
  };
}
