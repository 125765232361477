import React, { useState } from "react";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import { SubscriptionIcon } from "../../Home/SubscriptionDetail/shared/SubscriptionIcon";
import { useRequestChangeContext } from "../context";
import { useTranslation } from "react-i18next";

export const SelectSubscriptions = () => {
  const { subscriptions, setSelectedSubscriptions } = useRequestChangeContext();
  const history = useHistory();
  const [selectedCodes, setSelectedCodes] = useState(new Set());
  const onConfirm = () => {
    setSelectedSubscriptions(Array.from(selectedCodes.values()));
    history.push("select-topic");
  };
  const { t } = useTranslation();

  const onSelect = (sub) => {
    const newCodes = new Set(selectedCodes);

    if (selectedCodes.has(sub.code)) {
      newCodes.delete(sub.code);
    } else {
      newCodes.add(sub.code);
    }

    setSelectedCodes(newCodes);
  };

  const getSecondaryItemText = (subscription) =>
    subscription.subscription_type === "broadband" &&
    subscription.phone_number === "-" &&
    subscription.address
      ? subscription.address.street + " - " + subscription.address.city
      : subscription.phone_number;

  return (
    <div>
      <Box mb={4}>
        <strong>{t("request_change.select_subscriptions.title")}</strong>
      </Box>
      <List>
        {subscriptions.map((sub) => (
          <ListItem key={sub.code} button onClick={() => onSelect(sub)}>
            <ListItemAvatar>
              <Box
                display="flex"
                minWidth={60}
                minHeight={60}
                width={60}
                height={60}
                mr={4}
                ml={2}
                justifyContent="center"
                alignItems="center"
              >
                <SubscriptionIcon type={sub.subscription_type} />
              </Box>
            </ListItemAvatar>
            <ListItemText
              id={sub.code}
              primary={t(`subscriptions.type.${sub.subscription_type}`)}
              secondary={getSecondaryItemText(sub)}
            />
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                edge="end"
                checked={selectedCodes.has(sub.code)}
                onChange={() => onSelect(sub)}
                inputProps={{ "aria-labelledby": `${sub.description}` }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          disabled={selectedCodes.size === 0}
          fullWidth={false}
          onClick={onConfirm}
        >
          {t("common.confirm")}
        </Button>
      </Box>
    </div>
  );
};
