import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";

function hashString(str) {
  let hash = 0,
    i,
    chr;

  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return Math.abs(hash);
}

function shuffle(p, trickedResult) {
  return trickedResult || (Math.random() <= p ? "A" : "B");
}

/*
 * Resolve first or second branch base on probability
 * with resolution persistance
 *
 * @param {any} A First branch
 * @param {any} B Second branch
 * @param {string} campaign Campaign id
 * @param {number} p Probability of first over second
 *
 * @return {any} First or second branch
 */
function abTest({ A, B, campaign = "default-campaign", p = 0.5 }) {
  const campaignId = "ab-campaign-" + String(hashString(campaign));
  const query = new URLSearchParams(window.location.search);

  // INFO: `ab_debug=true` as search param disable resolution persistance
  const isDebugMode = query.get("ab_debug") === "true";

  const branch = shuffle(
    p,
    isDebugMode ? void 0 : localStorage.getItem(campaignId)
  );

  if (!isDebugMode) {
    localStorage.setItem(campaignId, branch);
  }

  const summary = { campaignId, branch, isDebugMode };

  switch (branch) {
    case "A":
      return [A, summary];
    case "B":
      return [B, summary];
    default:
      return [A, summary];
  }
}

/*
 * Default export. React Component with analytic tracking
 */
function ABComponent(props) {
  const history = useHistory();
  const [result, setResult] = useState();

  useEffect(() => {
    setResult(abTest(props));
  }, []);

  useEffect(() => {
    if (!result) return;
    const [, { campaignId, branch, isDebugMode }] = result;
    const query = new URLSearchParams(window.location.search);
    query.set(campaignId, branch);

    if (!isDebugMode)
      history.push(window.location.pathname + "?" + query.toString());
  }, [result]);

  if (props.children && Function.prototype.isPrototypeOf(props.children)) {
    return props.children(...result);
  }

  if (!result) return null;
  const [Component] = result;
  return <Component />;
}

/*
 * Test resolver to trigger test resolutions as promises outside of JSX renders
 */
ABComponent.resolve = function (props) {
  return new Promise((res, rej) => {
    const [result, summary] = abTest(props);
    res(result, summary);
  });
};

export default ABComponent;
