/* VENDOR */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Radio,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import groupBy from "lodash.groupby";
import React, { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { sortBy } from "lodash";
import cn from "classnames";

/* LIB */
import { noop } from "lib/fn/noop";
import { formatPrice } from "lib/helpers/format";
import { getTickets } from "lib/api/tickets";

/* COMPONENTS */
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { Modal } from "components/Modal";
import { Link } from "components/Link";
import { Tiles } from "components/layouts/Tiles";
import { Stack } from "components/layouts/Stack";

const useStyles = makeStyles((theme) => ({
  accordionHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  list: {
    width: "100%",
  },
  item: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  itemDisabled: {
    pointerEvents: "none",
    opacity: "0.5",
  },
  crossedPrice: {
    opacity: "0.5",
    marginLeft: "1em",
    textDecoration: "line-through",
  },
}));

const ProductWrapper = ({
  products,
  groupProductsBy,
  renderGroupName,
  renderProductList,
}) => {
  const shouldGroup = Boolean(groupProductsBy);
  const classes = useStyles();

  if (shouldGroup) {
    const groupedProducts = groupBy(products, groupProductsBy);

    return (
      <>
        {Object.keys(groupedProducts).map((groupKey) => (
          <Accordion key={groupKey}>
            <AccordionSummary classes={{ root: classes.accordionHeader }}>
              {renderGroupName(groupKey)}
            </AccordionSummary>
            <AccordionDetails>
              {renderProductList(groupedProducts[groupKey])}
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  } else {
    return <>{renderProductList(products)}</>;
  }
};

const ProductList = ({
  products,
  selectedProductCode,
  currentProductCode,
  onProductSelected,
  sortProductsBy,
  renderProductDescription,
  priceProductMonthly,
}) => {
  const classes = useStyles();
  return (
    <List classes={{ root: classes.list }} dense disablePadding={true}>
      {sortBy(products, sortProductsBy).map((product, index) => {
        const productCode = product.code;
        const isDisabled = currentProductCode === productCode;
        return (
          <Fragment key={productCode}>
            <ListItem disableGutters>
              <div
                className={cn(classes.item, isDisabled && classes.itemDisabled)}
                onClick={() => onProductSelected(product)}
              >
                <div>
                  <Radio
                    value={productCode}
                    checked={selectedProductCode === productCode}
                  />
                  <Typography variant="caption">
                    {renderProductDescription(product)}
                  </Typography>
                </div>
                <div>
                  {formatPrice(product.price, {
                    frequency: priceProductMonthly ? "monthly" : "",
                  })}
                  {product.isOffer ? (
                    <span className={classes.crossedPrice}>
                      {formatPrice(product.srcTariff.price, {
                        frequency: priceProductMonthly ? "monthly" : "",
                      })}
                    </span>
                  ) : (
                    void 0
                  )}
                </div>
              </div>
            </ListItem>
            {index < products.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </List>
  );
};

export const ProductPicker = ({
  title,
  submitText,
  selectedText,
  currentText,
  descriptionText,
  confirmText,
  onLoadingStateChange,
  subscription,
  getProducts,
  getAvailablePacks,
  groupProductsBy,
  sortProductsBy,
  renderGroupName,
  renderProductDescription,
  renderConfirmationStep,
  renderWarningStep,
  beforeAllShowWarning = noop,
  onSubmit,
  productType,
  onClickClose,
  priceProductMonthly,
}) => {
  const { t } = useTranslation();
  const enabledCallPack = !!getAvailablePacks;
  const { isLoading: tariffsIsLoading, data: products } = useQuery(
    `ProductPicker/${subscription.id}/${productType}/tariffs`,
    getProducts
  );
  const { isLoading: packsIsLoadingUseQuery, data: packs = [] } = useQuery(
    `ProductPicker/${subscription.id}/${productType}/packs`,
    getAvailablePacks,
    { enabled: enabledCallPack }
  );
  // https://github.com/TanStack/query/issues/3584
  const packsIsLoading = packsIsLoadingUseQuery && enabledCallPack;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(null);
  const [needsInformation, setNeedsInformation] = useState(true);
  const [confirmationContext, setConfirmationContext] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [value, setValue] = useState(null);
  const [warningShown, setWarningShown] = useState(false);

  useEffect(() => {
    onLoadingStateChange(isSubmitting || tariffsIsLoading);
  }, [isSubmitting, tariffsIsLoading, onLoadingStateChange, packsIsLoading]);

  if (tariffsIsLoading || packsIsLoading || isSubmitting) {
    return <Spinner />;
  }

  const offerProducts = products.map((product) => {
    if (product.offer !== null)
      return {
        ...product,
        ...product.offer,
        isOffer: true,
        srcTariff: product,
      };
    return product;
  });
  const allProducts = products.concat(offerProducts.filter((p) => p.isOffer));
  const currentProduct = allProducts.find((product) => {
    return product.code === subscription.active_product_code;
  });

  if (beforeAllShowWarning(currentProduct, warningShown)) {
    return renderWarningStep(() => setWarningShown(true), currentProduct);
  }

  if (isSubmitted || hasError) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box my={2}>
          {hasError ? (
            <Alert severity="error">{t("common.errors.request_failed")}</Alert>
          ) : (
            <Alert severity="success">
              {t("common.petition_received_with_email_confirmation")}
            </Alert>
          )}
        </Box>
        <Button onClick={onClickClose} fullWidth={false}>
          {t("common.close")}
        </Button>
      </Box>
    );
  }

  const selectedProduct = allProducts.find((product) => product.code === value);

  const checkExistingProcesses = async () => {
    const phoneNumber = subscription.phone_number;
    let tickets = [];

    const { data } = await getTickets({
      ticket_type: productType,
      liniaMobil: phoneNumber,
    });
    tickets = data;

    if (tickets.length > 0) {
      const rawProductCode = tickets[0].meta.find(
        (m) => m.key === "new_product_code"
      ).value;
      return {
        error: "needs_confirmation",
        product_name:
          allProducts.find((p) => p.code === rawProductCode)?.name || "",
        override_ticket_ids: tickets.map((t) => t.id),
        fiber_linked: tickets[0].meta.find((m) => m.key === "fiber_linked")
          .value,
      };
    }
    return;
  };

  const onClickSubmit = async (extraContext) => {
    setNeedsConfirmation(false);
    if (needsInformation) {
      setNeedsInformation(false);
      setIsSelected(true);
      return;
    }
    setIsSubmitting(true);
    try {
      if (!extraContext) {
        const checkResult = await checkExistingProcesses();

        if (checkResult?.error === "needs_confirmation") {
          setNeedsConfirmation(true);
          setHasError(false);
          setIsSubmitted(false);
          setConfirmationContext({
            ...checkResult,
          });
          return;
        }
      }

      await onSubmit(selectedProduct, extraContext, packs);
      setIsSubmitted(true);
    } catch (e) {
      console.log("e", e);
      setHasError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSelected) {
    return (
      <Tiles spacing={2} columns={1}>
        <Alert severity="info">
          {confirmText()}
          <div>
            <Trans i18nKey="common.assistance_email_message">
              <Link
                target="_blank"
                to={"mailto:" + t("common.assistance_email")}
              />
              <Link
                target="_blank"
                to={"mailto:" + t("common.assistance_email")}
              />
            </Trans>
          </div>
        </Alert>
        <Button
          onClick={() => {
            setIsSelected(false);
            onClickSubmit();
          }}
        >
          {t("common.continue")}
        </Button>
        <Button onClick={onClickClose}>{t("common.cancel")}</Button>
      </Tiles>
    );
  }

  const selectedProductCode = value ? value : "";
  const currentProductCode = currentProduct?.code;

  if (needsConfirmation) {
    return (
      <Alert severity="warning">
        {renderConfirmationStep(onClickSubmit, confirmationContext)}
      </Alert>
    );
  }
  const getProductsToWrapper = () =>
    Boolean(packs?.length) || currentProduct?.isOffer
      ? offerProducts
      : products;

  return (
    <>
      <Box display="flex" flexDirection="column" mb={4} alignItems="start">
        <Typography align="center" variant="h5">
          {title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" mb={4}>
        <Stack>
          {currentText && (
            <>
              {currentText}
              <strong>{currentProduct.description}</strong>
            </>
          )}
          {descriptionText && <Typography>{descriptionText}</Typography>}
        </Stack>
      </Box>
      <ProductWrapper
        products={getProductsToWrapper()}
        groupProductsBy={groupProductsBy}
        renderGroupName={renderGroupName}
        renderProductList={(products) => (
          <ProductList
            products={products}
            selectedProductCode={selectedProductCode}
            currentProductCode={currentProductCode}
            onProductSelected={(product) => setValue(product.code)}
            renderProductDescription={renderProductDescription}
            priceProductMonthly={priceProductMonthly}
            sortProductsBy={sortProductsBy}
          />
        )}
      />
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        {selectedProduct && (
          <Typography color="primary" align="center">
            {selectedText}{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            <strong>{renderProductDescription(selectedProduct)}</strong>
          </Typography>
        )}
        <Box mt={[1, 2]}>
          <Button
            fullWidth={false}
            onClick={() => onClickSubmit()}
            disabled={!selectedProduct}
          >
            {submitText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export const ProductPickerModal = ({ isOpen, onClose, ...props }) => {
  const [shouldBlockModal, setShouldBlockModal] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={shouldBlockModal ? noop : onClose}
      showCloseButton={!shouldBlockModal}
    >
      <ProductPicker
        onLoadingStateChange={(loading) => setShouldBlockModal(loading)}
        onClickClose={onClose}
        {...props}
      />
    </Modal>
  );
};
