import { MobileIcon } from "./MobileIcon";
import { RouterIcon } from "./RouterIcon";
import { MobileRouterIcon } from "./MobileRouterIcon";

const icons = { Mobile: MobileIcon, Router: RouterIcon, MobileRouter: MobileRouterIcon };

export default icons;
export const Mobile = MobileIcon;
export const Router = RouterIcon;
export const MobileRouter = MobileRouterIcon;
