import React from "react";
import { compact } from "lodash";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Text } from "components/Text";
import { TextField } from "components/TextField";
import { Form } from "components/Form";
import { Tiles } from "components/layouts/Tiles";
import { RadioToggle } from "components/RadioToggle";
import { Select } from "components/Select";

import { getDiscoveryChannels } from "lib/api/discoveryChannels";
import { genders, nationalities } from "lib/domain/somconnexio/selections";
import { getFullName } from "lib/helpers/format";
import { capitalize } from "lib/helpers/string";
import {
  composeValidators,
  required,
  requiredLastName,
  matchDateFormat,
  matchEmailFormat,
  matchVatFormat,
  mustMatchOther,
  validBirthdate,
  mustBeAdult,
  validPhone,
  validateSponsor,
} from "lib/form/validators";

import { Condition } from "../Condition";
import { FormStep } from "../FormStep";
import { ApiSelect } from "../ApiSelect";
import { ConfirmationSummary } from "../ConfirmationSummary";
import { FormStepGroup } from "../FormStepGroup";
import { spainIdType } from "spain-id";
import { useHistory } from "react-router-dom";

export const PersonalDataStepGroup = ({
  confirmMode = false,
  optingForRole,
  goToPayment = false,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getSelectionLabel = (i18nPrefix, value) =>
    t([i18nPrefix, value].join("."));

  return (
    <FormStepGroup
      index={1}
      title={t("funnel.signup.data.steps.personal_data_step_group.title")}
      confirmMode={confirmMode}
    >
      <FormStep
        initialValues={{ is_company: false }}
        id="partner/personal-data"
        title={t("funnel.signup.data.steps.personal_data.title")}
        renderSummary={(values) => {
          if (confirmMode) {
            return (
              <ConfirmationSummary
                i18nPrefix="funnel.signup.data.steps.personal_data.confirmation"
                title={t("funnel.signup.data.steps.personal_data.title")}
                fields={{
                  fullname: getFullName(values),
                  vat: values.vat,
                  birthdate: values.birthdate,
                  ...(!values.is_company
                    ? {
                        gender: getSelectionLabel(
                          "funnel.selections.gender",
                          values.gender
                        ),
                      }
                    : {}),
                  language: getSelectionLabel(
                    "funnel.selections.lang",
                    values.lang
                  ),
                  nationality: values.nationality,
                }}
              />
            );
          }

          return (
            <Text size="xs">
              {[
                values.vat.toUpperCase(),
                compact([
                  values.name,
                  values.tradename,
                  values.surname,
                  values.lastname,
                ])
                  .map(capitalize)
                  .join(" "),
              ].join(" · ")}
            </Text>
          );
        }}
        onSubmit={(values) =>
          optingForRole !== "sponsored" ? undefined : validateSponsor(values)
        }
      >
        <Tiles columns={1}>
          <Text size="lg">
            {t("funnel.signup.data.steps.personal_data.description")}
          </Text>
          <Box mb={4}>
            <RadioToggle.FormField
              name="is_company"
              leftLabel={t(
                "funnel.signup.data.steps.personal_data.i_am_a_person"
              )}
              leftValue={false}
              rightLabel={t(
                "funnel.signup.data.steps.personal_data.i_am_a_company"
              )}
              rightValue={true}
            />
          </Box>
          <Tiles columns={2} spacing={4}>
            {optingForRole === "sponsored" && (
              <>
                <TextField.FormField
                  name="sponsor_vat"
                  validate={composeValidators(required, matchVatFormat)}
                  label={t(
                    "funnel.signup.data.steps.personal_data.sponsor_vat_number"
                  )}
                />
                <TextField.FormField
                  name="sponsor_code"
                  validate={required}
                  label={t(
                    "funnel.signup.data.steps.personal_data.sponsor_code"
                  )}
                  info={t(
                    "funnel.signup.data.steps.personal_data.sponsor_tooltip",
                    { literalSponsorScreen: t("sponsor.title") }
                  )}
                />
              </>
            )}
            <Condition when="is_company" is={false}>
              <TextField.FormField
                name="vat"
                validate={composeValidators(required, matchVatFormat)}
                label={t("funnel.signup.data.steps.personal_data.vat_number")}
              />
              <TextField.FormField
                name="name"
                validate={required}
                label={t("funnel.signup.data.steps.personal_data.name")}
              />
              <TextField.FormField
                name="surname"
                validate={required}
                label={t("funnel.signup.data.steps.personal_data.surname")}
              />
              <TextField.FormField
                name="lastname"
                validate={requiredLastName}
                label={t("funnel.signup.data.steps.personal_data.lastname")}
              />
              <TextField.FormField
                name="email"
                validate={composeValidators(required, matchEmailFormat)}
                label={t("funnel.signup.data.steps.personal_data.email")}
              />
              <TextField.FormField
                name="repeat_email"
                validate={composeValidators(required, mustMatchOther("email"))}
                label={t("funnel.signup.data.steps.personal_data.repeat_email")}
              />
              <TextField.FormField
                name="phone"
                mask="000 00 00 00"
                validate={composeValidators(required, validPhone)}
                label={t("funnel.signup.data.steps.personal_data.phone")}
              />
              <TextField.FormField
                placeholder="dd/mm/aaaa"
                showPlaceHolderMask
                mask="00/00/0000"
                name="birthdate"
                validate={composeValidators(
                  required,
                  matchDateFormat,
                  validBirthdate,
                  mustBeAdult
                )}
                label={t("funnel.signup.data.steps.personal_data.birthdate")}
              />
              <Select.FormField
                name="gender"
                validate={required}
                label={t("funnel.signup.data.steps.personal_data.gender")}
                i18nPrefix="funnel.selections"
                options={genders}
              />
              <Select.FormField
                name="lang"
                validate={required}
                i18nPrefix="funnel.selections"
                options={["ca", "es"]}
                label={t(
                  "funnel.signup.data.steps.personal_data.preferred_language"
                )}
              />
              <Condition
                when="vat"
                is={(vat = "") => spainIdType(vat) === "nie"}
                renderWhenFalse={<div />}
              >
                <Select.FormField
                  name="nationality"
                  validate={required}
                  label={t(
                    "funnel.signup.data.steps.personal_data.nationality"
                  )}
                  options={nationalities}
                />
              </Condition>
            </Condition>
            <Condition when="is_company" is={true}>
              <TextField.FormField
                name="vat"
                validate={composeValidators(required, matchVatFormat)}
                label={t(
                  "funnel.signup.data.steps.personal_data.vat_number_company"
                )}
              />
              <div />
              <TextField.FormField
                name="name"
                validate={required}
                label={t("funnel.signup.data.steps.personal_data.company_name")}
              />
              {false && (
                <TextField
                  name="tradename"
                  validate={required}
                  label={t("funnel.signup.data.steps.personal_data.trade_name")}
                />
              )}
              <TextField.FormField
                name="email"
                validate={composeValidators(required, matchEmailFormat)}
                label={t("funnel.signup.data.steps.personal_data.email")}
              />
              <TextField.FormField
                name="repeat_email"
                validate={mustMatchOther("email")}
                label={t("funnel.signup.data.steps.personal_data.repeat_email")}
              />
              <TextField.FormField
                name="phone"
                mask="000 00 00 00"
                validate={composeValidators(required, validPhone)}
                label={t("funnel.signup.data.steps.personal_data.phone")}
              />
              <Select.FormField
                name="lang"
                validate={required}
                options={[
                  {
                    value: "ca",
                    label: "Català",
                  },
                  {
                    value: "es",
                    label: "Castellano",
                  },
                ]}
                label={t(
                  "funnel.signup.data.steps.personal_data.preferred_language"
                )}
              />
              <Select.FormField
                name="nationality"
                validate={required}
                label={t("funnel.signup.data.steps.personal_data.nationality")}
                options={nationalities}
              />
            </Condition>
            <div />
            <Form.Submit>{t("common.continue")}</Form.Submit>
          </Tiles>
        </Tiles>
      </FormStep>
      <FormStep
        id="partner/additional-data"
        title={t(
          "funnel.signup.data.steps.personal_data_additional_data.title"
        )}
        renderSummary={(values) => {
          if (!confirmMode) {
            return null;
          }

          return (
            <ConfirmationSummary
              title={t(
                "funnel.signup.data.steps.personal_data_additional_data.title"
              )}
              i18nPrefix="funnel.signup.data.steps.personal_data_additional_data.confirmation"
              labelResolvers={{
                discovery_channel: getDiscoveryChannels,
              }}
              fields={{
                discovery_channel: values.discovery_channel_id,
              }}
            />
          );
        }}
        onSubmit={() => {
          if (!goToPayment) {
            return;
          }

          history.push("payment");
        }}
      >
        <Tiles columns={1}>
          <Text size="lg">
            {t(
              "funnel.signup.data.steps.personal_data_additional_data.description"
            )}
          </Text>
          <Tiles columns={1} spacing={4}>
            <ApiSelect
              name="discovery_channel_id"
              validate={required}
              label={t(
                "funnel.signup.data.steps.personal_data_additional_data.discovery_channel"
              )}
              mapItem={(item) => ({ label: item.name, value: item.id })}
              query={getDiscoveryChannels}
              sortData={false}
            />
            <div />
            <Form.Submit>{t("common.continue")}</Form.Submit>
          </Tiles>
        </Tiles>
      </FormStep>
    </FormStepGroup>
  );
};
