import React, { useEffect, useState, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useTariffs } from "hooks/queries/useTariffs";
import { useStore } from "hooks/useStore";
import { useLinesDerivedState } from "hooks/useLinesDerivedState";
import { decorateOfferTariffs } from "hooks/queries/helper/tariffDecorator";
import { getUrlQuery } from "lib/helpers/urls";
import { useOfferModal } from "hooks/useOfferModal";
import { typeToCollection } from "lib/helpers/urls";

import { Card } from "components/Card";
import { Heading } from "components/Heading";
import { StyledIframeSpinner } from "components/Spinner";
import { Container } from "components/layouts/Container";
import { MobileTariffPicker } from "components/views/MobileTariffPicker";
import { InternetTariffPicker } from "components/views/InternetTariffPicker";
import { AddButton } from "components/shared/AddButton";
import { Text } from "components/Text";
import { CtaFooter } from "../shared/TariffsCtaFooter";

const stylesSecondaryMain = {
  default: { footer: "0", container: "0" },
  multiline: { footer: "-60", container: "20" },
};

function iframeScrollTop() {
  if (!window.parentIFrame) {
    return;
  }
  window.parentIFrame.getPageInfo((obj) => {
    window.parentIFrame.scrollTo(-1 * obj.scrollTop, 0);
    window.parentIFrame.getPageInfo(false);
  });
}

function Error({ data }) {
  return <span>{JSON.stringify(data)}</span>;
}

export const PersonalizedTariffPicker = ({
  type,
  openConfirmModal,
  queryParams,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { lines, setLines, addMobileLine, updateLineAt } = useStore();

  const {
    internetLines,
    mobileLines,
    isMultipleLines,
    hasFiberLineToAssociateMobile,
    hasEmptyLines,
    isLineByIdEmpty,
    linesTotalAmount,
    getRemoveLineButton,
  } = useLinesDerivedState();

  const [styleSecondaryMain, setStyleSecondaryMain] = useState(
    stylesSecondaryMain.default
  );
  useEffect(() => {
    setStyleSecondaryMain(
      isMultipleLines
        ? stylesSecondaryMain.multiline
        : stylesSecondaryMain.default
    );
  }, [isMultipleLines]);

  const types = typeToCollection(type).map(({ type }) => type);
  useEffect(() => setLines(typeToCollection(type)), [type]);

  useEffect(iframeScrollTop, []);

  const [idMobileTariffInOffer, setIdMobileTariffInOffer] = useState(-1);
  const [internetError, setInternetError] = useState(false);

  const {
    isLoading: isLoadingTariffs,
    error: errorLoaingTariffs,
    data: tariffs = [],
  } = useTariffs();

  const offers = useMemo(() => {
    return tariffs.filter((t) => t.offer).map(decorateOfferTariffs);
  }, [tariffs]);

  const {
    Modal: OfferModal,
    isLoading: isLoadingFibers,
    error: errorLoadingFibers,
    setIsOpen: openOfferModal,
    canOpen: canOpenOfferModal,
    offer: availableOffer,
    index: indexToSetOffer,
  } = useOfferModal(type, offers);

  const onClickSubHeader = () => {
    history.push(getUrlQuery("/tariffs/recommended", queryParams));
  };

  const getInitialTariffCode = (mobileLineIndex) => {
    if (indexToSetOffer === mobileLineIndex) {
      return availableOffer.code;
    }
  };

  const canShowMobileTariffInOffer = (mobileLineId) => {
    return (
      hasFiberLineToAssociateMobile &&
      (idMobileTariffInOffer < 0 || idMobileTariffInOffer === mobileLineId)
    );
  };

  const handleOnClickHire = () => {
    if (types.includes("internet") && hasEmptyLines) {
      setInternetError(true);
    } else if (canOpenOfferModal) {
      openOfferModal(true);
    } else {
      const isSharedLines = false;
      openConfirmModal(isSharedLines, lines);
    }
  };

  const handleOnChangeMobileTariff = (tariff, index, line__id) => {
    if (tariff?.is_offer_tariff) {
      setIdMobileTariffInOffer(line__id);
    } else if (idMobileTariffInOffer === line__id) {
      setIdMobileTariffInOffer(-1);
    }
    updateLineAt(tariff, "mobile", internetLines.length + index);
  };

  if (isLoadingTariffs || isLoadingFibers) return <StyledIframeSpinner />;
  if (errorLoaingTariffs || errorLoadingFibers)
    return <Error data={errorLoaingTariffs || errorLoadingFibers} />;

  return (
    <>
      <Container variant="narrow">
        <Heading>{t(`funnel.tariffs.${type}.title`)}</Heading>
        {type === "internet_and_mobile" && (
          <Box mt={5}>
            <Text size="md" semibold selectable={false}>
              {t("funnel.tariffs.internet_and_mobile.sub_header.question")}
            </Text>
            <Text
              size="md"
              bold
              selectable={false}
              underline
              onClick={() => onClickSubHeader()}
            >
              {t("funnel.tariffs.internet_and_mobile.sub_header.answer")}
            </Text>
          </Box>
        )}
        {internetLines.map((line, index) => (
          <Box key={line.__id} mt={5}>
            <Card>
              <InternetTariffPicker
                onChange={(tariff) => updateLineAt(tariff, "internet", index)}
                tariffs={tariffs.sort(
                  (tariff_a, tariff_b) =>
                    tariff_a.bandwidth - tariff_b.bandwidth
                )}
                error={internetError ? isLineByIdEmpty(line.__id) : false}
              />
            </Card>
          </Box>
        ))}
        {mobileLines.map((line, index) => (
          <Box key={line.__id} mt={5}>
            <Card
              topRightAction={
                index > 0 &&
                getRemoveLineButton(line.__id, line.type, () =>
                  setIdMobileTariffInOffer(-1)
                )
              }
            >
              <MobileTariffPicker
                onChange={(tariff) =>
                  handleOnChangeMobileTariff(tariff, index, line.__id)
                }
                tariffs={
                  canShowMobileTariffInOffer(line.__id)
                    ? offers.concat(tariffs.filter((t) => !t.offer))
                    : tariffs
                }
                initialTariffCode={getInitialTariffCode(index)}
              />
            </Card>
          </Box>
        ))}
        {types.includes("mobile") && mobileLines.length < 4 && (
          <Box mt={5} pb={5}>
            <AddButton
              onClick={addMobileLine}
              text={t("funnel.tariffs.mobile.add_line")}
            />
          </Box>
        )}
      </Container>
      <CtaFooter
        footerTranslateY={styleSecondaryMain.footer}
        containerTranslateY={styleSecondaryMain.container}
        isMultipleLines={isMultipleLines}
        linesTotalAmount={linesTotalAmount}
        handleOnClickHire={handleOnClickHire}
        showContract={true}
      />
      <OfferModal />
    </>
  );
};
