import React from "react";
import { iconFor } from "lib/domain/somconnexio/iconFor";

const sizesMap = {
  small: 50,
  big: 70,
  full: "100%",
};

export const SubscriptionIcon = ({ type, variant = "small" }) => (
  <img
    alt={`Subscription type ${type}`}
    style={{ width: "auto", height: sizesMap[variant], display: "flex" }}
    src={`/icons/${iconFor(type)}.png`}
  />
);
