import React from "react";
import { SidebarLayout } from "components/layouts/SidebarLayout";
import { Box, Card, Typography } from "@material-ui/core";
import { Stack } from "components/layouts/Stack";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { useTranslation } from "react-i18next";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { ChangeEmail } from "./ChangeEmail";
import { ChangePassword } from "./ChangePassword";
import { useApplicationContext } from "hooks/useApplicationContext";

const Index = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { currentUser } = useApplicationContext();

  return (
    <Box component={Card} p={4}>
      <Stack spacing={2}>
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <MailOutlineIcon fontSize="large" color="action" />
          </Box>
          <Stack>
            <Typography>{t("profile.index.email")}</Typography>
            <Typography color="textSecondary">{currentUser.email}</Typography>
            <Link to={`${path}/change-email`}>{t("common.change")}</Link>
          </Stack>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <VpnKeyIcon fontSize="large" color="action" />
          </Box>
          <Stack>
            <Typography>{t("profile.index.password")}</Typography>
            <Typography color="textSecondary">****</Typography>
            <Link to={`${path}/change-password`}>{t("common.change")}</Link>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export const Profile = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <SidebarLayout>
        <Route exact path={path}>
          <Index />
        </Route>

        <Route exact path={`${path}/change-email`}>
          <ChangeEmail />
        </Route>

        <Route exact path={`${path}/change-password`}>
          <ChangePassword />
        </Route>
      </SidebarLayout>
    </Switch>
  );
};
