import { get, patch } from "axios";

export const getConfirmTicket = async ({ type, token }) => {
  const data = await get(`/confirm-ticket/${token}/`, {
    params: { ticket_type: type },
  });
  return data;
};

export const patchConfirmTicket = async ({
  ticketType,
  ticketId,
  token,
  fields,
}) => {
  const headers = {
    "ticket-id": ticketId,
    "ticket-type": ticketType,
  };
  const data = {
    meta: fields,
  };
  const result = await patch(`/confirm-ticket/${token}/`, data, {
    headers: headers,
  });
  return result;
};
