import { get, post } from "axios";

export const createTicket = async ({
  body,
  subject,
  meta,
  override_ticket_ids,
}) => {
  return await post(`/tickets/`, {
    body,
    subject,
    meta,
    override_ticket_ids,
  });
};

export const getTickets = async ({ type, ...filters }) => {
  return await get(`/tickets/`, {
    params: { ticket_type: type, ...filters },
  });
};

export const postRequestChange = async ({
  topic,
  newValue,
  scope,
  selectedSubscriptions,
  lang,
  documentation,
}) => {
  return await createTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: `change_${topic}`,
      },
      {
        key: "new_value",
        value: newValue,
      },
      {
        key: "scope",
        value: scope,
      },
      {
        key: "selected_subscriptions",
        value: selectedSubscriptions,
      },
      {
        key: "language",
        value: lang,
      },
      {
        key: "docs",
        value: documentation,
      },
    ],
  });
};
