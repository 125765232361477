import React from "react";
import Box from "@material-ui/core/Box";

export const Stack = ({ children, spacing = 1, align = "flex-start" }) => {
  const count = React.Children.count(children);

  return (
    <Box display="flex" width="100%" flexDirection="column">
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <Box
              width="100%"
              display="flex"
              mb={index < count - 1 && spacing}
              justifyContent={align}
            >
              {child}
            </Box>
          )
      )}
    </Box>
  );
};
