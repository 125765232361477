import { get } from "axios";

export const checkInvoice = async ({ id }) => {
  let result;

  try {
    await get(`/invoices/check/${id}/`);
    result = true;
  } catch (e) {
    result = false;
    /* handle error */
  }

  return result;
};

export const getPaginateInvoicesList = async ({
  offset = 0,
  limit = 10,
} = {}) => {
  try {
    const { data, headers } = await get(`/invoices/`, {
      params: { offset, limit },
    });
    return { items: data, paginControl: headers };
  } catch (e) {
    console.error(e);
    /* handle error */
  }
};
