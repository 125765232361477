import { get, patch } from "axios";

export async function getAssets(assetsRefs) {
  const res = await get("/assets/", {
    headers: {
      "assets-ref": assetsRefs.join(";"),
    },
  });

  return res.data;
}

export async function getAsset(assetRef) {
  const res = await get(`/assets/${assetRef}/`);
  return res.data;
}

export async function patchAsset(assetId, meta) {
  const res = await patch(`/assets/${assetId}/`, { meta });
  return res.data;
}

export async function updateAssetDataPercentage(assetId, percentage) {
  return await patchAsset(assetId, [
    {
      key: "asset_update_type",
      value: "shared_consumption_percentage",
    },
    {
      key: "data_percentage",
      value: percentage,
    },
  ]);
}
