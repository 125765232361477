import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Card } from "components/Card";
import { Text } from "components/Text";
import { Tiles } from "components/layouts/Tiles";
import { TelecommProduct } from "./TelecommProduct";

import { Button } from "components/Button";
import { PricePreview } from "components/shared/PricePreview";

export const Pack = ({ pack, onSelect, isMobileSharedData }) => {
  const { t } = useTranslation();
  const onClickContract = () => onSelect(pack);
  const productsList = isMobileSharedData
    ? [
        ...new Map(
          pack.products.map((product) => [`${product.code}`, product])
        ).values(),
      ]
    : pack.products;

  return (
    <Card key={pack.code} variant="balanced">
      <Box display="flex" flexDirection="column" height="90%">
        {/* 
              value |0px     600px    960px  ...   
              key   |xs      sm       md     ...  
          */}
        <Box mb={2}>
          <Text bold mb={2}>
            {pack.name}
          </Text>
        </Box>
        <Card variant="noGutter" shadow={false} isInformative p={4}>
          <Tiles columns={1}>
            {productsList.map((product, index) => (
              <TelecommProduct
                key={`${product.code}-${index}`}
                {...product}
                quantity={
                  product.category === "fiber" ? 1 : pack.mobiles_in_pack
                }
              />
            ))}
          </Tiles>
        </Card>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
          mt={1}
        >
          <Tiles columns={1}>
            <PricePreview
              legend={""}
              details={["totalAmount"]}
              tariff={{ price: pack.price }}
              showReasons={false}
            />
            <Button onClick={onClickContract} type="submit">
              {t("common.contract")}
            </Button>
          </Tiles>
        </Box>
      </Box>
    </Card>
  );
};
