import { Trans, useTranslation } from "react-i18next";
import { Form } from "components/Form";
import { Select } from "components/Select";
import { Tiles } from "components/layouts/Tiles";
import { required, validateSponsor } from "lib/form/validators";
import { Condition } from "screens/SignUp/shared/Condition";
import { useChangeOwnerContext } from "../context";
import { Common } from "./personalData/Common";
import { NewPartner } from "./personalData/NewPartner";
import { NewSponsorship } from "./personalData/NewSponsorship";
import { NewUser } from "./personalData/NewUser";
import { FormSection } from "../shared/FormSection";
import { ConfirmationSummary } from "screens/SignUp/shared/ConfirmationSummary";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Tooltip } from "components/Tooltip";
import React from "react";
import { InfoIcon } from "components/icons/InfoIcon";
import { Text } from "components/Text";
export function NewOwnerData() {
  const {
    ticket,
    stepIndex,
    setStepIndex,
    newOwnerData,
    setNewOwnerData,
    getTopRightAction,
  } = useChangeOwnerContext();
  const { t } = useTranslation();
  /* 
    value |0px     600px    960px  ...   
    key   |xs      sm       md     ...  
  */
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onFormSubmit = async (values) => {
    if (values.role_new_owner === "sponsorship") {
      const submitErrors = await validateSponsor(values);
      if (submitErrors) {
        return submitErrors;
      }
    }
    setNewOwnerData(values);
    setStepIndex((prev) => prev + 1);
  };
  const initialValues = newOwnerData
    ? { ...newOwnerData }
    : {
        email_new_owner: ticket.new_email,
        new_owner_is_company: false,
      };

  const ChangeOwnerRole = () => {
    return [
      {
        value: "partner",
        label: t("funnel.join.anonymous.i_have_an_account"),
      },
      {
        value: "new_partner",
        label: t("funnel.join.anonymous.i_want_an_account"),
      },
      {
        value: "new_user",
        label: t("funnel.join.anonymous.i_want_as_user"),
      },
      {
        value: "sponsorship",
        label: t("funnel.join.anonymous.partner_sponsors_me"),
      },
    ];
  };

  return (
    <Tiles columns={1} spacing={5}>
      <FormSection
        index={1}
        title={t("request_change.change_owner.next_data.title")}
        stepIndex={stepIndex}
        getSummary={() => (
          <ConfirmationSummary
            i18nPrefix="request_change.change_owner.current_data.fields"
            fields={{
              fullname: newOwnerData.name_new_owner,
              vat: newOwnerData.vat_new_owner,
            }}
          />
        )}
        topRightAction={getTopRightAction}
      >
        <Form
          id={"change-owner"}
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        >
          <Tiles columns={1} spacing={4}>
            <Box display="flex" flexDirection="row">
              <Select.FormField
                name={"role_new_owner"}
                label={t("request_change.change_owner.next_data.select_role")}
                options={ChangeOwnerRole()}
                validate={required}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                ml={2}
              >
                <Tooltip
                  variant="info"
                  interactive
                  enterTouchDelay={0}
                  leaveTouchDelay={0}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={
                    <React.Fragment>
                      <Text size="sm">
                        <Trans
                          i18nKey={`funnel.tariffs.step_confirm_modal.second_step.body.${
                            isExtraSmallScreen ? "mobile" : "desktop"
                          }`}
                        />
                      </Text>
                    </React.Fragment>
                  }
                  arrow
                >
                  <div>
                    <InfoIcon />
                  </div>
                </Tooltip>
              </Box>
            </Box>
            <Condition when="role_new_owner" is={(role) => !!role}>
              <Common />
            </Condition>
            <Condition when="role_new_owner" is={"new_partner"}>
              <NewPartner />
            </Condition>
            <Condition when="role_new_owner" is={"sponsorship"}>
              <NewSponsorship />
            </Condition>
            <Condition
              when="role_new_owner"
              is={(role) => !!role && role !== "partner"}
            >
              <NewUser />
            </Condition>
            <Form.Submit>{t("common.continue")}</Form.Submit>
          </Tiles>
        </Form>
      </FormSection>
    </Tiles>
  );
}
