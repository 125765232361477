import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export default function AuthProvider({ keycloak, children }) {
  return (
    <AuthContext.Provider value={keycloak}>{children}</AuthContext.Provider>
  );
}
