import Alert from "@material-ui/lab/Alert";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { useContract } from "hooks/queries/useContracts";
import { Box, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import { EditIcon } from "components/icons/EditIcon";
import { SubscriptionDetailTab } from "./views/SubscriptionDetailTab";
import { SubscriptionOperationsTab } from "./views/SubscriptionOperationsTab";
import { BackButton } from "components/BackButton";
import { Inline } from "components/layouts/Inline";
import { SubscriptionIcon } from "./shared/SubscriptionIcon";
import { SubscriptionEditTab } from "./views/SubscriptionEditTab";
import { useApplicationContext } from "hooks/useApplicationContext";
const useStyles = makeStyles((theme) => ({
  tab_icon: {
    "color": theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}));
export const SubscriptionDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { currentUser } = useApplicationContext();
  const iconStyle = useStyles().tab_icon;

  const [tabIndex, setTabIndex] = useState("0");
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const {
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
    data: subscription,
  } = useContract({
    userName: currentUser?.username,
    id: id,
  });

  if (isSubscriptionLoading) {
    return <Spinner />;
  }

  if (isSubscriptionError) {
    return <Alert severity="error">{t("common.errors.request_failed")}</Alert>;
  }

  const data = subscription.data;

  return (
    <Fragment>
      <Box mb={2}>
        <BackButton />
      </Box>
      <Box m={6}>
        <Inline spacing={2}>
          <SubscriptionIcon variant="big" type={data.subscription_type} />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h6">
              {t(`subscriptions.type.${data.subscription_type}`)}
            </Typography>
            <Typography color="textSecondary">{data.phone_number}</Typography>
            <Typography color="textSecondary" variant="subtitle2">
              {data.description}
            </Typography>
          </Box>
        </Inline>
      </Box>
      <TabContext value={tabIndex}>
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
          <Tab
            label={t("common.detail")}
            icon={<InfoIcon className={iconStyle} />}
            value="0"
          />
          <Tab
            label={t("common.operate")}
            icon={<SettingsIcon className={iconStyle} />}
            value="1"
          />
          <Tab
            label={t("common.edit")}
            icon={<EditIcon className={iconStyle} />}
            value="2"
          />
        </Tabs>
        <TabPanel value="0">
          <SubscriptionDetailTab {...data} />
        </TabPanel>
        <TabPanel value="1">
          <SubscriptionOperationsTab {...data} />
        </TabPanel>
        <TabPanel value="2">
          <SubscriptionEditTab {...data} />
        </TabPanel>
      </TabContext>
    </Fragment>
  );
};
