export const ChevronUp = () => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 9L10 0.999999L1 9" stroke="white" />
  </svg>
);
