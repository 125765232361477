export const CloseIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="17.3536"
      y1="1.35355"
      x2="0.382991"
      y2="18.3241"
      stroke="#3E3382"
    />
    <line
      x1="0.353553"
      y1="0.646447"
      x2="17.3241"
      y2="17.617"
      stroke="#3E3382"
    />
  </svg>
);
