import { Box, withStyles } from "@material-ui/core";
import React from "react";
import cn from "classnames";

const scale = {
  "2xs": "0.625rem",
  "xs": "0.75rem",
  "sm": "0.8125rem",
  "md": "1rem",
  "lg": "1.25rem",
  "xl": "1.5rem",
  "2xl": "2.0rem",
  "3xl": "3.25rem",
};

const StyledBox = withStyles({
  root: {
    letterSpacing: ({ letterSpacing, size }) =>
      letterSpacing ||
      cn(size === "3xl" && "-0.05rem", size === "2xs" && "1px"),
    textDecoration: ({ underline, lineThrough }) =>
      underline ? "underline" : lineThrough ? "line-through" : "none",
    cursor: ({ onClick }) => (Boolean(onClick) ? "pointer" : "inherit"),
    fontFamily: "Work Sans",
    lineHeight: ({ lineHeight }) => lineHeight,
    fontSize: ({ size }) => scale[size],
    textTransform: ({ uppercase, capitalize }) =>
      uppercase ? "uppercase" : capitalize ? "capitalize" : "none",
    userSelect: ({ selectable }) => (selectable ? "auto" : "none"),
    opacity: ({ opacity }) => opacity,
    textAlign: ({ textAlign }) => textAlign,
  },
})(Box);

export const Text = ({
  size = "md",
  bold = false,
  semibold = false,
  underline = false,
  lineThrough = false,
  color = "text.main",
  textAlign,
  paragraph = false,
  uppercase = false,
  capitalize = false,
  selectable = true,
  lineHeight,
  letterSpacing,
  opacity = 1.0,
  children,
  onClick,
}) => (
  <StyledBox
    lineHeight={lineHeight}
    letterSpacing={letterSpacing}
    textAlign={textAlign}
    component={paragraph ? "p" : "span"}
    fontWeight={(bold && "bold") || (semibold && "600") || "normal"}
    underline={underline}
    lineThrough={lineThrough}
    uppercase={uppercase}
    capitalize={capitalize}
    selectable={selectable}
    gutterBottom={false}
    opacity={opacity}
    size={size}
    color={color}
    onClick={onClick}
  >
    {children}
  </StyledBox>
);
