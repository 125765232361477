import { ApplicationContext } from "lib/ApplicationContext";
import { useContext } from "react";

export const useApplicationContext = () => {
  const { contextValue, setContextValue } = useContext(ApplicationContext);

  return {
    ...contextValue,
    updateCurrentUser: (changes) => {
      setContextValue({
        ...contextValue,
        currentUser: {
          ...contextValue.currentUser,
          ...changes,
        },
      });
    },
  };
};
