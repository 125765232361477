import React, { useState, useEffect } from "react";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { Tiles } from "components/layouts/Tiles";
import { Text } from "components/Text";
import { Link } from "components/Link";
import { Trans, useTranslation } from "react-i18next";
import { noop } from "lib/fn/noop";
import { createTicket } from "lib/api/tickets";
import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { getStateByValue } from "lib/helpers/format";
import { useApplicationContext } from "hooks/useApplicationContext";
import { getStartOfToday } from "lib/helpers/dates";

const ModalContentSubmitted = ({ isHandleError, onClickClose }) => {
  const { t } = useTranslation();

  return (
    <Tiles spacing={2} columns={1}>
      {isHandleError ? (
        <Alert severity="error">{t("common.errors.request_failed")}</Alert>
      ) : (
        <Alert severity="success">
          {t("common.petition_received_with_email_confirmation")}
        </Alert>
      )}
      <Button onClick={onClickClose} fullWidth={false}>
        {t("common.close")}
      </Button>
    </Tiles>
  );
};

const ModalContentUnsubmitted = ({
  onSubmit,
  submit,
  onClickClose,
  onContinue,
  fromTechnology,
  activeProductCode,
  isChangeFiberLandline,
}) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const [needsInformation, setNeedsInformation] = useState(true);
  function getLiterals() {
    const literals = {
      confrimLiteral:
        "subscriptions.detail.change_tariff_BA_modal.landLine.ADSL_confirm",
      titleLiteral:
        "subscriptions.detail.change_tariff_BA_modal.landLine.ADSL_title",
      descriptionALiteral:
        "subscriptions.detail.change_tariff_BA_modal.landLine.ADSL_description_a",
      descriptionBLiteral:
        "subscriptions.detail.change_tariff_BA_modal.landLine.ADSL_description_b",
    };
    if (!isChangeFiberLandline) {
      if (fromTechnology == "ADSL") {
        literals.confrimLiteral =
          "subscriptions.detail.change_tariff_BA_modal.ADSL_confirm";
        literals.titleLiteral =
          "subscriptions.detail.change_tariff_BA_modal.ADSL_title";
        literals.descriptionALiteral =
          "subscriptions.detail.change_tariff_BA_modal.ADSL_description_a";
        literals.descriptionBLiteral =
          "subscriptions.detail.change_tariff_BA_modal.ADSL_description_b";
      } else if (activeProductCode == "SE_SC_REC_BA_F_300") {
        literals.confrimLiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_confirm";
        literals.titleLiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_title";
        literals.descriptionALiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_description_a";
        literals.descriptionBLiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_description_b";
      } else if (activeProductCode == "SE_SC_REC_BA_F_100") {
        literals.confrimLiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_confirm";
        literals.titleLiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_title_100";
        literals.descriptionALiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_description_a_100";
        literals.descriptionBLiteral =
          "subscriptions.detail.change_tariff_BA_modal.fiber_description_b";
      }
    }
    return literals;
  }

  const {
    confrimLiteral,
    titleLiteral,
    descriptionALiteral,
    descriptionBLiteral,
  } = getLiterals();

  const onClickContinue = async () => {
    let result = "success";
    onContinue();
    try {
      await submit();
    } catch (e) {
      console.log("e", e);
      result = "error";
    } finally {
      onSubmit(result);
    }
  };

  if (isSelected) {
    return (
      <Tiles spacing={2} columns={1}>
        <Alert severity="info">
          <Text>{t(confrimLiteral)}</Text>
        </Alert>
        <Button onClick={() => onClickContinue()}>
          {t("common.continue")}
        </Button>
        <Button onClick={onClickClose}>{t("common.cancel")}</Button>
      </Tiles>
    );
  }
  if (needsInformation) {
    return (
      <Tiles spacing={2} columns={1}>
        <Typography align="center" variant="h5">
          {t(titleLiteral)}
        </Typography>
        <Text>{t(descriptionALiteral)}</Text>
        <div>
          <Trans i18nKey={descriptionBLiteral}>
            <Link
              target="_blank"
              to={"mailto:" + t("common.assistance_email")}
            />
          </Trans>
        </div>
        <Button
          fullWidth={false}
          onClick={() => {
            setNeedsInformation(false);
            setIsSelected(true);
          }}
        >
          {t("common.accept")}
        </Button>
      </Tiles>
    );
  }
};

const ModalContent = ({ onSubmittingStateChange, ...props }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    onSubmittingStateChange(isSubmitting);
  }, [isSubmitting]);

  if (isSubmitted) {
    return <ModalContentSubmitted isHandleError={hasError} {...props} />;
  } else if (isSubmitting) {
    return <Spinner />;
  } else {
    return (
      <ModalContentUnsubmitted
        onContinue={() => setIsSubmitting(true)}
        onSubmit={(result) => {
          if (result === "error") setHasError(true);
          setIsSubmitting(false);
          setIsSubmitted(true);
        }}
        {...props}
      />
    );
  }
};

export const ChangeTariffModalBA = ({
  isOpen,
  onClose,
  subscription,
  isChangeFiberLandline,
}) => {
  const [shouldBlockModal, setShouldBlockModal] = useState(false);
  const subscriptionTechnology = subscription.subscription_technology;
  const subscriptionActiveProductCode = subscription.active_product_code;
  const { currentUser } = useApplicationContext();

  const submit = async () => {
    return createTicket({
      body: "-",
      subject: "-",
      meta: [
        {
          key: "ticket_type",
          value: "change_tariff_fiber",
        },
        {
          key: "previous_service",
          value: subscription.active_product_code,
        },
        {
          key: "ref_contract",
          value: subscription.code,
        },
        {
          key: "landline_number",
          value: subscription.phone_number,
        },
        {
          key: "service_address",
          value: subscription?.address?.street,
        },
        {
          key: "service_city",
          value: subscription?.address?.city,
        },
        {
          key: "service_zip",
          value: subscription?.address?.zip_code,
        },
        {
          key: "service_state",
          value: subscription?.address?.state,
        },
        {
          key: "service_state_code",
          value: getStateByValue(subscription?.address?.state),
        },
        {
          key: "complete_name",
          value: subscription.complete_name,
        },
        {
          key: "name",
          value: subscription.name,
        },
        {
          key: "sur_name",
          value: subscription.sur_name,
        },
        {
          key: "last_name",
          value: subscription.last_name,
        },
        {
          key: "vat_number",
          value: subscription.vat_number,
        },
        {
          key: "iban",
          value: subscription.iban,
        },
        {
          key: "customer_email",
          value: currentUser.email,
        },
        {
          key: "effective_date",
          value: getStartOfToday("otrs"),
        },
        {
          key: "customer_phone",
          value: currentUser.phone,
        },
        {
          key: "is_fiber_landline",
          value: isChangeFiberLandline,
        },
        {
          key: "previous_technology",
          value: subscriptionTechnology,
        },
      ],
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={shouldBlockModal ? noop : onClose}
      showCloseButton={!shouldBlockModal}
    >
      <ModalContent
        onSubmittingStateChange={(isSubmitting) =>
          setShouldBlockModal(isSubmitting)
        }
        submit={submit}
        onClickClose={onClose}
        fromTechnology={subscriptionTechnology}
        activeProductCode={subscriptionActiveProductCode}
        isChangeFiberLandline={isChangeFiberLandline}
      />
    </Modal>
  );
};
