import {
  TextField,
  Box,
  LinearProgress,
  Typography,
  styled,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Alert } from "@material-ui/lab";

import { checkCredentials } from "lib/api/auth";
import { useApplicationContext } from "hooks/useApplicationContext";
import { ModalLayout } from "components/layouts/ModalLayout";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { useTranslation } from "react-i18next";

const ModalContentWrapper = styled("div")({
  width: "360px",
  minHeight: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const SecretFieldModal = ({
  isOpen,
  resolveValue,
  onClose,
  message,
  timeout = 10,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [value, setValue] = useState(null);
  const [time, setTime] = useState(0);
  const [error, setError] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    if (!value) return;

    timer.current = setInterval(() => {
      setTime((time) => {
        if (time === timeout) return 0;
        return Math.min(time + 0.5, timeout);
      });
    }, 500);
  }, [value]);

  useEffect(() => {
    if (timer.current && time === 0) hide();
  }, [time]);

  useEffect(() => {
    if (!error) return;

    setValue(null);
    setPassword(null);
  }, [error]);

  const hide = () => {
    setIsLoading(false);
    setValue(null);
    setPassword(null);
    setError(null);
    setTime(0);
    clearInterval(timer.current);
    timer.current = null;
    onClose();
  };

  const onSubmit = async (password) => {
    const credentials = {
      username: currentUser.username,
      password,
    };

    try {
      setIsLoading(true);
      await checkCredentials(credentials.username, credentials.password);
      const value = await resolveValue();
      setValue(value);
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const listenKeyboard = (keyCode) => {
    if (keyCode === 13) onSubmit(password);
  };

  const modalContent = () => {
    if (isLoading)
      return (
        <ModalContentWrapper>
          <Spinner />
        </ModalContentWrapper>
      );

    if (error) {
      return (
        <ModalContentWrapper>
          <Alert severity="error">
            {t("subscriptions.detail.secret_modal.error.password")}
          </Alert>
          <Box mt={4}>
            <Typography>
              {t("subscriptions.detail.secret_modal.error.message")}
            </Typography>
          </Box>
          <Box mt={3} display="flex" flexDirection="row">
            <Button onClick={() => setError(null)}>{t("common.accept")}</Button>
          </Box>
        </ModalContentWrapper>
      );
    }

    if (value) {
      return (
        <ModalContentWrapper>
          <Box my={3}>
            <Typography variant="h4" align="center">
              {value}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={(time / timeout) * 100}
          />
          <Typography align="center">{Math.floor(timeout - time)}s</Typography>
        </ModalContentWrapper>
      );
    }

    return (
      <ModalContentWrapper>
        {message && (
          <Box my={3}>
            <Typography>{message}</Typography>
          </Box>
        )}
        <TextField
          id="standard-password-input"
          label={t("login.password")}
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          onKeyDown={(ev) => listenKeyboard(ev.keyCode)}
          fullWidth
        />
        <Box mt={3} display="flex" flexDirection="row" justifyContent="center">
          <Box mr={1}>
            <Button onClick={() => onSubmit(password)}>
              {t("common.accept")}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button onClick={hide}>{t("common.cancel")}</Button>
          </Box>
        </Box>
      </ModalContentWrapper>
    );
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={hide} fullWidth={false}>
      {modalContent()}
    </ModalLayout>
  );
};
