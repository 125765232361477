import { theme } from "theme";

export const ThreeMobiles = () => (
  <svg
    focusable="false"
    viewBox="0 0 18 30"
    aria-hidden="true"
    width="1em"
    height="1em"
    title="3_mobiles"
    style={{ fontSize: theme.spacing(8) }}
  >
    <path
      d="M17 8h-8c-1.66 0-3 1.34-3 3v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3v-16c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3h-10.5v-14h10.5v14zm-1.25-17.25v-.75c0-1.66-1.34-3-3-3h-8c-1.66 0-3 1.34-3 3v16c0 1.66 1.34 3 2.5 3v-5h-.75v-14h10.5v.75zm-3-4v-.75c0-1.66-1.34-3-3-3h-8c-1.66 0-3 1.34-3 3v16c0 1.66 1.34 3 2.5 3v-5h-.75v-14h10.5v.75z"
      fill={theme.palette.primary.main}
    ></path>
  </svg>
);
