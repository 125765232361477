import React, { useEffect, useState } from "react";
import { Text } from "components/Text";
import { Tiles } from "components/layouts/Tiles";
import { Box } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { useStore } from "hooks/useStore";
import { Card } from "components/Card";
import { Spinner } from "components/Spinner";
import { capitalize } from "lib/helpers/string";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useQueryParam, StringParam } from "use-query-params";
import { buildAnalyticsParamFromState } from "./shared/buildAnalyticsParamFromState";
import { CheckIcon } from "components/icons/CheckIcon";
import { useHistory } from "react-router-dom";

export const Thanks = () => {
  const history = useHistory();
  const store = useStore((state) => state);
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);

  const username = currentUser
    ? currentUser.first_name
    : capitalize(store.formStepDataByKey["partner/personal-data"].name);

  const onlyMembership = store.lines.length === 0;

  const [, setFormParam] = useQueryParam("form", StringParam);

  const [tracked, setTracked] = useState(false);
  useEffect(() => {
    if (window.fbq instanceof Function) {
      window.fbq("track", "Lead");
    }
    setTracked(true);
  }, []);

  useEffect(() => {
    if (loggedIn && tracked) {
      localStorage.setItem(
        "flash-message",
        t("funnel.signup.thanks.flash_message")
      );

      store.resetSignupFlow();
      history.push("/home");
    }
  }, [loggedIn, tracked]);

  useEffect(() => {
    setFormParam(buildAnalyticsParamFromState(store, "thanks"));
  }, [store, setFormParam]);

  if (loggedIn) {
    return <Spinner />;
  }

  return (
    <Tiles columns={2} spacing={0}>
      <video
        preload="auto"
        muted
        autoPlay
        loop
        alt={t("common.thanks")}
        style={{
          width: "100%",
          height: "100%",
          maxHeight: 400,
          objectFit: "cover",
        }}
        src="/thanks.webm"
      >
        <source src="/thanks.webm" type="video/webm" />
      </video>
      <Box
        bgcolor="white"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={8}
        py={5}
      >
        <Tiles columns={1}>
          <CheckIcon />
          <div style={{ maxWidth: 555 }}>
            <Text size="xl">
              <Trans i18nKey="funnel.signup.thanks.welcome">
                <Text size="xl" bold>
                  {{ username }}
                </Text>
              </Trans>
            </Text>
          </div>
        </Tiles>
      </Box>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <div
          style={{
            position: "relative",
            top: -24,
            width: "100%",
            maxWidth: 500,
          }}
        >
          {false && <Card>twitter share</Card>}
        </div>
      </Box>
      <Box px={8} py={8}>
        <div style={{ maxWidth: 555 }}>
          <Tiles columns={1}>
            <Text bold size="xl">
              {t("funnel.signup.thanks.now_what_title")}
            </Text>
            <Text size="lg">
              {onlyMembership
                ? t("funnel.signup.thanks.now_what_body_only_membership")
                : t("funnel.signup.thanks.now_what_body")}
            </Text>
          </Tiles>
        </div>
      </Box>
    </Tiles>
  );
};
