import React from "react";
import MaterialTextField from "@material-ui/core/TextField";

/**
 * Preconfigured Material UI TextInput for consistency sake
 */
export const LegacyTextField = React.forwardRef(
  (
    {
      type = "text",
      value,
      setState,
      label,
      variant = "outlined",
      onFocus = () => {}
    },
    ref
  ) => (
    <MaterialTextField
      inputRef={ref}
      type={type}
      label={label}
      value={value}
      onChange={event => setState(event.target.value)}
      onFocus={onFocus}
      fullWidth
      variant={variant}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
);
