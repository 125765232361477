export const PhoneIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6663 2.66667C15.581 2.66667 17.333 5.51267 17.333 8.33333C17.333 8.51733 17.4823 8.66667 17.6663 8.66667C17.8503 8.66667 17.9997 8.51733 17.9997 8.33333C17.9997 5.18067 16.0417 2 11.6663 2C11.4823 2 11.333 2.14933 11.333 2.33333C11.333 2.51733 11.4823 2.66667 11.6663 2.66667Z"
      fill="#853358"
      stroke="#853358"
      stroke-width="0.5"
    />
    <path
      d="M11.6663 6.00004C13.595 6.00004 13.9997 7.26871 13.9997 8.33337C13.9997 8.51737 14.149 8.66671 14.333 8.66671C14.517 8.66671 14.6663 8.51737 14.6663 8.33337C14.6663 6.42671 13.5723 5.33337 11.6663 5.33337C11.4823 5.33337 11.333 5.48271 11.333 5.66671C11.333 5.85071 11.4823 6.00004 11.6663 6.00004Z"
      fill="#853358"
      stroke="#853358"
      stroke-width="0.5"
    />
    <path
      d="M16.8333 12.6667C15.8173 12.6667 14.468 12.34 13.6253 12.062C13.214 11.92 12.7433 12.0293 12.4733 12.3053L10.6393 13.61C8.52333 12.4913 7.178 11.152 6.07267 9.05933L7.654 7.41933C7.96533 7.108 8.07733 6.65333 7.94333 6.22867C7.53867 5.002 7.33333 3.972 7.33333 3.16667C7.33333 2.52333 6.81 2 6.16667 2H3.16667C2.52333 2 2 2.52333 2 3.16667C2 11.2073 8.79267 18 16.8333 18C17.4767 18 18 17.4767 18 16.8333V13.8333C18 13.19 17.4767 12.6667 16.8333 12.6667ZM17.3333 16.8333C17.3333 17.1093 17.1087 17.3333 16.8333 17.3333C9.154 17.3333 2.66667 10.846 2.66667 3.16667C2.66667 2.89067 2.89133 2.66667 3.16667 2.66667H6.166C6.44133 2.66667 6.666 2.89067 6.666 3.16667C6.666 4.04267 6.88267 5.14333 7.30867 6.43333C7.36667 6.61867 7.32 6.81067 7.178 6.952L5.426 8.76867C5.32733 8.87133 5.30467 9.02467 5.36933 9.15133C6.59533 11.562 8.086 13.0527 10.5147 14.2967C10.6247 14.3533 10.7593 14.344 10.86 14.2713L12.902 12.8127C13.0353 12.6793 13.2353 12.6333 13.412 12.6933C14.348 13.0027 15.7387 13.3333 16.8333 13.3333C17.1087 13.3333 17.3333 13.5573 17.3333 13.8333V16.8333Z"
      fill="#853358"
      stroke="#853358"
      stroke-width="0.5"
    />
  </svg>
);
