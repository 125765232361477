import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

export const Spinner = () => {
  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    </Box>
  );
};

const StyledBox = withStyles({
  root: {
    display: "flex",
    position: "fixed",
    zIndex: 1000,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
})(Box);

export const StyledIframeSpinner = () => (
  <StyledBox>
    <Spinner />
  </StyledBox>
);
