import { findLastIndex } from "lodash";

export const getLineGroupProps = (index, lines) => {
  const thisLine = lines[index];

  const linesOfSameKind = lines.filter(line => line.type === thisLine.type);
  const lastOfKindIndex = findLastIndex(
    lines,
    line => line.type === thisLine.type
  );
  const linesOfOtherKindCount = lines.length - linesOfSameKind.length;

  return {
    isUniqueOfType: linesOfSameKind.length === 1,
    isLastOfKind: index === lastOfKindIndex,
    indexOfKind:
      thisLine.type === "internet"
        ? index
        : index - (linesOfOtherKindCount)
  };
};
