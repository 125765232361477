import { getCoopAgreement } from "lib/api/coop_agreement";
import { useQuery } from "react-query";

export const useCoopAgreement = ({
  coopAgreementCode,
  automatically_run = false,
}) =>
  useQuery(
    `coopAgreement/${coopAgreementCode}`,
    async () => await getCoopAgreement(coopAgreementCode),
    {
      enabled: automatically_run,
      retry: false,
    }
  );
