import React from "react";
import { Box } from "@material-ui/core";

export const Container = ({ children, variant = "medium" }) => {
  // mui breakpoints javascript-media-queries
  // value |600px
  // key   |sm
  const width = {
    small: 600,
    narrow: 720,
    medium: 800,
    wide: 1200,
  }[variant];

  return (
    <Box paddingX={[3, 0]} width={["100%", width]} margin="0 auto">
      {children}
    </Box>
  );
};
