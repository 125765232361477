import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { Select } from "components/Select";
import { useStore } from "hooks/useStore";
import { Field, useForm, useFormState } from "react-final-form";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { Checkbox } from "components/Checkbox";
import { TextField } from "components/TextField";
import { sortBy, uniqBy, compact, uniqueId } from "lodash";
import { useTranslation } from "react-i18next";
import { Condition } from "../shared/Condition";
import { formatFullAddress } from "../shared/formatFullAddress";
import { required } from "lib/form/validators";
import { states } from 'lib/domain/somconnexio/selections';


const StatePicker = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Select.FormField
      validate={required}
      name={name}
      options={states}
      label={t("funnel.address_picker.state")}
    />
  );
};

export const AddressPicker = ({ name, label }) => {
  const formState = useFormState();
  const form = useForm();
  const availableAddresses = useStore(state => state.availableAddresses);
  const { t } = useTranslation();
  const [isInsertingNew, setIsInsertingNew] = useState(false);
  const addressId = formState.values[name]?._id;

  const options = uniqBy(
    compact(availableAddresses).filter(address =>
      Boolean(address.street)
    ),
    "_id"
  ).map(address => ({
    label: formatFullAddress(address),
    value: address._id
  }));

  useEffect(() => {
    if (isInsertingNew) {
      return
    }

    const willInsertNew = addressId === "add_new";

    setIsInsertingNew(willInsertNew);

    if (willInsertNew) {
      form.mutators.clearField(name);
    }
  }, [addressId, isInsertingNew, form.mutators, name]);

  const optimisticId = useMemo(() => uniqueId("address_"), []);

  if (availableAddresses.length <= 0 || isInsertingNew) {
    return (
      <Tiles columns={1} spacing={4}>
        <Field
          name={`${name}._id`}
          component="input"
          type="hidden"
          initialValue={optimisticId}
        />
        <TextField.FormField
          validate={required}
          name={`${name}.street`}
          label={`${label} ${t("funnel.address_picker.street_hint")}`}
        />
        <TextField.FormField
          validate={required}
          name={`${name}.city`}
          label={t("funnel.address_picker.city")}
        />
        <TextField.FormField
          validate={required}
          name={`${name}.zip_code`}
          label={t("funnel.address_picker.zip_code")}
        />

        <StatePicker name={`${name}.state`} />
      </Tiles>
    );
  }

  return (
    <>
      <Field
        name={name}
        component="input"
        type="hidden"
      />
      <Select.FormField
        name={`${name}._id`}
        validate={required}
        label={label}
        options={[
          ...options,
          { label: t("funnel.address_picker.add_new"), value: 'add_new' }
        ]}
      />
    </>
  );
};
