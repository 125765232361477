import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { LegacyTextField } from "components/LegacyTextField";
import { Stack } from "components/layouts/Stack";
import { Button } from "components/Button";
import { useRequestChangeContext } from "../context";
import { useTranslation } from "react-i18next";
import { isEmail } from "lib/form/validators";

export const ChangeEmail = () => {
  const { setNewValue, requestConfirm, selectedSubscriptions } = useRequestChangeContext();
  const [email, setEmail] = useState(null);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();

  const onClick = () => {
    if (!isEmail(email)) {
      setErrors([
        t("common.errors.invalid_email_address")
      ]);
      return;
    }

    setNewValue(email);
    requestConfirm();
  };

  return (
    <Stack spacing={2}>
      <strong>{t("request_change.change_email.title")}</strong>
      <LegacyTextField
        label={t("request_change.change_email.email")}
        placeholder={t("request_change.change_email.your_new_email")}
        setState={setEmail}
      />
      <Button fullWidth={false} onClick={onClick}>
        {t("common.confirm")}
      </Button>
      {errors.map((error, index) => (
        <Box key={index} width="100%">
          <Alert severity="error">{error}</Alert>
        </Box>
      ))}
    </Stack>
  );
};
