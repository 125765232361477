import { useStore } from "hooks/useStore";
import React, { Component } from "react";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import { Button } from "components/Button";
import { Tiles } from "components/layouts/Tiles";
import { withTranslation } from "react-i18next";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";
import { Bugsnag } from "@bugsnag/js";

const UnexpectedError = withTranslation()(({ t }) => {
  return (
    <FullScreenCenteredLayout>
      <Tiles columns={1}>
        <div>{t("common.errors.unexpected_error")}</div>
        <Button onClick={() => window.location.reload()}>{t("common.continue")}</Button>
      </Tiles>
    </FullScreenCenteredLayout>
  );
});

export class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV === "production") {
      Bugsnag.notify(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <UnexpectedError />
        </>
      );
    }

    return <>{this.props.children}</>;
  }
}
