import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

import { BrandLogo } from "components/icons/BrandLogo";

const Backdrop = ({ children }) => (
  <Box
    p={2}
    flex={1}
    bgcolor={process.env.REACT_APP_FORM_BUILD ? "transparent" : "primary.main"}
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    {children}
  </Box>
);

const Container = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    flex={1}
    width={{
      xs: "100%",
      sm: "470px",
    }}
  >
    {children}
  </Box>
);

export const FullScreenCenteredLayout = ({ children }) => {
  return (
    <Backdrop>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <BrandLogo variant="white" />
        </Box>
        <Box p={6} border="3x dashed green" component={Card}>
          {children}
        </Box>
      </Container>
    </Backdrop>
  );
};
