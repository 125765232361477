import {
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useSharedDataContext } from "../context";
import { useTranslation } from "react-i18next";
import { Tiles } from "components/layouts/Tiles";
import { Button } from "components/Button";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RouterIcon from "@material-ui/icons/Router";
import { theme } from "theme";
const useStyles = makeStyles((theme) => ({
  productIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(8),
  },
}));
export const Fibers = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();
  const {
    setShouldShowStepper,
    optionalContracts,
    selectedFiberContract,
    setSelectedFiberContract,
    setSelectedPack,
    selectedMobileContracts,
    packs,
  } = useSharedDataContext();
  const fiberContracts = optionalContracts.fiber;
  setShouldShowStepper(true);
  useEffect(() => {
    const getBandwithFromContract = ({ active_product_code, bandwidth }) => {
      if (active_product_code.indexOf("F_100") !== -1) {
        return 300;
      }
      return bandwidth;
    };
    const isFiberselected = selectedFiberContract.length === 1;
    setSelectedPack((current) =>
      isFiberselected
        ? packs.find(
            (pack) =>
              pack.mobiles_in_pack === selectedMobileContracts.length &&
              pack.has_landline_phone ===
                selectedFiberContract[0].has_landline_phone &&
              pack.fiber_bandwidth ===
                getBandwithFromContract(selectedFiberContract[0])
          )
        : current
    );
    setIsButtonDisabled(!isFiberselected || selectedMobileContracts.length < 2);
  }, [selectedFiberContract, selectedMobileContracts]);

  const hasFiber = (contractCode) =>
    selectedFiberContract
      .map((selectedFiber) => selectedFiber.code)
      .indexOf(contractCode);

  const isChecked = (contractCode) => hasFiber(contractCode) !== -1;

  const isItemDisabled = (contractCode) =>
    hasFiber(contractCode) === -1 && selectedFiberContract.length === 1;

  const onSelect = (contract) => {
    const currentIndex = hasFiber(contract.code);
    const copySelectedFiberContract = [...selectedFiberContract];
    if (currentIndex === -1) {
      copySelectedFiberContract.push(contract);
    } else {
      copySelectedFiberContract.splice(currentIndex, 1);
    }
    setSelectedFiberContract(copySelectedFiberContract);
  };
  return (
    <>
      <Box mt={5}>
        <Typography
          variant={useMediaQuery(theme.breakpoints.down("xs")) ? "body1" : "h6"}
        >
          {t("share_data.fibers.description")}
        </Typography>
      </Box>
      <Box component={Card} mt={5}>
        {/* TODO react-window FixedSizeList  */}
        <List>
          {fiberContracts.map((contract) => (
            <ListItem
              key={contract.code}
              button
              onClick={() => onSelect(contract)}
              disabled={isItemDisabled(contract.code)}
            >
              <ListItemAvatar>
                <RouterIcon className={styles.productIcon} />
              </ListItemAvatar>
              <ListItemText
                id={contract.code}
                primary={
                  <Typography variant="h6">{contract.description}</Typography>
                }
                secondary={
                  <Typography variant="body2">
                    {`${contract.address.street} - ${contract.address.city}`}
                    {}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Checkbox
                  color="primary"
                  checked={isChecked(contract.code)}
                  onChange={() => onSelect(contract)}
                  disabled={isItemDisabled(contract.code)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
      <Tiles columns="2" spacing={4}>
        <div />
        <Button
          onClick={() => {
            history.push("end");
          }}
          disabled={isButtonDisabled}
        >
          {t("common.continue")}
        </Button>
      </Tiles>
    </>
  );
};
