import { useTranslation } from "react-i18next";
import { FormSection } from "../shared/FormSection";
import { useChangeOwnerContext } from "../context";
import { useState } from "react";
import { Button } from "components/Button";
import { patchConfirmTicket } from "lib/api/confirmTicket";
import {
  ownerDataToMeta,
  ownerDataToSubscriptionRequest,
} from "../lib/ownerDataToMeta";
import { noop } from "lib/fn/noop";
import { postForm } from "lib/api/submitForms";
import { AcceptTerms } from "components/shared/AcceptTerms";
import { useQueryParams } from "hooks/useQueryParams";

export function NewOwnerConfirm() {
  const { t } = useTranslation();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const { is_pack = false } = useQueryParams();

  const index = 3;
  const {
    stepIndex,
    newOwnerDoc,
    newOwnerData,
    token,
    ticket,
    setIsSuccess,
    setIsError,
    setIsLoading,
  } = useChangeOwnerContext();

  async function updateTicket() {
    const { status: patch_status } = await patchConfirmTicket({
      ticketType: is_pack ? "change_owner_pack" : "change_owner",
      ticketId: ticket.id,
      token: token,
      fields: [
        {
          key: "next_owner_docs",
          value: newOwnerDoc,
        },
        ...ownerDataToMeta(newOwnerData),
      ],
    });
    if (patch_status === 204 && newOwnerData.role_new_owner !== "partner") {
      // Create subscription request
      await postForm(ownerDataToSubscriptionRequest(newOwnerData));
    }
    setIsSuccess(true);
  }

  const submit = async () => {
    try {
      setIsLoading(true);
      updateTicket();
    } catch (e) {
      setIsError(true);
    }
    setIsLoading(false);
  };
  return stepIndex >= index ? (
    <FormSection
      index={index}
      title={t("common.confirm")}
      stepIndex={stepIndex}
      getSummary={noop}
    >
      <AcceptTerms setIsAccepted={setHasAcceptedTerms} />
      <Button onClick={submit} type="submit" disabled={!hasAcceptedTerms}>
        {t("common.confirm")}
      </Button>
    </FormSection>
  ) : (
    <></>
  );
}
