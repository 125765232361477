import React from "react";
import { Box, styled, useTheme } from "@material-ui/core";
import "console-goodies";

const TileContainer = styled("div")(({ theme, spacing, columns }) => ({
  display: "grid",
  border: ["1px solid hotpink", "1px solid purple"],
  gap: theme.spacing(spacing),
  gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  },
}));

const calculateSpan = (span) => {
  if (span === "all") {
    return "1 / -1";
  }
  return `span ${span} / span ${span}`;
};
const SpanContainer = styled("div")(({ theme, span }) => ({
  display: "grid",
  [theme.breakpoints.up("md")]: {
    gridColumn: calculateSpan(span),
  },
}));

export const Tiles = ({ children, columns = 2, spacing = 2 }) => {
  const theme = useTheme();

  return (
    <TileContainer theme={theme} columns={columns} spacing={spacing}>
      {children}
    </TileContainer>
  );
};

export const TileSpan = ({ span, children }) => (
  <SpanContainer span={span}>{children}</SpanContainer>
);

export const TileSpacer = () => <Box display={["none", "block"]} />;
