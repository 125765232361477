import i18next from "i18next";
import ibanLib from "iban";
import { compact } from "lodash";
import { states } from "lib/domain/somconnexio/selections";
import { capitalize } from "./string";

export const formatMinutes = (minutes, unlimited_value = 99999) => {
  if (Number(minutes) === 0) {
    return i18next.t("common.minutes.no_minutes");
  } else if (Number(minutes) === unlimited_value) {
    return i18next.t("common.minutes.unlimited_minutes");
  } else {
    return i18next.t("common.minutes.n_minutes", {
      count: minutes,
    });
  }
};

export const formatDataPlanSize = (mb) => {
  if (mb < 1024) {
    return `${mb} MB`;
  }

  return `${mb / 1024} GB`;
};

export const formatBandwith = (mbps) => {
  if (mbps < 1024) {
    return `${mbps} Mb`;
  }

  return `${mbps / 1024} Gb`;
};

// export const formatIBAN = (iban) => {
//   return ibanLib.printFormat(iban, " ");
// };

export const formatIBAN = (input) => {
  const parts = ibanLib.printFormat(input, " ").split(" ");

  return [parts[0], "****", "****", "****", parts[4], parts[5]].join(" ");
};

export const getFullName = (
  values,
  fieldMap = { name: "name", surname: "surname", lastname: "lastname" }
) => {
  return compact([
    values[fieldMap.name],
    values[fieldMap.surname],
    values[fieldMap.lastname],
  ])
    .map(capitalize)
    .join(" ");
};

export const formatPrice = (price, { frequency } = {}) => {
  const symbol = frequency === "monthly" ? "€/mes" : "€";

  const value = new Intl.NumberFormat("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);

  return `${value}${symbol}`;
};

export const getStateByValue = (value) => {
  const state = states.find((item) => item.label === value);
  return state ? state.value : "";
};
