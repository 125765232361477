import { compact } from "lodash";

export const formatFullAddress = address => {
  if (!address) {
    return ""
  }

  const { street, city, zip_code } = address || {};

  return compact([street, city, zip_code]).join(", ");
};
