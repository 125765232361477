import React from "react";
import { theme } from "../theme";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  circle: {
    strokeLinecap: "round",
  },
});

const dark = theme.palette.background.dark;

export const CircularGraph = ({ maxValue, value, labelGenerator = null, marginTopLabel=0, marginLeftLabel=0, marginBottomLabel=0, marginRightLabel=0 }) => {
  const styles = useStyles();
  const normaliseValue = (value * 100) / maxValue;

  return (
    <Box position="relative" display="inline-block">
      <Box top={0} left={0} bottom={0} right={0} position="absolute">
        <CircularProgress
          style={{ color: dark }}
          size={150}
          variant="determinate"
          value={100}
          thickness={4.5}
        />
      </Box>
      <CircularProgress
        size={150}
        thickness={4.5}
        variant="determinate"
        value={normaliseValue}
        classes={{
          circle: styles.circle,
        }}
      />
      {labelGenerator && (
        <Box
          top={marginTopLabel}
          left={marginLeftLabel}
          bottom={marginBottomLabel}
          right={marginRightLabel}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {labelGenerator()}
        </Box>
      )}
    </Box>
  );
};
