import { getTariffs } from "lib/api/tariffs";
import { useQuery } from "react-query";
import { decoratePackTarrifs } from "./helper/tariffDecorator";

const useQueryTariffs = (queryKey, decorator, automatically_run) =>
  useQuery(
    queryKey,
    () =>
      getTariffs().then((pricelist) =>
        decorator ? decorator(pricelist) : pricelist
      ),
    {
      enabled: automatically_run,
    }
  );

export const useCatalog = ({ automatically_run = true } = {}) => {
  return useQueryTariffs(
    "catalog",
    ({ products, packs }) => {
      return {
        tariffs: products,
        packs: packs.map(decoratePackTarrifs),
      };
    },
    automatically_run
  );
};

export const useTariffs = ({ automatically_run = true } = {}) =>
  useQueryTariffs("products", ({ products }) => products, automatically_run);

export const usePackTariffs = ({ automatically_run = true } = {}) =>
  useQueryTariffs(
    "packs",
    ({ packs }) => packs.map(decoratePackTarrifs),
    automatically_run
  );
