import { Box } from "@material-ui/core";
import { Container } from "components/layouts/Container";
import { Tiles } from "components/layouts/Tiles";
import { useStore } from "hooks/useStore";
import { useEffect } from "react";
import { JsonParam, useQueryParam } from "use-query-params";
import { Alert } from "@material-ui/lab";
import { useApplicationContext } from "hooks/useApplicationContext";
import { getLineGroupProps } from "./shared/getLineGroupProps";
import { LineStepGroup } from "./shared/StepGroups/LineStepGroup";
import { PersonalDataStepGroup } from "./shared/StepGroups/PersonalDataStepGroup";
import groupBy from "lodash.groupby";
import { Trans, useTranslation } from "react-i18next";
import { AddButton } from "components/shared/AddButton";
import { useDerivedState } from "hooks/useDerivedState";

const AddLine = ({ type }) => {
  const { t } = useTranslation();
  const { canAddLineOfType } = useDerivedState();
  const addMobileLine = useStore((state) => state.addMobileLine);
  const addInternetLine = useStore((state) => state.addInternetLine);
  const isSharedLines = useStore((state) => state.isSharedLines);
  const addCallback = type === "internet" ? addInternetLine : addMobileLine;
  const canAddLine = canAddLineOfType(type);
  const [intent] = useQueryParam("intent", JsonParam);
  const didNotWantAnyLines =
    (intent.lines || (intent.isNewSignupFlow ? [] : [""])).length === 0;
  if (!canAddLine || didNotWantAnyLines || isSharedLines) {
    return null;
  }

  return (
    <AddButton
      onClick={() => addCallback({ updateSteps: true })}
      text={t(`funnel.tariffs.${type}.add_line`)}
    />
  );
};

export const Data = ({ tariffs }) => {
  const [intent] = useQueryParam("intent", JsonParam);
  const initializeSignupFlow = useStore((state) => state.initializeSignupFlow);
  const lines = useStore((state) => state.lines);
  const setCurrentIndex = useStore((state) => state.setCurrentIndex);
  const rejectedLines = useStore((state) => state.rejectedLines);
  const { internetLines = [], mobileLines = [] } = groupBy(
    lines,
    (line) => `${line.type}Lines`
  );
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const { currentStep } = useDerivedState();

  useEffect(() => {
    if (intent.isNewSignupFlow) {
      initializeSignupFlow({
        loggedIn,
        currentRole: currentUser?.role,
        optingForRole: intent.optingForRole,
        coopAgreementCode: intent.coopAgreementCode,
        lines: intent.lines,
        tariffs,
        initialIntent: intent,
        isSharedLines: intent.isSharedLines,
      });
    } else {
      setCurrentIndex(loggedIn ? 1 : 0);
    }
  }, [intent, tariffs]);

  useEffect(() => {
    const el = document.getElementById(`scroll/${currentStep}`);

    if (!el) {
      return;
    }

    const y = el.getBoundingClientRect().top + window.scrollY;

    window.scroll({
      top: y - 250,
      behavior: "smooth",
    });
  }, [currentStep]);

  return (
    <Container variant="narrow">
      <Tiles columns={1} spacing={5}>
        {rejectedLines.length > 0 && (
          <Alert severity="warning">
            <Trans i18nKey="funnel.signup.data.steps.rejected_lines_explanation">
              <ul>
                {rejectedLines.map((line, index) => (
                  <li key={index}>{line.name}</li>
                ))}
              </ul>
            </Trans>
          </Alert>
        )}
        {!loggedIn && (
          <PersonalDataStepGroup
            optingForRole={intent.optingForRole}
            goToPayment={lines.length === 0}
          />
        )}
        {internetLines.map((line, index) => {
          const isLast = index === lines.length - 1;

          return (
            <LineStepGroup
              key={`line-${line.__id}`}
              line={line}
              stepsBefore={loggedIn ? 1 : 2}
              index={index}
              isLast={isLast}
              tariffs={tariffs}
              {...getLineGroupProps(index, lines)}
            />
          );
        })}

        {mobileLines.map((line, index) => {
          const isLast = index === mobileLines.length - 1;

          return (
            <LineStepGroup
              key={`line-${line.__id}`}
              line={line}
              stepsBefore={loggedIn ? 1 : 2}
              index={index}
              isLast={isLast}
              tariffs={tariffs}
              {...getLineGroupProps(index, lines)}
              indexOfKind={index}
            />
          );
        })}
        <AddLine type="mobile" />
      </Tiles>
      {/* TODO some padding at the bottom to avoid scroll jumping when collapsing boxes */}
      <Box height="400px" />
    </Container>
  );
};
