export const CheckIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="17" fill="#3E3382" />
    <path d="M10 17.28L15.2941 24L25 12" stroke="white" />
  </svg>
);
