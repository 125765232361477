import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { triggerMatomoTracking } from "../lib/analytics/Matomo";
import { triggerPlausiblePageView } from "../lib/analytics/Plausible";

const locationURI = (location) =>
  location.pathname + (location.search || "");

let onLocationChange = (function () {
  let from;
  return (location) => {
    const url = locationURI(location);
    if (from === url) return;
    from = url;
    triggerMatomoTracking(url);
    triggerPlausiblePageView(location.search);
  };
})();

/* Navigation breadcrumbs analytics */
export function useAnalytics() {
  const location = useLocation();

  /* Initial call to location tracking */
  useEffect(() => onLocationChange(location), []);

  /* Bind tracking effect to location changes */
  useEffect(() => {
    onLocationChange(location);
  }, [location]);

  /* Return a callback to turn off tracking */
  return () => {
    onLocationChange = () => {};
  };
}
