import React from "react";
import { Text } from "components/Text";
import { styled, useTheme } from "@material-ui/core";

const dotSize = 8;

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const DotRoot = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center"
});

const LabelRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginTop: theme.spacing(1),
  "& > div": {
    width: dotSize,
    height: dotSize,
    display: 'flex',
    //textAlign: 'center',
    justifyContent: 'center',
    "&:first-child": {
      justifyContent: 'flex-start',
    },
    "&:last-child": {
      justifyContent: 'flex-end',
    },
  },
}));

const Dot = styled("div")({
  display: "flex",
  width: dotSize,
  height: dotSize,
  borderRadius: "100%",
  background: ({ theme, isCompleted }) =>
    isCompleted ? theme.palette.text.main : theme.palette.info.dark
});

const Line = styled("div")({
  display: "flex",
  flex: 1,
  height: 1,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: ({ theme, isCompleted }) =>
    isCompleted ? theme.palette.text.main : theme.palette.info.dark,
  marginLeft: 4,
  marginRight: 4
});

export const Stepper = ({ currentStep, steps }) => {
  const activeIndex = steps.findIndex(({ key }) => key === currentStep);
  const theme = useTheme();

  const isCompleted = index => index <= activeIndex;

  return (
    <Root>
      <DotRoot>
        {steps.map(({ key }, index) => (
          <>
            <Dot key={key} theme={theme} isCompleted={isCompleted(index)} />
            {index < steps.length - 1 && (
              <Line
                key={`line-${key}`}
                theme={theme}
                isCompleted={isCompleted(index + 1)}
              />
            )}
          </>
        ))}
      </DotRoot>
      <LabelRoot>
        {steps.map(({ label, key }, index) => (
          <div>
            <Text
              size="md"
              bold={index === activeIndex}
              color={
                isCompleted(index)
                  ? theme.palette.text.main
                  : theme.palette.info.dark
              }
            >
              {label}
            </Text>
          </div>
        ))}
      </LabelRoot>
    </Root>
  );
};
