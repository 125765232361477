import { Box } from "@material-ui/core";
import { AttachFiles } from "components/AttachFiles";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Heading } from "components/Heading";
import { LegacyTextField } from "components/LegacyTextField";
import { Text } from "components/Text";
import { Stack } from "components/layouts/Stack";
import { Tiles } from "components/layouts/Tiles";
import { Section } from "components/shared/Section";
import { isEmail } from "lib/form/validators";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRequestChangeContext } from "../context";
import { Alert } from "@material-ui/lab";
import {
  contractsInPack,
  decoratePackFiber,
} from "hooks/queries/helper/contractsPack";

export const ChangeOwner = () => {
  const { t } = useTranslation();
  const {
    requestConfirm,
    setDocumentation,
    documentation,
    setNewValue,
    setTopic,
    topic,
    scope,
    subscriptions,
    selectedSubscriptions,
  } = useRequestChangeContext();

  const errorsRef = useRef();
  const dialogRef = useRef();

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [userSubs, setuserSubs] = useState({});
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setuserSubs(decoratePackFiber(subscriptions));
  }, [subscriptions]);

  useEffect(() => {
    if (errors.length > 0) {
      dialogRef.current.scrollTo(
        0,
        errorsRef.current.getBoundingClientRect().top
      );
    }
  }, [errors]);

  /**
   * Check if any selectedSubscriptions is part of a pack
   * and the others subs of pack isn't is present
   */
  const isPackAffected = () => {
    const subscriptionsPack = contractsInPack(userSubs);
    const selectedSubscriptionsPack = contractsInPack(
      userSubs.filter(({ code }) => selectedSubscriptions.includes(code))
    );
    const comparator = [];

    Object.keys(selectedSubscriptionsPack).forEach((code) => {
      comparator.push(
        subscriptionsPack[code].length !==
          selectedSubscriptionsPack[code].length
      );
    });

    return comparator.some((differ) => !!differ);
  };
  const isPackBroken = () => (scope === "all" ? false : isPackAffected());

  const checkErrors = () => {
    const Errors = [];
    if (!isEmail(email)) {
      Errors.push(t("common.errors.invalid_email_address"));
    }
    if (!documentation.length) {
      Errors.push(t("request_change.change_owner.info_doc_vat"));
    }
    setErrors(Errors);
    return Errors.length > 0;
  };

  const submitForm = () => {
    setNewValue(email);
    setTopic(isPackBroken() ? `${topic}_pack` : topic);
    requestConfirm();
  };

  const onClickConfirm = () => {
    if (checkErrors()) {
      setHasAcceptedTerms(false);
      return;
    }
    submitForm();
  };
  return (
    <div ref={dialogRef}>
      <Box display="flex" flexDirection="column" mb={4} alignItems="start">
        <Heading align="center" variant="h5">
          {t(`request_change.change_owner.title`)}
        </Heading>
        <div ref={errorsRef}>
          {errors.map((error, index) => (
            <Box mt={1} key={index} width="100%">
              <Alert severity="error">{error}</Alert>
            </Box>
          ))}
        </div>
      </Box>
      <>
        <Section title={t("request_change.change_owner.current_data.title")}>
          <Tiles columns={1} spacing={4}>
            <AttachFiles
              getTitle={() => (
                <Text>
                  <Trans
                    i18nKey={
                      "request_change.change_owner.current_data.vat_actual_owner_lit"
                    }
                  />
                </Text>
              )}
              id="actual-owner-vat"
              info={t("request_change.change_owner.info_doc_vat")}
              maxFiles={2}
              maxSize={2048 * 1024} // 2048 KB
              setEncodedFiles={setDocumentation}
              accept=".jpg, .jpeg, .png, .pdf"
              multiple
            />
            <Checkbox
              name="accept_terms"
              onChange={({ target }) => setHasAcceptedTerms(target.checked)}
              label={t("request_change.change_owner.current_data.accept_terms")}
              checked={hasAcceptedTerms}
            />
          </Tiles>
        </Section>
        <Section title={t("request_change.change_owner.next_data.title")}>
          <Stack spacing={2}>
            <div>
              <p>
                {t("request_change.change_owner.next_data.email_disclaimer")}
              </p>
            </div>
            <LegacyTextField
              label={t("request_change.change_owner.next_data.email_new_owner")}
              value={email}
              setState={setEmail}
            />

            <Button onClick={onClickConfirm} disabled={!hasAcceptedTerms}>
              {t("common.confirm")}
            </Button>
          </Stack>
        </Section>
      </>
    </div>
  );
};
