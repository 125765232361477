import React from "react";
import Box from "@material-ui/core/Box";
import { Helmet } from "react-helmet";
import { theme } from "../../theme";
import "iframe-resizer/js/iframeResizer.contentWindow.js";

export const IframeLayout = ({
  children,
  sidebar = false,
  showBackgroundRect = true,
}) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          style: `background-color: ${theme.palette.background.light}`,
        }}
      />
      {showBackgroundRect && (
        <Box
          style={{
            height: 340,
            zIndex: "0",
            backgroundColor: "white",
          }}
          width="100%"
          position="fixed"
          top={0}
          left={0}
        />
      )}
      <Box position="relative" zIndex="1">
        {children}
      </Box>
    </>
  );
};
