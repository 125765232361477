import { getStateByValue } from "lib/helpers/format";
import { uniqWith, isEqual } from "lodash";

export const mapLegacyAddress = (address) => {
  return {
    zip_code: address.zip_code,
    street: address.street,
    city: address.city,
    state: getStateByValue(address.state),
    country: "ES",
  };
};

export const reusePartnerAddresses = (addresses) => {
  return uniqWith(addresses, isEqual).map(mapLegacyAddress);
};

/**
 * @deprecated since reuse addresses from partner in Odoo
 */
export const mapLegacyOpencellAddress = (address) => {
  return {
    zip_code: address.zip_code,
    street: address.address,
    city: address.city,
    state: getStateByValue(address.subdivision),
    country: "ES",
  };
};

/**
 * @deprecated since reuse addresses from partner in Odoo
 */
export const reuseOpencellAddresses = (addresses) => {
  return uniqWith(addresses, isEqual).map(mapLegacyOpencellAddress);
};
