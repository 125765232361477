import { Text } from 'components/Text';
import { AddButtonIcon } from 'components/icons/AddButtonIcon';
import { Box } from '@material-ui/core';

export const AddButton = ({ text, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <AddButtonIcon />
      <Box ml={2} display="flex">
        <Text
          size="sm"
          semibold
          uppercase
          selectable={false}
          letterSpacing="0.03125rem"
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
};
