import { getContractDetail, getContractsList } from "lib/api/contracts";
import { getFiberContracts } from "lib/api/fiberContracts";
import { useQuery } from "react-query";

export const useContracts = ({ userName = undefined, enabled = true } = {}) =>
  useQuery(`contracts/${userName}`, getContractsList, { enabled });

export const useContract = ({ userName, id } = {}) =>
  useQuery(
    `contract/${userName}/${id}`,
    async () => await getContractDetail({ id })
  );

export const useFiberContracts = ({
  userName = undefined,
  mobiles_sharing_data = false,
  automatically_run = true,
} = {}) =>
  useQuery(
    `fiberContracts/${userName}/${mobiles_sharing_data}`,
    async () =>
      await getFiberContracts({ mobiles_sharing_data: mobiles_sharing_data }),
    {
      enabled: automatically_run,
    }
  );
