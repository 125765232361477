/**
 * Iframe view for development purposes
 **/

import React from "react";
import IframeResizer from "iframe-resizer-react";

const path = "/tariffs/mobile?locale=ca";

export const DevIframe = () => {
  return (
    <div style={{ height: "100%", width: "100%", paddingTop: 40, background: 'hotpink' }}>
      <div style={{ height: 75, width: "100%" }}></div>
      <IframeResizer
        heightCalculationMethod="max"
        style={{ border: 'none', minHeight: "100%", width: 1, minWidth: "100%" }}
        src={path}
      />
    </div>
  );
};
