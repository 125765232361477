import { get, post } from "axios";

export const requestEmailChange = async ({ newEmail, currentPassword }) => {
  return await post(`/profile/request_email_change/`, {
    current_password: currentPassword,
    new_email: newEmail,
  });
};

export const confirmEmailChange = async ({ token }) => {
  return await post(`/profile/confirm_email_change/`, { token });
};

export const changePassword = async ({ currentPassword, newPassword }) => {
  return await post(`/profile/change_password/`, {
    current_password: currentPassword,
    new_password: newPassword,
  });
};

export const getInfo = async ({ promptSessionExpired = false } = {}) => {
  const { data } = await get("/info/", {
    headers: promptSessionExpired
      ? { "X-Prompt-Session-Expired": promptSessionExpired }
      : undefined,
  });

  return data;
};

export const getProfile = async () => {
  const { data } = await get("/profile/");

  return data;
};

export const confirmChange = async ({ token }) => {
  return await post(`/confirm_change/`, { token });
};
