import { List, AccordionDetails, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Text } from "components/Text";
import { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import { noop } from "lib/fn/noop";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  AccordionDetail: {
    display: "grid",
  },
  AccordionList: {
    paddingTop: 0,
    paddingBottom: 30,
  },
}));

export const AccordionDetailsLayout = ({
  dataQuery = noop,
  queryKey,
  listItem,
  querySettings = {},
}) => {
  const {
    data: response,
    isLoading,
    isRefetching,
  } = useQuery([queryKey], dataQuery, querySettings);
  const styles = useStyles();
  const [resources, setResources] = useState([]);
  useEffect(() => {
    if (!response) return;
    setResources(response);
  }, [response]);
  const { t } = useTranslation();
  const domRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: getTopOffset(domRef.current),
        behavior: "smooth",
      });
    }, 400);
  }, [response]);
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: getTopOffset(domRef.current),
        behavior: "smooth",
      });
    });
  }, [isLoading]);

  function getTopOffset(el) {
    const domRect = el.getBoundingClientRect();
    return domRect.top;
  }
  return (
    <AccordionDetails className={styles.AccordionDetail}>
      <div className="accordion-content" ref={domRef}>
        <List className={styles.AccordionList}>
          {isRefetching || isLoading ? (
            <Skeleton variant="rectangular" height={75} />
          ) : resources.length === 0 ? (
            <>
              <Alert severity="info">
                <Text size="md" color="black">
                  {t(`common.errors.validation.history_of_services_empty`)}
                </Text>
              </Alert>
            </>
          ) : (
            resources.map(listItem)
          )}
        </List>
      </div>
    </AccordionDetails>
  );
};
