import { SvgIcon } from "@material-ui/core";

export const MobileIcon = ({ color = "#853358", fontSize = 60 }) => (
  <SvgIcon viewBox="0 0 53 51" width="53" height="51" style={{ fontSize: fontSize }}>
    <path
      d="M12.811 6.20623C12.811 3.38864 12.811 0.571045 15.6607 0.571045C19.2229 0.571045 34.1838 0.571045 36.3211 0.571045C38.4583 0.571045 40.5956 1.27544 40.5956 6.20623C40.5956 11.8414 40.5956 43.5393 40.5956 45.6525C40.5956 47.7657 39.1708 49.8789 35.6086 49.8789C32.0465 49.8789 19.2229 49.8789 17.0856 49.8789C14.9483 49.8789 12.811 49.1745 12.811 46.3569C12.811 43.5393 12.811 9.02382 12.811 6.20623Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M26.3471 41.2006C27.9145 40.9893 29.4106 42.5389 29.1968 44.0182C29.0544 45.0748 28.1994 45.9905 27.0596 46.1314C25.7772 46.3427 24.4236 45.427 24.2099 44.0182C23.9961 42.6094 25.0648 41.3415 26.3471 41.2006Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M44.8702 5.27588C45.0839 5.4872 47.1499 7.31863 47.0075 10.2067C46.865 12.6721 45.1551 14.1513 44.8702 14.433"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M48.4323 3.16333C48.7173 3.37465 51.852 5.55828 51.9944 9.50291C52.1369 13.2362 49.5009 15.5607 49.1447 15.8425"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M8.53652 5.27588C8.3228 5.4872 6.25676 7.31863 6.39925 10.2067C6.54173 12.6721 8.25155 14.1513 8.53652 14.433"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M4.97438 3.16333C4.61817 3.37465 1.55474 5.55828 1.41226 9.50291C1.19853 13.8702 4.76065 16.406 4.97438 16.5469"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M30.6217 2.59908C30.4792 3.09216 30.693 3.79656 31.3341 4.00788C31.9753 4.2192 32.6165 3.79656 32.759 3.30348C32.9727 2.59908 32.0466 1.7538 31.3341 1.89468C30.9779 1.96512 30.693 2.31732 30.6217 2.59908Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M13.1673 5.98047H40.2394"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M12.811 38.3828H40.5956"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M19.9353 3.02148H27.0596"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </SvgIcon>
);
