import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { Button } from "components/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import { SidebarLayout } from "components/layouts/SidebarLayout";
import { getPaginateInvoicesList, checkInvoice } from "lib/api/invoices";
import { toDate, format } from "date-fns";
import { ca, es } from "date-fns/locale";
import { useApplicationContext } from "hooks/useApplicationContext";
import { PaginatedResourceList } from "components/PaginatedResourceList";
import { capitalize } from "lib/helpers/string";

const getDateLocale = (locale) => {
  return {
    es,
    ca,
  }[locale];
};

const useStyles = makeStyles((theme) => ({
  amount: {
    fontSize: theme.typography.h5.fontSize,
    display: "flex",
    justifyContent: "flex-end",
  },
  date: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
    display: "flex",
    fontSize: theme.typography.h5.fontSize,
  },
  number: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.grey["600"],
  },
}));

const Invoice = ({ invoice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const { currentUser } = useApplicationContext();
  const { t } = useTranslation();
  const classes = useStyles();

  const onClickDownload = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const isInvoiceAvailable = await checkInvoice({ id: invoice.id });

    if (isInvoiceAvailable) {
      window.location = invoice.download_url;
    } else {
      setHasErrors(true);
    }

    setIsLoading(false);
  };

  return (
    <Box component={Card} display="flex" flex={1} p={2} width="100%">
      <Box display="flex" width="100%" flexDirection="column" key={invoice.id}>
        <Box
          pl={1}
          display="flex"
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <div className={classes.date}>
            {capitalize(
              format(toDate(invoice.date), "MMMM yyyy", {
                locale: getDateLocale(currentUser.preferred_locale),
              })
            )}
          </div>
          <div className={classes.amount}>
            {invoice.total_amount.toLocaleString(currentUser.preferred_locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            €
          </div>
        </Box>
        <Box
          display="flex"
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <div className={classes.number}>{invoice.number}</div>
          <div>
            {!hasErrors && (
              <Button
                variant="text"
                onClick={onClickDownload}
                href={invoice.download_url}
                color="primary"
                disabled={isLoading}
                fullWidth={false}
              >
                {isLoading ? t("common.loading") : t("common.download")}
              </Button>
            )}
          </div>
        </Box>
        {hasErrors && (
          <Alert severity="error">{t("invoices.download_error")}</Alert>
        )}
      </Box>
    </Box>
  );
};

export const Invoices = () => {
  const { t } = useTranslation();

  return (
    <SidebarLayout>
      <PaginatedResourceList
        paginatorQuery={(offset) => getPaginateInvoicesList({ offset })}
        queryKey="invoices"
        renderItem={(invoice) => <Invoice invoice={invoice} />}
        renderHeader={() => (
          <Box mb={2}>
            <Typography variant="h5" component="h1">
              {t("invoices.description")}
            </Typography>
          </Box>
        )}
      />
    </SidebarLayout>
  );
};
