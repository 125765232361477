import { useStore } from "hooks/useStore";
import { useDerivedState } from "hooks/useDerivedState";
import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import { Subheading } from "components/Subheading";
import { Form } from "components/Form";
import { Text } from "components/Text";
import { useTranslation } from "react-i18next";
import { noop } from "lib/fn/noop";
import { FormStepGroupContext } from "./FormStepGroupContext";

export const FormStepContent = ({
  id,
  title,
  renderSummary = noop,
  children,
  initialValues,
  onSubmit,
  omitTitleWhenOpen = false,
  isDirty,
}) => {
  const { currentStep, isStepEditable } = useDerivedState();
  const setCurrentStep = useStore((state) => state.setCurrentStep);
  const isOpen = id === currentStep;
  const { t } = useTranslation();
  const { confirmMode } = useContext(FormStepGroupContext);

  if (isOpen) {
    return (
      <Form id={id} initialValues={initialValues} onSubmit={onSubmit}>
        <div data-qa-selector={id} data-form-step-open={isOpen}>
          <div id={`scroll/${id}`} />
          <Box px={[3, 8]} py={5}>
            {!omitTitleWhenOpen && (
              <Box mb={5}>
                <Subheading>{title}</Subheading>
              </Box>
            )}
            {children}
          </Box>
        </div>
      </Form>
    );
  }

  return (
    <div data-qa-selector={id} data-form-step-open={isOpen}>
      <div id={`scroll/${id}`} />
      <Box
        px={[3, 8]}
        py={3}
        display="flex"
        alignItems={confirmMode ? "flex-start" : "center"}
        justifyContent="space-between"
        style={{ opacity: isDirty ? 1.0 : 0.25 }}
      >
        <Box display="flex" flexDirection="column">
          {!confirmMode && <Subheading>{title}</Subheading>}
          {isDirty && renderSummary(initialValues)}
        </Box>

        {isDirty && isStepEditable(id, confirmMode) && (
          <Text
            underline
            color="primary.main"
            onClick={() => setCurrentStep(id)}
          >
            {t("common.edit")}
          </Text>
        )}
      </Box>
    </div>
  );
};
