import { get } from "axios";

export const getFiberContracts = async ({
  mobiles_sharing_data = false,
} = {}) => {
  try {
    const { data } = await get(
      `/contracts/fibers_to_link_to_mobile_offer/`,
      {
        params: { mobiles_sharing_data },
      }
    );

    return data;
  } catch (e) {
    console.error(e);
    return [];
    /* handle error */
  }
};
