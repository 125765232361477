export const CheckedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      stroke="#3E3382"
      stroke-opacity="0.6"
    />
    <rect x="5" y="5" width="14" height="14" fill="#3E3382" />
  </svg>
);
