import { initializeAxios } from "./initializeAxios";
import { initializeI18n } from "./initializeI18n";
import { initializeApiMocks } from "./initializeApiMocks";

export async function initialize() {
  // Initialize requests first, to be able to make requests to the API.
  await initializeAxios();

  const promises = [];

  promises.push(initializeI18n());

  if (process.env.NODE_ENV === "development") {
    promises.push(initializeApiMocks());
  }

  // Run the rest of initializers here
  await Promise.all(promises);
}
