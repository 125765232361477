import { Alert } from "@material-ui/lab";
import { Link } from "components/Link";
import { Stack } from "components/layouts/Stack";
import { useTranslation } from "react-i18next";

export const Success = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Alert severity="success">{t("confirm_change.complete")}</Alert>
      <Link to="/">{t("confirm_change.go_to_app")}</Link>
    </Stack>
  );
};
