import React, { useMemo } from "react";
import { Select } from "components/Select";
import { useQuery } from "react-query";
import { groupBy, sortBy } from "lodash";

const sortOptions = (data) => {
  const { rest, other } = groupBy(data, (item) =>
    item.label.match(/(otr[oa]s?|altr[ea]s?|others?)/i) ? "other" : "rest"
  );

  return [...sortBy(rest, "label"), ...sortBy(other, "label")];
};

export const ApiSelect = ({
  name,
  validate,
  label,
  query,
  mapItem,
  queryKey = undefined,
  sortData = true,
}) => {
  const {
    isLoading,
    error,
    data = [],
  } = useQuery(`api-select/${!!queryKey ? queryKey : name}`, query, {
    staleTime: 4 * 60 * 1000, // 4 min, https://www.codemzy.com/blog/react-query-cachetime-staletime
  });
  const sortedData = useMemo(
    () => sortOptions(data.map(mapItem)),
    [data, mapItem]
  );

  return (
    <Select.FormField
      name={name}
      label={label}
      validate={validate}
      options={
        isLoading || error ? [] : sortData ? sortedData : data.map(mapItem)
      }
    />
  );
};
