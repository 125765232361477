import { Box, makeStyles } from "@material-ui/core";
import { ThreeMobiles } from "components/icons/ThreeMobiles";
import { TwoMobiles } from "components/icons/TwoMobiles";
import { Trans, useTranslation } from "react-i18next";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import RouterIcon from "@material-ui/icons/Router";
import { Text } from "components/Text";

export const TelecommProduct = ({
  category,
  is_offer_tariff,
  name,
  quantity = 1,
}) => {
  const useStyles = makeStyles((theme) => ({
    productGrid: {
      display: "grid",
      gridTemplateColumns: "25% 75%",
    },
    productMobileInfoGrid: {
      display: "grid",
      gridTemplateRows: "40% 20% 20%",
    },
    productFiberInfoGrid: { display: "grid", gridTemplateRows: "50% 40%" },
    productIcon: {
      "color": theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        fontSize: theme.spacing(8),
      },
      "display": "grid",
      "gridColumn": 1,
    },
  }));

  const { t } = useTranslation();
  const styles = useStyles();
  const isMobileProduct = category === "mobile";

  const getProductIcon = () => {
    if (category !== "mobile") {
      return <RouterIcon title="fiber" />;
    }
    switch (quantity) {
      case 2:
        return <TwoMobiles />;
      case 3:
        return <ThreeMobiles />;
      default:
        return <PhoneAndroidIcon title="mobile" />;
    }
  };

  const getProductLit = () => (
    <Text capitalize={true}>
      {quantity > 1
        ? t(`funnel.tariffs.recommended.filters.labels.${category}`, {
            count: Number(quantity),
          })
        : t(`subscriptions.type.${category}`)}
    </Text>
  );
  const getProductDescription = () => (
    <>
      <Text size="xs">
        {isMobileProduct ? `${name.split("GB")[0]} GB` : name}
      </Text>
      {isMobileProduct && (
        <Text size="xs" color="primary.main">
          <Trans
            i18nKey="funnel.tariffs.recommended.associated_fiber"
            count={quantity}
          />
        </Text>
      )}
    </>
  );

  return (
    <Box className={styles.productGrid}>
      <Box className={styles.productIcon}>{getProductIcon()}</Box>
      <Box
        className={
          category === "mobile"
            ? styles.productMobileInfoGrid
            : styles.productFiberInfoGrid
        }
        mt={category === "fiber" ? 1 : 0}
      >
        {getProductLit()}
        {getProductDescription()}
      </Box>
    </Box>
  );
};
