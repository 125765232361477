import { makeStyles } from "@material-ui/core";
import { theme } from "theme";
export const ImgMiniature = ({ miniature }) => {
  const useStyles = makeStyles({
    img: {
      height: theme.spacing(10),
      width: theme.spacing(10),
      margin: `0  ${theme.spacing(2)} 0 0`,
    },
  });
  const classes = useStyles();
  return <img src={miniature} className={classes.img} alt="" />;
};
