import { Card } from "components/Card";
import { Subheading } from "components/Subheading";
import { Text } from "components/Text";
import {
  Hidden,
  useMediaQuery,
  useTheme,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { formatBandwith } from "lib/helpers/format";

export const PacksFilter = ({
  mobileSelection,
  setMobileSelection,
  bandWidthSelection,
  setBandWidthSelection,
  mobileValues,
  bandWidthValues,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function getOptionLabel(value, field) {
    switch (field) {
      case "mobile":
        if (value > 2) return value;
        return t("funnel.tariffs.recommended.filters.labels.mobile", {
          count: Number(value),
        });
      default:
        return formatBandwith(value);
    }
  }

  function getFilterOptions(values, field) {
    return values.map((value) => {
      return {
        value: value,
        label: getOptionLabel(value, field),
      };
    });
  }

  return (
    <Card shadow={true} variant="noGutter">
      <Box
        display="flex"
        flexDirection={
          useMediaQuery(theme.breakpoints.down("xs")) ? "column" : "row"
        }
      >
        <FilterRadioGroup
          index={0}
          fieldName="bandWidth"
          value={bandWidthSelection}
          setValue={setBandWidthSelection}
          options={getFilterOptions(bandWidthValues, "bandWidth")}
        />
        <FilterRadioGroup
          index={1}
          fieldName="mobile"
          value={mobileSelection}
          setValue={setMobileSelection}
          options={getFilterOptions(mobileValues, "mobile")}
        />
      </Box>
    </Card>
  );
};

function FilterRadioGroup({ fieldName, value, setValue, options, index = 0 }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      ml={1}
      flex={1}
      p={isMobile ? 4 : 5}
      pt={index > 0 && isMobile ? 0 : 5}
    >
      <FormControl component="fieldset">
        <Hidden mdUp>
          <Subheading>
            {t("funnel.tariffs.recommended.filters.label_" + fieldName)}
          </Subheading>
        </Hidden>
        <Hidden smDown>
          <Text bold>
            {t("funnel.tariffs.recommended.filters.label_" + fieldName)}
          </Text>
        </Hidden>
        <RadioGroup
          aria-label={fieldName}
          name={fieldName}
          value={String(value)}
          onChange={(ev) => setValue(Number(ev.target.value))}
          row={true}
        >
          {options.map((opt, i) => (
            <FormControlLabel
              key={i}
              value={opt.value}
              control={
                <Radio color="primary" size={isMobile ? "small" : "medium"} />
              }
              label={<Text>{opt.label}</Text>}
              sx={{ borderColor: "primary.main", borderRadius: 5 }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
