export const CaretIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class={className}
  >
    <path d="M3 7l9 9 9-9" stroke="currentColor" />
  </svg>
);
