import { addMonths, format, startOfMonth, startOfToday } from "date-fns";
const getPattern = (style) =>
  style === "otrs" ? "yyyy-MM-dd HH:mm:ss" : "dd/MM/yyyy";

export const getStartOfToday = (style = "view") =>
  format(startOfToday(new Date()), getPattern(style));

export const getStartOfNextMonth = (style = "view") =>
  format(
    startOfMonth(addMonths(startOfToday(new Date()), 1)),
    getPattern(style)
  );
