import { Form as FinalForm, useForm, useFormState } from "react-final-form";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";

export const Form = ({ children, id, initialValues, onSubmit }) => {
  return (
    <FinalForm
      // This flag below is used to remove conditional fields from the state
      destroyOnUnregister
      mutators={{
        clearField: (args, state, utils) => {
          utils.changeValue(state, args[0], () => undefined);
        }
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={id} onSubmit={handleSubmit}>
          {children}
        </form>
      )}
    />
  );
};

const Submit = props => {
  const { disabled, children, ...rest } = props;
  const { t } = useTranslation();
  const { submitting, validating } = useFormState()
  const isLoading = submitting || validating;

  return (
    <Button
      type="submit"
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading ? t("common.loading") : children}
    </Button>
  );
};

Form.Submit = Submit;
