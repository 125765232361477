import { MenuItem, withStyles } from "@material-ui/core";
import { Select as MaterialSelect } from "@material-ui/core";
import { CaretIcon } from "components/icons/CaretIcon";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  StyledFormControl,
  StyledInputLabel,
  important,
} from "./shared/InputCustomizations";
import { Field } from "react-final-form";
import React from "react";
import "./Select.css";
import cn from "classnames";

/**
 * Changes text color inside select drop down
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    color: important(theme.palette.text.main),
    maxHeight: "400px",
  },
  iconStyles: {
    color: theme.palette.text.main,
    display: "inline-block",
    width: "1em",
    height: "1em",
    fontSize: "1.5rem",
    right: theme.spacing(4),
  },
}));

const StyledSelect = withStyles((theme) => ({
  root: {
    color: theme.palette.text.main,
    width: "100%",
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}))(MaterialSelect);

export const Select = ({
  options,
  label,
  helperText,
  error,
  i18nPrefix,
  ...props
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const getOptions = () => {
    if (!i18nPrefix) {
      return options;
    }

    return options.map((value) => ({
      label: t([i18nPrefix, props.name, value].join(".")),
      value,
    }));
  };

  return (
    <>
      <StyledFormControl variant="outlined" error={error}>
        <StyledInputLabel>{label}</StyledInputLabel>
        <StyledSelect
          {...props}
          label={label}
          MenuProps={{
            classes: {
              paper: styles.paper,
            },
          }}
          IconComponent={({ className }) => (
            <CaretIcon className={cn(styles.iconStyles, className)} />
          )}
        >
          {getOptions().map(({ value, label }, index) => (
            <MenuItem key={JSON.stringify(value)} value={value}>
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {label}
              </div>
            </MenuItem>
          ))}
        </StyledSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </StyledFormControl>
    </>
  );
};

const FormField = ({ name, validate, ...props }) => {
  const { t } = useTranslation();

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <Select
          {...props}
          error={meta.error}
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          helperText={
            meta.submitFailed &&
            meta.error &&
            t(`common.errors.validation.${meta.error}`)
          }
        />
      )}
    </Field>
  );
};

Select.FormField = FormField;
