export const decoratePackTarrifs = (pack) => {
  const prettyPack = {
    ...pack,
    products: pack.products.map((product) => {
      // TODO peor es morirse, the field `is_offer_tariff` should come from products pack catalog, we are faking it here meanwhile
      return {
        ...product,
        type: product.category === "mobile" ? "mobile" : "internet",
        //TODO ** review is_offer_tariff concept.
        //  is_offer_tariff conditions how is calculated allowedLines in ../../hooks/useStore.js.
        is_offer_tariff: product.code === "SE_SC_REC_MOBILE_PACK_UNL_20480",
        belongs: pack.code,
        // Propagate the available_for to parse products list to AllowebLines
        available_for: [...pack.available_for],
      };
    }),
  };

  return prettyPack;
};

export const decorateOfferTariffs = (tariff) => {
  return tariff.offer
    ? {
        ...tariff,
        is_offer_tariff: true,
        original_price: tariff.price,
        price: tariff.offer.price,
        code: tariff.offer.code,
        name: tariff.offer.name,
      }
    : {
        ...tariff,
        is_offer_tariff: false,
      };
};
