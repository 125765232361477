import { Grid, ListItem, Typography } from "@material-ui/core";
import { format, toDate } from "date-fns";
import { getSubscriptionHistory } from "lib/api/subscriptionHistory";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AccordionLayout } from "components/layouts/AccordionLayout";

const HistoryItem = ({ item }) => {
  return (
    <Fragment>
      <ListItem divider={true}>
        <Grid>
          <Grid item>
            <Typography display="inline" variant="overline">
              {format(toDate(item.init_date), "dd/MM/yyyy")}
            </Typography>
            {!item.is_active && (
              <Typography display="inline" variant="overline">
                {` - ${format(toDate(item.termination_date), "dd/MM/yyyy")}`}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography>{item.description}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    </Fragment>
  );
};

export const SubscriptionHistory = ({ id }) => {
  const { t } = useTranslation();
  return (
    <AccordionLayout
      accordionTitle={t(`subscriptions.detail.history`)}
      dataQuery={() => getSubscriptionHistory({ id })}
      queryKey={`subscription-history/${id}`}
      listItem={(item) => <HistoryItem item={item} />}
    />
  );
};
