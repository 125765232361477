import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";

if (process.env.REACT_APP_FORM_BUILD === "1") {
  const { default: App } = require("./Forms");
  const startApp = ({ el, route }) => {
    ReactDOM.render(
      <HashRouter>
        <App route={route} />
      </HashRouter>,
      el
    );
  };
  if (process.env.NODE_ENV === "development") {
    startApp({
      el: document.getElementById("root"),
      route: window.location.hash.replace(/^#?\//, ""),
    });
  } else {
    if (Object.prototype.hasOwnProperty.call(window, "wpReact")) {
      window.wpReact.startApp = startApp;
    } else {
      console.error("wpReact not found");
    }
  }
} else {
  const { default: App } = require("./App");
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("root")
  );
}
