import React from "react";
import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/core";

const Line = styled("div")(({ theme, variant }) => {
  const color = {
    default: theme.palette.background.dark,
    corporate: theme.palette.text.main,
    //white: "hotpink"
    white: "rgba(255, 255, 255, 0.2)",
  };

  return { height: 0, width: "100%", borderTop: `1px solid ${color[variant]}` };
});

export const Separator = ({ spacing = 0, variant = "default" }) => (
  <Box py={spacing} width="100%">
    <Line variant={variant} />
  </Box>
);
