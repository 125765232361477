import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";
import { Spinner } from "components/Spinner";
import { confirmChange } from "lib/api/profile";
import { Error } from "./shared/Error";
import { Success } from "./shared/Success";

export function ConfirmChange() {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    async function confirmToken() {
      try {
        setIsLoading(true);
        await confirmChange({ token });
        setIsLoading(false);
        setIsSuccess(true);
      } catch (e) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }

    confirmToken();
  }, [token]);

  return (
    <FullScreenCenteredLayout>
      {isLoading && <Spinner />}
      {isSuccess && <Success />}
      {isError && <Error />}
    </FullScreenCenteredLayout>
  );
}
