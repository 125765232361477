import React from "react";
import { Stack } from "components/layouts/Stack";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useRequestChangeContext } from "../context";
import { Trans, useTranslation } from "react-i18next";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Typography from "@material-ui/core/Typography";

export const SelectTopic = () => {
  const { setTopic } = useRequestChangeContext();
  const history = useHistory();
  const { t } = useTranslation();

  const onTopicSelected = (topic) => {
    setTopic(topic);
    history.push(`request-change/${topic}`);
  };

  return (
    <Stack>
      <strong>{t("request_change.select_topic.title")}</strong>
      <Box mt={4} ml={2}>
        <Stack spacing={2}>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <MailOutlineIcon fontSize="large" color="action" />
            </Box>
            <Stack>
              <Typography>{t("request_change.select_topic.email")}</Typography>
              <Typography color="textSecondary">
                {t("request_change.select_topic.email_description")}
              </Typography>
              <Button
                variant="text"
                color="primary"
                fullWidth={false}
                onClick={() => onTopicSelected("email")}
              >
                {t("request_change.select_topic.request_change")}
              </Button>
            </Stack>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <CreditCardIcon fontSize="large" color="action" />
            </Box>
            <Stack>
              <Typography>
                {t("request_change.select_topic.bank_account")}
              </Typography>
              <Typography color="textSecondary">
                {t("request_change.select_topic.bank_account_description")}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey="request_change.select_topic.bank_account_disclaimer">
                  <Link
                    target="_blank"
                    to="mailto:facturacion@somosconexion.coop"
                  />
                </Trans>
              </Typography>
              <Button
                variant="text"
                color="primary"
                fullWidth={false}
                onClick={() => onTopicSelected("iban")}
              >
                {t("request_change.select_topic.request_change")}
              </Button>
            </Stack>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr={2}>
              <PeopleAltIcon fontSize="large" color="action" />
            </Box>
            <Stack>
              <Typography>
                {t("request_change.select_topic.change_owner")}
              </Typography>
              <Typography color="textSecondary">
                {t("request_change.select_topic.change_owner_description")}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey="request_change.select_topic.change_owner_disclaimer">
                  <Link
                    target="_blank"
                    to={"mailto:" + t("common.assistance_email")}
                  />
                </Trans>
              </Typography>
              <Button
                variant="text"
                color="primary"
                fullWidth={false}
                onClick={() => onTopicSelected("owner")}
              >
                {t("request_change.select_topic.request_change")}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
