export const CircleCheckIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9.5" stroke="#F6DE59" />
    <path d="M5.88281 9.57646L8.997 13.5294L14.7063 6.47058" stroke="#F6DE59" />
  </svg>
);
