import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { Alert } from "components/Alert";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/IconButton";
import { EditIcon } from "components/icons/EditIcon";
import { SidebarLayout } from "components/layouts/SidebarLayout";
import { PaginatedResourceList } from "components/PaginatedResourceList";
import { Tiles } from "components/layouts/Tiles";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { Link } from "components/Link";
import Typography from "@material-ui/core/Typography";
import { Hidden } from "@material-ui/core";
import { SubscriptionDetail } from "./SubscriptionDetail";
import { SubscriptionIcon } from "./SubscriptionDetail/shared/SubscriptionIcon";
import { useApplicationContext } from "hooks/useApplicationContext";
import { makeStyles } from "@material-ui/core/styles";
import { iconFor } from "lib/domain/somconnexio/iconFor";
import { getPaginatedContractsList } from "lib/api/contracts";
import { InfoIcon } from "components/icons/InfoIcon";

const useStyles = makeStyles((theme) => ({
  modifyService: {
    "color": theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    "textDecoration": "underline",
    "paddingLeft": 2,
  },
  actionServiceIcon: {
    padding: "0",
  },
}));

const SubscriptionItem = ({ sub }) => {
  const { t } = useTranslation();
  const { modifyService, actionServiceIcon } = useStyles();
  const secondaryText =
    sub.subscription_technology === "fiber"
      ? `${sub.phone_number} / ${sub.address.street}`
      : `${sub.phone_number}`;

  const iconSize = [40, 60];

  return (
    <Link showUnderline={false} to={`/home/subscriptions/${sub.id}`}>
      <Box component={Card} width={1} p={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Box display="flex">
            <Box
              display="flex"
              minWidth={iconSize}
              minHeight={iconSize}
              width={iconSize}
              height={iconSize}
              mr={4}
              ml={2}
              justifyContent="center"
              alignItems="center"
            >
              <SubscriptionIcon variant="full" type={sub.subscription_type} />
            </Box>
            <Box>
              <Typography variant="h6" component="div">
                {t(`subscriptions.type.${sub.subscription_type}`)}
              </Typography>
              <Typography display={"inline"}>{sub.description}</Typography>
              <Typography color="textSecondary">{secondaryText}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton className={actionServiceIcon}>
              <EditIcon />
            </IconButton>
            <Hidden smDown>
              <Typography variant="body1" className={modifyService}>
                {t("subscriptions.modify_service")}
              </Typography>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

const ResourceListHeader = () => {
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();
  const showAlert = useLocation().state?.fromBannedNewServiceRoute;

  return (
    <Tiles columns={1} spacing={0}>
      <Typography variant="h5" component="h1">
        {t("common.welcome", { name: currentUser.first_name })}
      </Typography>
      {showAlert && (
        <Alert
          severity={"warning"}
          alertTitle={null}
          alertContent={"banned_actions.message_new_service_banned"}
          alertSubtitle={null}
          alertSubContent={null}
          closable
          contactUs={true}
        />
      )}
    </Tiles>
  );
};

const SubscriptionList = () => {
  return (
    <PaginatedResourceList
      renderHeader={() => <ResourceListHeader />}
      queryKey="subscriptions"
      paginatorQuery={(offset, search, filter) =>
        getPaginatedContractsList({ offset, search, filter })
      }
      renderItem={(sub) => (
        <Box key={sub.id} width="100%">
          <SubscriptionItem sub={sub} />
        </Box>
      )}
      radioFiltersProps={{
        enabled: true,
        options: ["all", "mobile", "broadband"],
        defaultOption: "all",
        title: "subscriptions.list.filter.filter_label",
        litPrefix: "subscriptions.list.filter",
      }}
      textFilterProps={{
        enabled: true,
        title: "subscriptions.list.filter.search_label",
        mask: "000000000",
      }}
    />
  );
};

export const Home = () => {
  let { path } = useRouteMatch();

  return (
    <SidebarLayout>
      <Switch>
        <Route exact path={path}>
          <SubscriptionList />
        </Route>
        <Route path={`${path}/subscriptions/:id`}>
          <SubscriptionDetail />
        </Route>
      </Switch>
    </SidebarLayout>
  );
};
