import React from "react";
import { Box, withStyles } from "@material-ui/core";
import { pick } from "lodash";
import { Text } from "components/Text";

const StyledBox = withStyles(({ palette }) => ({
  root: {
    cursor: ({ onClick, cursor }) =>
      Boolean(cursor) ? cursor : Boolean(onClick) ? "pointer" : "auto",
    borderRadius: 10,
    boxShadow: ({ shadow }) =>
      shadow ? "0px 20px 35px rgba(0, 0, 0, 0.15)" : "none",
    background: ({ bgcolor, isHighlighted, isInformative }) =>
      bgcolor ||
      (isHighlighted
        ? palette.secondary.main
        : isInformative
        ? palette.background.main
        : palette.background.light),
  },
}))(Box);

const paddingByVariant = {
  noGutter: {
    padding: 0,
  },
  default: {
    py: 8,
    px: [3, 8],
  },
  cta: {
    pt: 7,
    px: 5,
    pb: 5,
  },
  formStep: {
    py: 0,
    px: 8,
  },
  balanced: {
    py: 5,
    px: 5,
  },
};

const horizontal = (padding) => pick(padding, ["px"]);

export const Card = ({
  variant = "default",
  header = null,
  shadow = true,
  headerBgcolor = "background.dark",
  children,
  topRightAction = null,
  ...props
}) => (
  <StyledBox shadow={shadow} {...props}>
    {header && (
      <Box
        borderRadius="10px 10px 0 0"
        bgcolor={headerBgcolor}
        height="34px"
        color="text.main"
        py={2}
        {...horizontal(paddingByVariant[variant])}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {header}
      </Box>
    )}
    <Box
      position="relative"
      width="100%"
      height="100%"
      {...paddingByVariant[variant]}
    >
      <Box position="absolute" top={0} right={0} pr={3} pt={3}>
        {topRightAction}
      </Box>
      {children}
    </Box>
  </StyledBox>
);

export const SignUpCard = ({
  confirmMode,
  title,
  topRightAction = null,
  index,
  children,
  ...props
}) => {
  const header = (
    <Box
      px={[3, 8]}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Text size="xs">
        {!confirmMode && <>{index}. &nbsp;&nbsp;</>}
        <Text semibold size="xs" uppercase letterSpacing="0.1rem">
          {title}
        </Text>
      </Text>
      {topRightAction}
    </Box>
  );
  return (
    <Card
      shadow={false}
      variant="noGutter"
      bgcolor="white"
      headerBgcolor={confirmMode ? "white" : undefined}
      header={header}
      {...props}
    >
      {children}
    </Card>
  );
};
