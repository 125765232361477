import { SvgIcon } from "@material-ui/core";

export const RouterIcon = ({ color = "#853358", fontSize = 60 }) => (
  <SvgIcon viewBox="0 0 59 58" width="59" height="58" style={{ fontSize: fontSize }}>
    <path
      d="M6.20928 37.8594C6.20928 37.8594 50.5122 37.8594 53.0081 37.8594C55.5041 37.8594 58 37.8594 58 41.654C58 45.4487 58 49.8758 58 51.1407C58 52.4056 56.752 54.303 53.6321 54.303C50.5122 54.303 4.96132 54.303 4.33733 54.303C3.08936 54.303 1.21741 53.0381 1.21741 51.1407C1.21741 49.2434 1.21741 41.0216 1.21741 41.0216C1.21741 41.0216 1.21741 37.8594 6.20928 37.8594Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M9.32926 41.6544C11.5756 41.5279 13.6972 43.615 13.6972 46.0815C13.6972 48.548 11.638 50.6351 9.32926 50.5086C7.51971 50.4454 6.02214 48.9908 5.58535 47.3464C4.96137 44.8799 6.77093 41.7809 9.32926 41.6544Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M41.7765 10.032C42.962 10.2217 44.21 9.27303 44.2724 8.13463C44.3348 7.31245 43.7108 6.55352 43.0244 6.2373C41.9013 5.73134 40.2165 6.30054 39.9045 7.50219C39.6549 8.57734 40.5285 9.84223 41.7765 10.032Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M45.5203 3.0752C45.7699 3.26493 48.0162 5.09902 48.0162 8.13476C48.0162 10.6645 46.4562 12.2457 46.1442 12.5619"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M49.2643 1.17798C49.8259 1.81042 51.6354 4.02398 51.7602 7.50243C51.885 11.3604 49.7635 13.8901 49.2643 14.4593"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M38.0325 3.0752C37.5957 3.51791 36.2853 5.09902 36.1605 7.50231C36.0357 10.8543 38.3445 12.9413 38.6565 13.1943"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M34.9126 1.17798C34.2886 1.93691 32.6038 4.15047 32.4166 7.50243C32.167 11.6133 34.4134 14.4593 34.9126 15.0918"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M9.32922 46.0817V41.6545"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M39.9045 18.8863C39.9045 18.2539 40.2165 15.7241 42.0884 15.7241C43.9604 15.7241 44.2724 17.6215 44.2724 18.2539C44.2724 18.8863 45.5204 34.065 45.5204 34.065C45.5204 34.065 44.2724 35.3299 42.4004 35.3299C40.5285 35.3299 39.2805 33.4326 39.2805 33.4326C39.2805 33.4326 39.9045 20.1512 39.9045 18.8863Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M2.15332 39.061L9.95313 32.1674C9.95313 32.1674 11.2011 31.5349 12.4491 31.5349C13.697 31.5349 39.2804 31.5349 39.2804 31.5349"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M45.5203 31.5349H47.3922C47.3922 31.5349 49.8882 31.5349 51.1361 32.7998C52.3841 34.0647 56.752 38.4918 56.752 38.4918"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M28.0488 48.4847C29.5464 48.6112 31.1687 47.4728 31.1687 45.9549C31.1687 44.6268 29.9207 43.5516 28.6728 43.4251C27.1752 43.2986 25.5529 44.437 25.5529 45.9549C25.5529 47.2198 26.8008 48.2949 28.0488 48.4847Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M37.2213 48.6113C38.3445 48.6745 40.2164 48.0421 40.2164 46.5874C40.2164 45.2593 39.2181 43.5517 37.8453 43.2355C36.1605 42.856 34.2886 44.3106 34.2886 45.8918C34.2886 47.3464 35.8485 48.548 37.2213 48.6113Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M46.7682 48.8011C48.2034 48.8011 49.7634 47.7892 49.8882 46.2714C50.0754 44.5638 48.3906 43.1091 46.7682 43.1091C45.3331 43.1091 43.7731 44.121 43.6483 45.6389C43.4611 47.2833 45.1459 48.7379 46.7682 48.8011Z"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M8.0813 54.3035C8.39329 54.6829 9.76606 56.517 11.95 56.517C12.3244 56.517 14.2587 56.4538 15.8187 54.3035"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M43.0244 54.3035C43.3364 54.6829 44.7092 56.517 46.8931 56.517C47.2675 56.517 49.2019 56.4538 50.7618 54.3035"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </SvgIcon>
);
