import { Separator } from "components/Separator";
import { Text } from "components/Text";
import { TextField } from "components/TextField";
import { Tiles } from "components/layouts/Tiles";
import {
  composeValidators,
  matchVatFormat,
  required,
} from "lib/form/validators";
import { useTranslation } from "react-i18next";

export const NewSponsorship = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text semibold size="xs" uppercase letterSpacing="0.1rem">
        {t("request_change.change_owner.next_data.sponsorship")}
      </Text>
      <Separator />
      <Tiles columns={2} spacing={4}>
        <TextField.FormField
          name="sponsor_vat"
          validate={composeValidators(required, matchVatFormat)}
          label={t("funnel.signup.data.steps.personal_data.sponsor_vat_number")}
        />
        <TextField.FormField
          name="sponsor_code"
          validate={required}
          label={t("funnel.signup.data.steps.personal_data.sponsor_code")}
          info={t("funnel.signup.data.steps.personal_data.sponsor_tooltip", {
            literalSponsorScreen: t("sponsor.title"),
          })}
        />
      </Tiles>
    </>
  );
};
