import { get } from "axios";

export const getTariffs = async ({ code = "21IVA" } = {}) => {
  const { data } = await get(`/product-catalog/`, {
    params: { code },
  });

  try {
    return data[0];
  } catch (e) {
    console.error(e);
    /* handle error */
  }
};
