import { Box } from "@material-ui/core";
import { Column } from "components/layouts/Column";
import { Columns } from "components/layouts/Columns";
import { Stack } from "components/layouts/Stack";
import { displayVatNumber } from "lib/domain/somconnexio/auth";
import { formatIBAN } from "lib/helpers/format";
import { Fragment, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAsset } from "hooks/queries/useAssets";
import { noop } from "lib/fn/noop";
import { SubscriptionHistory } from "../shared/SubscriptionHistory";
import { Field, SecretField } from "../shared/Fields";
import { SecretFieldModal } from "../modal/SecretFieldModal";

// TODO rebase with odoo contracts
const formatAddress = (data) => {
  const { street, zip_code, city } = data;

  return (
    <Fragment>
      <div>{street}</div>
      <div>
        {zip_code} {city}
      </div>
    </Fragment>
  );
};

export const SubscriptionDetailTab = ({ ...subscription }) => {
  const {
    complete_name,
    vat_number,
    iban,
    email,
    address,
    subscription_type,
    asset_ref,
  } = {
    ...subscription,
  };
  const { t } = useTranslation();
  const { id } = useParams();
  const secretResolver = useRef(noop);
  const [isSecretModalOpen, setIsSecretModalOpen] = useState(false);

  const {
    data: asset,
    isLoading: isAssetLoading,
    isError: isAssetError,
  } = useAsset({
    id,
    ref: asset_ref,
    retry: process.env.NODE_ENV === "production" ? 3 : false,
    enabled: subscription_type === "mobile",
  });

  return (
    <Fragment>
      <SecretFieldModal
        isOpen={isSecretModalOpen}
        onClose={() => {
          secretResolver.current = noop;
          setIsSecretModalOpen(false);
        }}
        resolveValue={secretResolver.current}
        message={<Trans>subscriptions.detail.secret_modal.info</Trans>}
        timeout={20}
      />
      <Box mt={3} mb={6}>
        <Columns spacing={4}>
          <Column>
            <Stack>
              <Field
                label={t("subscriptions.detail.name")}
                value={complete_name}
              />
              <Field
                label={t("subscriptions.detail.vat_number")}
                value={displayVatNumber(vat_number)}
              />
              <Field
                label={t("subscriptions.detail.iban")}
                value={formatIBAN(iban)}
              />
            </Stack>
          </Column>
          <Column>
            <Stack>
              <Field label={t("subscriptions.detail.email")} value={email} />
              {subscription_type === "broadband" && (
                <Field
                  label={t("subscriptions.detail.address")}
                  value={formatAddress(address)}
                />
              )}
              {subscription_type === "mobile" && (
                <SecretField
                  label={t("subscriptions.detail.show_puk")}
                  tooltip={t("subscriptions.detail.puk_tooltip")}
                  disabled={isAssetError || isAssetLoading}
                  discover={() => {
                    secretResolver.current = () => asset.puk;
                    setIsSecretModalOpen(true);
                  }}
                />
              )}
              {false && subscription_type === "mobile" && (
                <SecretField
                  label={t("subscriptions.detail.show_pin")}
                  tooltip={t("subscriptions.detail.pin_tooltip")}
                  disabled={isAssetError || isAssetLoading}
                  discover={() => {
                    secretResolver.current = () => asset.pin;
                    setIsSecretModalOpen(true);
                  }}
                />
              )}
            </Stack>
          </Column>
        </Columns>
      </Box>
      <SubscriptionHistory id={id} />
    </Fragment>
  );
};
