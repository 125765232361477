import React from "react";
import MaterialButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import { theme } from "../theme";

const styles = {
  root: {
    "height": 55,
    "marginBottom": 0,
    "boxShadow": "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
    opacity: 0.5,
  },
  label: {
    fontSize: 16,
    letterSpacing: "1px",
    fontWeight: "bold",
  },
};

const StyledButton = withStyles(styles)(MaterialButton);

/**
 * Preconfigured Material UI Button for consistency sake
 */

export const Button = ({
  type = "button",
  onClick,
  children,
  fullWidth = true,
  variant = "contained",
  color = "primary",
  disabled = false,
  form,
  component = "button",
}) => (
  <StyledButton
    fullWidth={fullWidth}
    variant={variant}
    color={color}
    type={type}
    onClick={onClick}
    disabled={disabled}
    form={form}
    component={component}
  >
    {children}
  </StyledButton>
);
