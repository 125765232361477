import { Box, Card } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { Stack } from "components/layouts/Stack";
import { Link } from "components/Link";
import { Spinner } from "components/Spinner";
import { LegacyTextField } from "components/LegacyTextField";
import { useApplicationContext } from "hooks/useApplicationContext";
import { requestEmailChange } from "lib/api/profile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmail } from "lib/form/validators";

export const ChangeEmail = () => {
  const { currentUser } = useApplicationContext();
  const [isLoading, setIsLoading] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isSuccess, setIsSuccess] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const { t } = useTranslation();

  const onSubmit = async (event) => {
    event.preventDefault();

    setErrors([]);
    setIsLoading(true);

    if (currentUser.email === newEmail) {
      setErrors([t("profile.change_email.already_in_use")]);
      setIsLoading(false);
      return;
    }

    if (!isEmail(newEmail)) {
      setErrors([t("common.errors.invalid_email_address")]);
      setIsLoading(false);
      return;
    }

    try {
      await requestEmailChange({ newEmail, currentPassword });

      setErrors([]);
      setIsSuccess(true);
    } catch (e) {
      const responseErrors = e.response.data.errors || [
        t("common.errors.request_failed"),
      ];
      setErrors(responseErrors);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <Box component={Card} p={4}>
        <Box width="100%">
          <Alert severity="success">{t("profile.change_email.complete")}</Alert>
        </Box>
      </Box>
    );
  }

  return (
    <Box component={Card} p={4}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <Heading>{t("profile.change_email.title")}</Heading>
            <div>
              <p>{t("profile.change_email.disclaimer")}</p>
            </div>
            <LegacyTextField
              label={t("profile.change_email.current_email")}
              value={newEmail}
              setState={setNewEmail}
            />
            {errors.map((error, index) => (
              <Box key={index} width="100%">
                <Alert severity="error">{error}</Alert>
              </Box>
            ))}
            <Button onClick={onSubmit} type="submit">
              {t("common.confirm")}
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  );
};
