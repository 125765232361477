import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Container } from "components/layouts/Container";
import { Button } from "components/Button";
import { Separator } from "components/Separator";
import { postForm } from "lib/api/submitForms";
import { ConfirmationSummary } from "./shared/ConfirmationSummary";
import { Spinner } from "components/Spinner";
import { useStore } from "hooks/useStore";
import { Tiles } from "components/layouts/Tiles";
import { LineStepGroup } from "./shared/StepGroups/LineStepGroup";
import { PersonalDataStepGroup } from "./shared/StepGroups/PersonalDataStepGroup";
import { PaymentStepGroup } from "./shared/StepGroups/PaymentStepGroup";
import { formToApi } from "./shared/formToApi";
import { getLineGroupProps } from "./shared/getLineGroupProps";
import { SignUpCard } from "components/Card";
import { useApplicationContext } from "hooks/useApplicationContext";
import { Text } from "components/Text";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useDerivedState } from "hooks/useDerivedState";
import { AcceptTerms } from "components/shared/AcceptTerms";

const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Content = ({ tariffs }) => {
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const lines = useStore((state) => state.lines);
  const isSharedLines = useStore((state) => state.isSharedLines);
  const optingForRole = useStore((state) => state.optingForRole);
  const { t } = useTranslation();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const history = useHistory();
  const { mustPayMonthlyBill, mustPayMemberFee } = useDerivedState();
  const state = useStore((state) => state);
  const { getLinesFromState, internetLineInFormData } =
    useLinesFormDerivedState();
  const [isLoading, setIsLoading] = useState(null);

  const gotoThanks = () => {
    history.push("thanks");
  };

  const onSubmit = async () => {
    const payload = formToApi(
      state,
      getLinesFromState,
      Boolean(internetLineInFormData),
      {
        loggedIn,
        mustPayMonthlyBill,
        mustPayMemberFee,
      }
    );

    try {
      setIsLoading(true);
      await postForm(payload);
      await waitFor(250);

      gotoThanks();
    } catch (e) {
      console.error("Error submiting form!");
      console.error(e);
      gotoThanks();
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const onClickFooter = () =>
    history.push(
      `/signup/data?intent=${encodeURIComponent(
        JSON.stringify({ optingForRole, isNewSignupFlow: false })
      )}`
    );

  return (
    <>
      <Tiles columns={1} spacing={1}>
        {!loggedIn ? (
          <PersonalDataStepGroup optingForRole={optingForRole} confirmMode />
        ) : (
          <SignUpCard
            confirmMode
            title={t("funnel.signup.data.steps.personal_data.title")}
          >
            <Separator />
            <Box
              px={[3, 8]}
              py={3}
              display="flex"
              alignItems={"flex-start"}
              justifyContent="space-between"
              style={{ opacity: 1.0 }}
            >
              <ConfirmationSummary
                i18nPrefix="funnel.signup.data.steps.personal_data.confirmation"
                title={t("funnel.signup.data.steps.personal_data.title_logged")}
                fields={{
                  fullname: currentUser.full_name,
                  email: currentUser.email,
                }}
              />
            </Box>
          </SignUpCard>
        )}
        {lines.map((line, index) => {
          return (
            <LineStepGroup
              confirmMode
              line={line}
              stepsBefore={2}
              tariffs={tariffs}
              index={index}
              isLast={false}
              {...getLineGroupProps(index, lines)}
            />
          );
        })}
        <PaymentStepGroup confirmMode />
        <Box mt={4}>
          <Tiles columns="2" spacing={4}>
            {!isSharedLines && lines.length > 0 ? (
              <Box mt={6}>
                <Text
                  size="sm"
                  semibold
                  selectable={false}
                  underline
                  onClick={() => onClickFooter()}
                >
                  {t("funnel.signup.confirm.reselect_tariffs")}
                </Text>
              </Box>
            ) : (
              <div />
            )}
            <AcceptTerms setIsAccepted={setHasAcceptedTerms} />
            <div />
            <Button
              disabled={!hasAcceptedTerms}
              type="submit"
              onClick={onSubmit}
            >
              {t("common.finish")}
            </Button>
          </Tiles>
        </Box>
      </Tiles>
      <Box height="400px" />
    </>
  );
};

export const Confirm = ({ tariffs }) => (
  <Container variant="narrow">
    <Content tariffs={tariffs} />
  </Container>
);
