import { getAsset, getAssets } from "lib/api/assets";
import { useQuery } from "react-query";

export const useAssets = ({ id, refs, ...settings }) =>
  useQuery({
    querykey: "assets/" + id,
    queryFn: () => getAssets(refs),
    ...settings,
  });

export const useAsset = ({ id, ref, ...settings }) => {
  return useQuery({
    queryKey: "asset/" + id,
    queryFn: () => getAsset(ref),
    ...settings,
  });
};
