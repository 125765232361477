import { Button } from "components/Button";
import { Tiles } from "components/layouts/Tiles";
import { Text } from "components/Text";
import { useTranslation } from "react-i18next";
import { ModalLayout } from "components/layouts/ModalLayout";

export const ModalAdviceOffer = ({ isOpen, onClose, onConfirm, form__id }) => {
  const { t } = useTranslation();
  return (
    <ModalLayout 
    isOpen={isOpen}
    onClose={onClose}
    >
      
        <Text size="xl" lineHeight={1.25} bold>
          {t("common.important")}
        </Text>
        <Text>
          <Text>{t("funnel.shared.advice_offer_modal.title")}</Text>
        </Text>
        <Tiles spacing={8}>
          <Button form={form__id} type="submit" onClick={onConfirm}>
            {t("funnel.shared.advice_offer_modal.accept")}
          </Button>
          <Button onClick={onClose}>
            {t("funnel.shared.advice_offer_modal.decline")}
          </Button>
        </Tiles>
      </ModalLayout>
  );
}