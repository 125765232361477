import axios, { get } from "axios";
import {
  LOGIN_ENDPOINT,
  CHECK_AUTH_ENDPOINT,
  LOGIN_TOKEN_ENDPOINT,
  LOGIN_CHECK_CREDENTIALS,
} from "lib/api/auth";
import { useStore } from "hooks/useStore";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

const setupCSRF = async () => {
  await get(`${axios.defaults.baseURL || ""}/auth/set-csrf-cookie/`);

  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
};

/**
 * This endpoints should be ignored by the sessions expired interceptor.
 */
const skipEndpoints = [
  LOGIN_ENDPOINT,
  CHECK_AUTH_ENDPOINT,
  LOGIN_TOKEN_ENDPOINT,
  LOGIN_CHECK_CREDENTIALS,
];

const setupSessionExpiredInterceptor = () => {
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      const isSkipPromptHeaderPresent = Boolean(
        err.config.headers["X-Prompt-Session-Expired"]
      );

      if (
        !isSkipPromptHeaderPresent &&
        skipEndpoints.includes(err.config.url)
      ) {
        return Promise.reject(err);
      }

      if (err.response.status === 401) {
        const { setSessionExpired } = useStore.getState();
        setSessionExpired();
        return;
      }

      return Promise.reject(err);
    }
  );
};

export async function initializeAxios() {
  setupSessionExpiredInterceptor();
  await setupCSRF();

  window.axios = axios;
}
