import { Box } from "@material-ui/core";
import { SignUpCard } from "components/Card";
import { Separator } from "components/Separator";

export const Section = ({ children, title }) => {
  return (
    <SignUpCard confirmMode title={title}>
      <Separator />
      <Box
        px={[3, 8]}
        py={3}
        display="flex"
        alignItems={"flex-start"}
        justifyContent="space-between"
        style={{ opacity: 1.0 }}
      >
        {children}
      </Box>
    </SignUpCard>
  );
};
