import { Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import { Card } from "components/Card";
import { Button } from "components/Button";
import { Container } from "components/layouts/Container";
import { Text } from "components/Text";
import { PricePreview } from "components/shared/PricePreview";

export const CtaFooter = ({
  footerTranslateY = "0",
  containerTranslateY = "0",
  margingTop = 10,
  isMultipleLines = false,
  linesTotalAmount = "0",
  handleOnClickHire,
  showContract = false,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      minHeight="230px"
      bgcolor={"secondary.main"}
      mt={margingTop}
      style={{
        transform: `translateY(${containerTranslateY}%)`,
      }}
    >
      <Container variant="narrow">
        {showContract && (
          <Box style={{ transform: "translateY(-50%)" }}>
            {isMultipleLines ? (
              <Card>
                <Box>
                  <PricePreview
                    legend={""}
                    details={["totalAmount"]}
                    tariff={{ price: linesTotalAmount }}
                    showSubmit={true}
                    onSubmit={handleOnClickHire}
                  />
                </Box>
              </Card>
            ) : (
              <Button onClick={handleOnClickHire}>
                {t("common.contract")}
              </Button>
            )}
          </Box>
        )}
        {/*Origin : https://trello.com/c/cBrT3Vvi */}
        {false && (
          <Box
            px={8}
            style={{
              transform: `translateY(${footerTranslateY}%)`,
            }}
          >
            <Text>
              <Trans i18nKey="funnel.tariffs.cta_footer">
                <Text bold />
              </Trans>
            </Text>
          </Box>
        )}
      </Container>
    </Box>
  );
};
