import { Field } from "react-final-form";
import { isFunction } from 'lodash'

export const Condition = ({ when, is, children, renderWhenFalse = null }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      const shouldShow = isFunction(is) ? is(value) : is === value;

      return (shouldShow ? children : renderWhenFalse)
    }}
  </Field>
);
