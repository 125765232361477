import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { Menu, MenuItem } from "@material-ui/core";
import { styled } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { theme } from "../../theme";
import "iframe-resizer/js/iframeResizer.contentWindow.js";
import { BrandLogo } from "components/icons/BrandLogo";
import { PhoneIcon } from "components/icons/PhoneIcon";
import { ProfileIcon } from "components/icons/ProfileIcon";
import { Text } from "components/Text";
import { useTranslation } from "react-i18next";
import { Hidden } from "@material-ui/core";
import { IconButton } from "components/IconButton";
import { useApplicationContext } from "hooks/useApplicationContext";
import { makeStyles } from "@material-ui/core/styles";
import { logout } from "lib/api/auth";
import { useAuthContext } from "lib/AuthContext";
import { Select } from "@material-ui/core";
import { StyledInputLabel } from "components/shared/InputCustomizations";

const TopBar = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    height: "80px",
  },
  width: "100%",
  height: "70px",
  background: "white",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 10,
}));

const LogoContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "165px",
    height: "40px",
  },
  width: "125px",
  height: "30px",
}));

const useStyles = makeStyles((theme) => ({
  profileMenu: {
    marginTop: theme.spacing(6),
  },
}));

/**
 * TODO styling
 */
const LocaleSelector = ({ initialLocale }) => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(initialLocale);

  const handleChange = (event) => {
    const lang = event.target.value;
    setLocale(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <div style={{ width: "100px" }}>
      <StyledInputLabel>
        {t("funnel.signup.data.steps.personal_data.confirmation.language")}
      </StyledInputLabel>
      <Select defaultValue={locale} onChange={handleChange}>
        <option value={"ca"}>Català</option>
        <option value={"es"}>Castellano</option>
      </Select>
    </div>
  );
};

function ProfileTopBar() {
  const { t } = useTranslation();
  const style = useStyles();
  const history = useHistory();
  const { currentUser } = useApplicationContext();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const keycloak = useAuthContext();

  async function handleLogoutClick() {
    await logout(() =>
      keycloak.logout({
        redirectUri: `${window.location.origin}/home`,
      })
    );
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Hidden smDown>
        <Box ml={2}>
          <Text color="primary.main" bold>
            {currentUser.full_name}
          </Text>
        </Box>
      </Hidden>
      <IconButton onClick={(e) => setProfileAnchorEl(e.currentTarget)}>
        <ProfileIcon />
      </IconButton>
      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={() => setProfileAnchorEl(null)}
        className={style.profileMenu}
      >
        <MenuItem
          onClick={() => {
            setProfileAnchorEl(null);
            history.push("/home");
          }}
        >
          <Text color="primary.main" semibold>
            {t("summary.title")}
          </Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setProfileAnchorEl(null);
            handleLogoutClick();
          }}
        >
          <Text color="primary.main" semibold>
            {t("common.logout")}
          </Text>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export const TopBarLayout = ({ locale = undefined, children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useApplicationContext();
  const isAuthenticated = Boolean(currentUser);

  if (process.env.REACT_APP_FORM_BUILD) return children;

  return (
    <>
      <Helmet
        bodyAttributes={{
          style: `background-color: ${theme.palette.background.main}`,
        }}
      />
      <TopBar>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <LogoContainer>
            <IconButton onClick={() => history.push("/web-somconnexio")}>
              <BrandLogo width="100%" height="auto" />
            </IconButton>
          </LogoContainer>
          <Box display="flex" flexDirection="row" alignItems="center">
            <PhoneIcon />
            <Hidden smDown>
              <Box ml={2}>
                <Text color="primary.main" bold>
                  {t("funnel.signup.header.call_me")}
                </Text>
              </Box>
            </Hidden>
          </Box>
          {isAuthenticated && <ProfileTopBar />}
          {locale && <LocaleSelector initialLocale={locale} />}
        </Box>
      </TopBar>
      <Box paddingTop="70px">{children}</Box>
    </>
  );
};
