import { uniq } from "lodash";
const roleResolver = ({ optingForRole, coopAgreementCode }) => {
  if (optingForRole === "coop_agreement") {
    return coopAgreementCode === "SC" ? "user" : optingForRole;
  }
  return optingForRole;
};
export const buildAnalyticsParamFromState = (state, currentStep) => {
  let categories = uniq(
    state.lines.map(
      (line) => ({ mobile: "mobil", internet: "internet" }[line.type])
    )
  );
  categories.sort();

  categories = categories.join("");

  if (categories === "internetmobil") {
    categories = "intmobi";
  }

  if (categories === "") {
    categories = "nocontract";
  }

  let currentRole;

  if (!state.currentRole) {
    currentRole = "novaalta";
  } else if (state.currentRole === "member") {
    currentRole = "jasoci";
  } else if (state.currentRole === "sponsored") {
    currentRole = "jaapadrinat";
  }

  return [
    currentStep,
    categories,
    currentRole,
    currentRole === "novaalta" ? roleResolver(state) : "",
  ].join("");
};
