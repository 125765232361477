import React from "react";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";
import { Heading } from "components/Heading";
import { Link } from "components/Link";

export const MaintenanceMode = () => (
  <FullScreenCenteredLayout>
    <Heading>Tornem de seguida! / Volvemos enseguida!</Heading>

    <p>
      En aquests moments estem realitzant tasques de manteniment de la oficina
      virtual. Si ho desitgeu, podeu posar-vos en contacte amb nosaltres a
      través de l'enllaç:<br />
      <Link to="https://somconnexio.coop/contacte/">
        Contacte - Som Connexió
      </Link>
    </p>

    <p>
      En estos momentos estamos realizando tareas de mantenimiento de la oficina
      virtual. Si lo desea puede contactar con nosotros a través de el enlace:<br />
      <Link to="https://somosconexion.coop/contacto/">
        Contacto - Som Connexió
      </Link>
    </p>
  </FullScreenCenteredLayout>
);
