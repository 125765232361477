import { genders, nationalities } from "lib/domain/somconnexio/selections";
import { Condition } from "screens/SignUp/shared/Condition";
import { spainIdType } from "spain-id";

const { Select } = require("components/Select");
const { Text } = require("components/Text");
const { TextField } = require("components/TextField");
const { Tiles } = require("components/layouts/Tiles");
const {
  required,
  composeValidators,
  validPhone,
  matchDateFormat,
  validBirthdate,
  mustBeAdult,
} = require("lib/form/validators");
const { useTranslation } = require("react-i18next");
const { AddressPicker } = require("screens/SignUp/shared/AddressPicker");

export const NewUser = () => {
  const { t } = useTranslation();
  return (
    <>
      <Tiles columns={2} spacing={4}>
        <Select.FormField
          name="lang"
          validate={required}
          options={[
            {
              value: "ca",
              label: "Català",
            },
            {
              value: "es",
              label: "Castellano",
            },
          ]}
          label={t("funnel.signup.data.steps.personal_data.preferred_language")}
        />
        <TextField.FormField
          name="phone"
          mask="000 00 00 00"
          validate={composeValidators(required, validPhone)}
          label={t("funnel.signup.data.steps.personal_data.phone")}
        />
        <Condition when="new_owner_is_company" is={false}>
          <TextField.FormField
            placeholder="dd/mm/aaaa"
            showPlaceHolderMask
            mask="00/00/0000"
            name="birthdate"
            validate={composeValidators(
              required,
              matchDateFormat,
              validBirthdate,
              mustBeAdult
            )}
            label={t("funnel.signup.data.steps.personal_data.birthdate")}
          />
          <Select.FormField
            name="gender"
            validate={required}
            label={t("funnel.signup.data.steps.personal_data.gender")}
            i18nPrefix="funnel.selections"
            options={genders}
          />
        </Condition>
        <Condition
          when="vat_new_owner"
          is={(vat_new_owner = "") => spainIdType(vat_new_owner) === "nie"}
          renderWhenFalse={<div />}
        >
          <Select.FormField
            name="nationality_new_owner"
            validate={required}
            label={t("funnel.signup.data.steps.personal_data.nationality")}
            options={nationalities}
          />
        </Condition>
      </Tiles>
      <Text semibold size="xs" letterSpacing="0.1rem">
        {t("request_change.change_owner.next_data.address")}
      </Text>
      <AddressPicker
        validate={required}
        name="delivery_address"
        label={t("request_change.change_owner.next_data.address_field")}
      />
    </>
  );
};
