import { useStore } from "hooks/useStore";
import React, { useContext } from "react";
import { FormStepContent } from "./FormStepContent";
import { FormStepGroupContext } from "./FormStepGroupContext";
import { noop } from "lib/fn/noop";

export const FormStep = ({
  initialValues,
  onSubmit = noop,
  checkToFormSubmit = () => true,
  id,
  ...props
}) => {
  const calculateNextSteptoGo = useStore(
    (state) => state.calculateNextSteptoGo
  );
  const formStepDataByKey = useStore((state) => state.formStepDataByKey);
  const setFormStepData = useStore((state) => state.setFormStepData);
  const setCurrentIndex = useStore((state) => state.setCurrentIndex);
  const stepInitialValues = formStepDataByKey[id];
  const values = stepInitialValues || initialValues;
  const { confirmMode } = useContext(FormStepGroupContext);

  const onFormSubmit = async (values) => {
    if (!checkToFormSubmit(values)) {
      return;
    }

    setFormStepData(id, values);

    const submitErrors = await onSubmit(values, id);

    if (submitErrors) {
      return submitErrors;
    }
    if (!confirmMode) {
      calculateNextSteptoGo();
    } else {
      setCurrentIndex(null);
    }
  };

  // TODO join these two components, they needed to be split before to be able
  // to use form hooks, but it is not needed anymore.
  return (
    <>
      <FormStepContent
        initialValues={values}
        onSubmit={onFormSubmit}
        isDirty={Boolean(stepInitialValues)}
        id={id}
        {...props}
      />
    </>
  );
};
