import { get } from "axios";

export const getCoopAgreement = async (code) => {
  try {
    const res = await get("/coop_agreement/", {
      params: { code },
    });
    return res.data;
  } catch (err) {
    return {name: null}
  }
}

