import React from "react";
import { PaymentStepGroup } from "./shared/StepGroups/PaymentStepGroup";
import { Container } from "components/layouts/Container";

export const Payment = () => {
  return (
    <Container>
      <PaymentStepGroup />
    </Container>
  );
};
