import { theme } from "theme";
// TODO Review fontSize in mobile
export const Fiber2Mobiles = ({ fontSize }) => (
  <svg
    focusable="false"
    viewBox="0 0 40 20"
    width="1em"
    height="1em"
    aria-hidden="true"
    title="fiber_2_mobiles"
    fontSize={fontSize}
    display="inline-block"
  >
    <path
      d="M17.2 2.9l.8-.8C16.6.7 14.8 0 13 0s-3.6.7-5 2.1l.8.8C10 1.8 11.5 1.2 13 1.2s3 .6 4.2 1.7zm-.9.8c-.9-.9-2.1-1.4-3.3-1.4s-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1 .9 0 1.8.3 2.5 1l.8-.8zM16 10h-2V6h-2v4H2c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM5 15H3v-2h2v2zm3.5 0h-2v-2h2v2zm3.5 0h-2v-2h2v2 M21.4297 6.1875m.0625 0 .4688 0q.0625 0 .0625.0625l0 5.5q0 .0625-.0625.0625l-.4688 0q-.0625 0-.0625-.0625l0-5.5q0-.0625.0625-.0625ZM19.0391 8.7031m.0625 0 5.25 0q.0625 0 .0625.0625l0 .4688q0 .0625-.0625.0625l-5.25 0q-.0625 0-.0625-.0625l0-.4688q0-.0625.0625-.0625Z M36.7 2.8h-5.6c-1.162 0-2.1.938-2.1 2.1v11.2c0 1.162.938 2.1 2.1 2.1h5.6c1.162 0 2.1-.938 2.1-2.1v-11.2c0-1.162-.938-2.1-2.1-2.1zm-1.4 14h-2.8v-.7h2.8v.7zm2.275-2.1h-7.35v-9.8h7.35v9.8zm-.875-12.075v-.525c0-1.162-.938-2.1-2.1-2.1h-5.6c-1.162 0-2.1.938-2.1 2.1v11.2c0 1.162.938 2.1 1.75 2.1v-3.5h-.525v-9.8h7.35v.525z"
      fill={theme.palette.primary.main}
    ></path>
  </svg>
);
