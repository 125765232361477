import React, { useState } from "react";
import { styled, Collapse, Box, Hidden } from "@material-ui/core";
import { Text } from "components/Text";
import { Separator } from "components/Separator";
import { Tiles } from "components/layouts/Tiles";
import { Stack } from "components/layouts/Stack";
import { ChevronDown } from "components/icons/ChevronDown";
import { ChevronUp } from "components/icons/ChevronUp";
import { CircleCheckIcon } from "components/icons/CircleCheckIcon";
import { compact } from "lodash";
import { useTranslation, Trans } from "react-i18next";
import { formatPrice } from "lib/helpers/format";
import { decorateOfferTariffs } from "hooks/queries/helper/tariffDecorator";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useDerivedState } from "hooks/useDerivedState";

const Root = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    background: theme.palette.primary.main,
    position: "fixed",
    top: 70,
    left: 0,
    width: "100%",
    zIndex: 1,
  },
  [theme.breakpoints.up("md")]: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    right: 0,
    top: 0,
    width: "310px",
    height: "100%",
    background: theme.palette.primary.main,
    overflow: "hidden",
  },
}));

const getInitialPayment = (lines, { mustPayMemberFee }) => {
  return compact([
    mustPayMemberFee && { concept: "new_signup", amount: 100 },
    ...lines
      .filter((line) => line.type === "mobile" && !line.has_sim_card)
      .map(() => ({
        concept: "new_sim_card",
        amount: 2.05,
      })),
  ]);
};

const getMonthlyBill = (lines, tariffs) => {
  return lines
    .filter((line) => line?.code)
    .map((line) => {
      let tariffsList = tariffs;
      //TODO peor es morirse
      if (line.code === "SE_SC_REC_MOBILE_PACK_UNL_20480") {
        tariffsList = tariffs.map(decorateOfferTariffs);
      }
      const tariff = tariffsList.find((t) => t.code === line.code);

      return {
        concept: tariff.name,
        amount: Number(tariff.price),
      };
    });
};

// TODO duplicated from tariffs
const Reason = ({ text }) => (
  <Box display="flex" alignItems="center">
    <CircleCheckIcon />
    <Box ml={2}>
      <Text size="sm" color="white">
        {text}
      </Text>
    </Box>
  </Box>
);

const FullSummary = ({
  initialPayments,
  initialTotalAmount,
  monthlyPayments,
  monthlyTotalAmount,
}) => {
  const { t } = useTranslation();
  function reduceDuplicates(collection, key) {
    const count = {};
    collection.forEach((item) => {
      count[item[key]] = count[item[key]] || { ...item, count: 0 };
      count[item[key]].count += 1;
    });
    return Object.values(count);
  }
  return (
    <Box px={6} py={5} mt={5}>
      <Tiles columns={1} spacing={2}>
        <Text color="white" size="sm" uppercase>
          {t("funnel.signup.payment_summary.title")}
        </Text>

        {initialPayments.length > 0 && (
          <>
            <Box my={0}>
              <Text color="white" size="md" semibold uppercase>
                {t("funnel.signup.payment_summary.initial_bill")}
              </Text>
            </Box>
            <Tiles columns={1} spacing={1}>
              {reduceDuplicates(initialPayments, "concept").map((item) => {
                return (
                  <Box display="flex" justifyContent="space-between">
                    <Text color="white" size="sm">
                      <Trans
                        i18nKey={`funnel.signup.payment_summary.concepts.${item.concept}`}
                        count={item.count}
                      >
                        {t(
                          `funnel.signup.payment_summary.concepts.${item.concept}`,
                          { count: item.count }
                        )}
                      </Trans>
                    </Text>

                    <Text size="sm" color="white">
                      {formatPrice(item.amount * item.count)}
                    </Text>
                  </Box>
                );
              })}
            </Tiles>
            <Separator variant="white" />
            <Box display="flex" justifyContent="space-between">
              <Text bold size="sm" color="white">
                {t("common.total")}
              </Text>
              <Text bold size="sm" color="white">
                {formatPrice(initialTotalAmount)}
              </Text>
            </Box>
          </>
        )}

        {monthlyPayments.length > 0 && (
          <>
            <Box my={0}>
              <div>
                <Text color="white" size="md" semibold uppercase>
                  {t("funnel.signup.payment_summary.monthly_bill")}
                </Text>
              </div>
              <div>
                <Text color="white" size="xs" opacity={0.5}>
                  ({t("common.vat_included")})
                </Text>
              </div>
            </Box>

            <Tiles columns={1} spacing={1}>
              {reduceDuplicates(monthlyPayments, "concept").map((item) => {
                return (
                  <Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Text size="sm" color="white">
                          {item.count > 1 && (
                            <>
                              {"(" + item.concept + ")"}
                              <strong>{" x " + item.count}</strong>
                            </>
                          )}
                          {item.count === 1 && item.concept}
                        </Text>
                      </Box>
                      <Text size="sm" color="white">
                        {formatPrice(item.amount * item.count)}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
            </Tiles>
            <Separator variant="white" />
            <Box display="flex" justifyContent="space-between">
              <Text size="sm" bold color="white">
                {t("common.total")}
              </Text>
              <Text size="sm" bold color="white">
                {formatPrice(monthlyTotalAmount)}
              </Text>
            </Box>
          </>
        )}
      </Tiles>
    </Box>
  );
};

export const MobileSummary = ({
  children,
  initialTotalAmount,
  monthlyTotalAmount,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box minHeight="70px" height="auto" position="relative">
      <Box
        p={3}
        height="70px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="absolute"
        justifyContent="space-between"
        width="100%"
        onClick={() => !isOpen && setIsOpen(true)}
      >
        <Box
          style={{ visibility: isOpen ? "hidden" : "visible" }}
          display="flex"
          flex={1}
        >
          <Stack spacing={1}>
            <Text size="sm" color="white" uppercase>
              {t("funnel.signup.payment_summary.initial_bill")}
            </Text>
            <Text bold size="sm" color="white">
              {formatPrice(initialTotalAmount)}
            </Text>
          </Stack>
        </Box>
        <Box
          style={{ visibility: isOpen ? "hidden" : "visible" }}
          display="flex"
          flex={1}
        >
          <Stack spacing={1}>
            <Text size="sm" color="white" uppercase>
              {t("funnel.signup.payment_summary.monthly_bill")}
            </Text>
            <Text bold size="sm" color="white">
              {formatPrice(monthlyTotalAmount)}
            </Text>
          </Stack>
        </Box>
        {!isOpen && (
          <Box display="flex">
            <ChevronDown />
          </Box>
        )}
        {isOpen && (
          <Box display="flex" onClick={() => setIsOpen(false)}>
            <ChevronUp />
          </Box>
        )}
      </Box>
      <Box width="100%">
        <Collapse in={isOpen}>{children}</Collapse>
      </Box>
    </Box>
  );
};

export const PaymentSummary = ({ tariffs }) => {
  const { t } = useTranslation();
  const { mustPayMemberFee } = useDerivedState();
  const { getLinesFromState } = useLinesFormDerivedState();

  const initialPayments = getInitialPayment(getLinesFromState(), {
    mustPayMemberFee,
  });
  const monthlyPayments = getMonthlyBill(getLinesFromState(), tariffs);

  const computeTotal = (list) =>
    list.reduce((sum, { amount }) => sum + amount, 0);

  const initialTotalAmount = computeTotal(initialPayments);
  const monthlyTotalAmount = computeTotal(monthlyPayments);

  return (
    <>
      <Hidden smDown>
        <Root>
          <FullSummary
            initialTotalAmount={initialTotalAmount}
            initialPayments={initialPayments}
            monthlyTotalAmount={monthlyTotalAmount}
            monthlyPayments={monthlyPayments}
          />
          <Box bgcolor="primary.dark" mx={6} px={4} py={3}>
            <Tiles columns={1} spacing={1}>
              <Reason text={t("funnel.signup.payment_summary.reason_1")} />
              <Reason text={t("funnel.signup.payment_summary.reason_2")} />
              <Reason text={t("funnel.signup.payment_summary.reason_3")} />
            </Tiles>
          </Box>
        </Root>
      </Hidden>
      <Hidden mdUp>
        <Root>
          <MobileSummary
            initialTotalAmount={initialTotalAmount}
            monthlyTotalAmount={monthlyTotalAmount}
          >
            <FullSummary
              initialTotalAmount={initialTotalAmount}
              initialPayments={initialPayments}
              monthlyTotalAmount={monthlyTotalAmount}
              monthlyPayments={monthlyPayments}
            />
          </MobileSummary>
        </Root>
      </Hidden>
    </>
  );
};
