export const iconFor = (type) => {
  if (type === "mobile") {
    return "mobile";
  }

  if (type === "adsl" || type === "fiber" || type === "broadband") {
    return "modem";
  }
  if (type === "landline_phone") return "phone";
  return "unknown";
};
