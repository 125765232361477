import React, { useEffect, useState } from "react";
import { Text } from "components/Text";
import { useTranslation } from "react-i18next";

export const ConfirmationSummary = ({
  title,
  fields,
  i18nPrefix,
  labelResolvers
}) => {
  const [isLoading, setIsLoading] = useState(Boolean(labelResolvers));
  const [resolvedLabelsByKey, setResolvedLabelsByKey] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (!labelResolvers) {
      return;
    }

    Promise.all(
      Object.entries(labelResolvers).map(([key, resolver]) => {
        return resolver().then(result => [key, result]);
      })
    ).then(entries => {
      setResolvedLabelsByKey(Object.fromEntries(entries));
    });
  }, [labelResolvers]);

  return (
    <div>
      <Text size="2xs" bold color="secondary.dark" uppercase letterSpacing='0.1rem'>
        {title}
      </Text>
      {Object.entries(fields)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
          const name = t(`${i18nPrefix}.${key}`);
          const hasResolver = Boolean(resolvedLabelsByKey[key]);

          const valueLabel = hasResolver
            ? resolvedLabelsByKey[key].find(item => item.id === value)
                ?.name || value
            : value;

          return (
            <div>
              <Text size="xs">{name}: </Text>
              <Text size="xs" bold>
                {valueLabel}
              </Text>
            </div>
          );
        })}
    </div>
  );
};
