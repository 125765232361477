import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import get from "lodash.get";

const StyledTypography = withStyles(({ palette }) => ({
  root: {
    color: ({ color }) => {
      return get(palette, color.split("."));
    },
    fontSize: 21,
    fontWeight: "bold"
  }
}))(Typography);

export const Subheading = ({
  gutterBottom = false,
  color = "text.main",
  ...props
}) => (
  <StyledTypography
    component="h2"
    color={color}
    gutterBottom={gutterBottom}
    {...props}
  />
);
