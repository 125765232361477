import { removeNonAlphanum, removeSpaces } from "lib/helpers/string";

export function ownerDataToMeta(ownerData) {
  function getRole(role) {
    switch (role) {
      case "partner":
        return "jaSocSoci";
      case "new_partner":
        return "vullFermeSoci";
      case "new_user":
        return "conveniCooperatiu";
      case "sponsorship":
        return "apadrinat";
      default:
        return "";
    }
  }
  function getCompleteName(name, sur_name, last_name) {
    return [name, sur_name, last_name].join(" ");
  }
  const commonData = [
    {
      key: "role_new_owner",
      value: getRole(ownerData.role_new_owner),
    },
    {
      key: "legal_entity",
      value: ownerData.new_owner_is_company ? "organitzacio" : "persona",
    },
    {
      key: "name_new_owner",
      value: ownerData.new_owner_is_company
        ? ownerData.name_new_owner
        : getCompleteName(
            ownerData.name_new_owner,
            ownerData.surname_new_owner,
            ownerData.lastname_new_owner
          ),
    },
    {
      key: "vat_new_owner",
      value: ownerData.vat_new_owner,
    },
    {
      key: "email_new_owner",
      value: ownerData.email_new_owner,
    },
    {
      key: "iban_new_owner",
      value: ownerData.iban_new_owner,
    },
  ];
  const newPartnerData =
    ownerData.role_new_owner === "new_partner"
      ? [
          {
            key: "iban_new_partner",
            value: ownerData.iban_new_partner,
          },
          {
            key: "pays_in_ten_terms",
            value: ownerData.pays_in_ten_terms ? "1" : "0",
          },
        ]
      : [];
  const newSponsorshipData =
    ownerData.role_new_owner === "sponsorship"
      ? [
          {
            key: "sponsor_vat",
            value: ownerData.sponsor_vat,
          },
          {
            key: "sponsor_code",
            value: ownerData.sponsor_code,
          },
        ]
      : [];
  const newUserData =
    ownerData.role_new_owner !== "partner"
      ? [
          {
            key: "lang",
            value: ownerData.lang + "_ES",
          },
          {
            key: "phone_contact",
            value: ownerData.phone,
          },
          {
            key: "address_street_contact",
            value: ownerData.delivery_address.street,
          },
          {
            key: "address_city_contact",
            value: ownerData.delivery_address.city,
          },
          {
            key: "address_state_contact",
            value: ownerData.delivery_address.state,
          },
          {
            key: "address_zip_code_contact",
            value: ownerData.delivery_address.zip_code,
          },
        ]
      : [];
  return [
    ...commonData,
    ...newPartnerData,
    ...newSponsorshipData,
    ...newUserData,
  ];
}

export function ownerDataToSubscriptionRequest(ownerData) {
  return {
    is_company: ownerData.new_owner_is_company,
    vat: ownerData.vat_new_owner,
    name: ownerData.name_new_owner,
    surname: ownerData.surname_new_owner,
    lastname: ownerData.lastname_new_owner,
    email: ownerData.email_new_owner,
    phone: removeSpaces(ownerData.phone),
    tradename: ownerData.new_owner_is_company ? ownerData.name_new_owner : null,
    birthdate: !ownerData.new_owner_is_company
      ? ownerData.birthdate.split("/").reverse().join("-")
      : null,
    gender: ownerData.gender,
    lang: ownerData.lang,
    nationality: ownerData.nationality_new_owner ?? "ES",
    discovery_channel_id: 8,
    iban: removeNonAlphanum(removeSpaces(ownerData.iban_new_owner)), // ?¿? com fem sin tenim 2 ibans en el cas que siga nou soci
    address: {
      street: ownerData.delivery_address.street,
      zip_code: ownerData.delivery_address.zip_code,
      city: ownerData.delivery_address.city,
      state: ownerData.delivery_address.state,
      country: "ES",
    },
    payment_type: ownerData.pays_in_ten_terms ? "split" : "single",
    sponsor_vat:
      ownerData.role_new_owner === "sponsorship" ? ownerData.sponsor_vat : null,
    coop_agreement_code: ownerData.role_new_owner === "new_user" ? "SC" : null,
    source: "website_change_owner",
    lines: [],
  };
}
