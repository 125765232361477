import {
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSharedDataContext } from "../context";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { SharedPricePreview } from "components/shared/SharedPricePreview";
import { useEffect, useState } from "react";
import { Tiles } from "components/layouts/Tiles";
import { Button } from "components/Button";
import { useHistory } from "react-router-dom";
import { Text } from "components/Text";
import { theme } from "theme";
const useStyles = makeStyles((theme) => ({
  productIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(8),
  },
}));
export const Mobiles = () => {
  const styles = useStyles();
  const [linesPricePreview, setLinesPricePreview] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setShouldShowStepper,
    optionalContracts,
    selectedMobileContracts,
    setSelectedMobileContracts,
    packs,
  } = useSharedDataContext();
  const mobileContracts = optionalContracts.mobile;
  setShouldShowStepper(true);
  useEffect(() => {
    const newPrice = packs
      .find(
        (pack) =>
          pack.mobiles_in_pack === (selectedMobileContracts.length > 2 ? 3 : 2)
      )
      .products.find((product) => product.category === "mobile").price;

    setLinesPricePreview(
      selectedMobileContracts.map((selectedMobile) => {
        return {
          ...selectedMobile,
          new_price: newPrice,
          price_difference: true,
        };
      })
    );
    setIsButtonDisabled(selectedMobileContracts.length < 2);
  }, [selectedMobileContracts]);

  const hasMobile = (contractCode) =>
    selectedMobileContracts
      .map((selectedMobile) => selectedMobile.code)
      .indexOf(contractCode);

  const isChecked = (contractCode) => hasMobile(contractCode) !== -1;

  const isItemDisabled = (contractCode) =>
    hasMobile(contractCode) === -1 && selectedMobileContracts.length === 3;

  const onSelect = (contract) => {
    const currentIndex = hasMobile(contract.code);
    const copySelectedMobileContracts = [...selectedMobileContracts];
    if (currentIndex === -1) {
      copySelectedMobileContracts.push(contract);
    } else {
      copySelectedMobileContracts.splice(currentIndex, 1);
    }
    setSelectedMobileContracts(copySelectedMobileContracts);
  };
  return (
    <>
      <Box mt={5}>
        <Typography
          variant={useMediaQuery(theme.breakpoints.down("xs")) ? "body1" : "h6"}
        >
          {t("share_data.mobiles.description")}
        </Typography>
      </Box>
      <Box component={Card} mt={5}>
        {/* TODO react-window FixedSizeList  */}
        <List>
          {mobileContracts.map((contract) => (
            <ListItem
              key={contract.code}
              button
              onClick={() => onSelect(contract)}
              disabled={isItemDisabled(contract.code)}
            >
              <ListItemAvatar>
                <PhoneAndroidIcon className={styles.productIcon} />
              </ListItemAvatar>
              <ListItemText
                id={contract.code}
                primary={
                  <Typography variant="h6">
                    {`${t("common.line")} ${contract.phone_number}`}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2">{`${t("common.with")} ${
                    contract.description
                  }`}</Typography>
                }
              />
              <ListItemSecondaryAction>
                <Checkbox
                  color="primary"
                  checked={isChecked(contract.code)}
                  onChange={() => onSelect(contract)}
                  disabled={isItemDisabled(contract.code)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
      <Typography variant="caption">
        {t("share_data.mobiles.advice")}
      </Typography>
      <SharedPricePreview
        lines={linesPricePreview}
        getPrimaryText={(line) => <Text>{line.phone_number}</Text>}
        getSecondaryText={(line) => (
          <Text size={"sm"}>{`${t("common.with")} ${line.description}`}</Text>
        )}
        getTotalLit={() => (
          <Text size="lg" bold>
            {t("share_data.mobiles.price.total")}
          </Text>
        )}
      />
      <Tiles columns="2" spacing={4}>
        <div />
        <Button
          onClick={() => {
            history.push("fibers");
          }}
          disabled={isButtonDisabled}
        >
          {t("common.continue")}
        </Button>
      </Tiles>
    </>
  );
};
