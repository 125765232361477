/**
 * Some Material UI customizations to customize inputs (mainly Select and TextField)
 */
import { withStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

export const StyledFormControl = withStyles(theme => ({
  root: {
    width: '100%',
    "& .MuiFormLabel-root": {
      "&[data-shrink=false]": {
        transform: "translate(14px, 18px) scale(1)",
      },
      width: '100%',
      color: theme.palette.text.main,
    },
    "& .MuiInputBase-root": {
      height: 50
    },
    "& .Mui-focused": {
      background: "transparent"
    },
    "& input": {
      color: theme.palette.text.main,
    },
    "& fieldset": {
      borderWidth: 1.0,
      borderRadius: 0,
      borderColor: important(theme.palette.text.main)
    }
  }
}))(FormControl);

export const StyledInputLabel = withStyles(theme => ({
  root: {
    color: important(theme.palette.text.main)
  }
}))(InputLabel);

export const important = rule => `${rule} !important`;
