import { IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "./Button";
import { Inline } from "./layouts/Inline";
import { Link } from "./Link";

export const BackButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      onClick={() => history.goBack()}
      variant="text"
      color="primary"
      fullWidth={false}
    >
      <ArrowBackIcon color="primary" />
      {" " + t('common.go_back')}
    </Button>
  );
};
