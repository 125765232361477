import React from "react";
import Box from "@material-ui/core/Box";

export const Inline = ({ children, spacing = 1 }) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {React.Children.map(
        children,
        child =>
          child && (
            <Box display="flex" mr={spacing} mb={spacing}>
              {child}
            </Box>
          )
      )}
    </Box>
  );
};
