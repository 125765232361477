import {
  Typography,
  Accordion,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { noop } from "lib/fn/noop";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionDetailsLayout } from "../layouts/AccordionDetailsLayout";

const useStyles = makeStyles(() => ({
  AccordionSummary: {
    textTransform: "uppercase",
  },
  Accordion: {
    boxShadow: "none",
  },
}));

export const AccordionLayout = ({
  accordionTitle,
  dataQuery = noop,
  queryKey,
  listItem,
  querySettings,
}) => {
  const styles = useStyles();

  return (
    <Accordion
      className={styles.Accordion}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        className={styles.AccordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button" color="textSecondary">
          {accordionTitle}
        </Typography>
      </AccordionSummary>

      <AccordionDetailsLayout
        dataQuery={dataQuery}
        queryKey={queryKey}
        listItem={listItem}
        querySettings={querySettings}
      />
    </Accordion>
  );
};
