import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";

import { getSignupUrl, openUrl } from "lib/helpers/urls";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useQueryParams } from "hooks/useQueryParams";

import { IframeLayout } from "components/layouts/IframeLayout";
import ABTest from "components/ABTest";
import { ModalContractTariff } from "./shared/ModalContractTariff";
import { PersonalizedTariffPicker } from "./views/PersonalizedTariffPicker";
import { RecommendedTariffsPicker } from "./views/RecommendedTariffsPicker";
import { ModalStepCtaTariff } from "./shared/ModalStepCtaTariff";
import { useHistory, useLocation } from "react-router-dom";

export const Tariffs = () => {
  const { type } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isModalContractOpen, setIsModalContractOpen] = useState(false);
  const [isModalContractStepOpen, setIsModalContractStepOpen] = useState(false);

  const loggedIn = Boolean(useApplicationContext()?.currentUser);
  const qp = useQueryParams();
  function delegatedModalOpener(isSharedLines, lines) {
    if (loggedIn || qp.opting_for_role) {
      openUrl(
        getSignupUrl(
          lines,
          qp.opting_for_role,
          qp.coop_agreement_code,
          true,
          isSharedLines
        )
      );
    } else {
      /*
       * AB Test. 100% ModalStepCtaTariff / 0% ModalContractTariff
       */
      const showContractStep = Math.random() <= 1;
      const utmCampaign = showContractStep ? "new_popup" : "classic_popup";
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.get("utm_campaign") !== utmCampaign) {
        urlParams.set("utm_campaign", utmCampaign);
        history.push(location.pathname + "?" + urlParams.toString());
      }
      setIsModalContractOpen(!showContractStep);
      setIsModalContractStepOpen(showContractStep);

      /*
       * Example with ABTest Component
       *
       * The component will set `ab-campaign-2080203097` as url flag for analytic tracking
       *
       * ABTest.resolve({
       *   campaign: "campaign_new_user",
       *   p: 0.8,
       *   A: () => setIsModalContractStepOpen(true),
       *   B: () => setIsModalContractOpen(true),
       * }).then((result, { campaignId, branch }) => {
       *   result();
       *   const query = new URLSearchParams(window.location.search);
       *   query.set(campaignId, branch);
       *   history.push(window.location.pathname + "?" + query.toString());
       * });
       *
       */
    }
  }

  return (
    <IframeLayout showBackgroundRect={false}>
      <Box mt={7} />
      {type === "recommended" ? (
        <RecommendedTariffsPicker
          queryParams={qp}
          openConfirmModal={delegatedModalOpener}
        />
      ) : (
        <PersonalizedTariffPicker
          type={type}
          queryParams={qp}
          loggedIn={loggedIn}
          openConfirmModal={delegatedModalOpener}
        />
      )}
      <ModalStepCtaTariff
        isOpen={isModalContractStepOpen}
        onClose={() => setIsModalContractStepOpen(false)}
      />
      <ModalContractTariff
        isOpen={isModalContractOpen}
        onClose={() => setIsModalContractOpen(false)}
      />
    </IframeLayout>
  );
};
