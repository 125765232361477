import Box from "@material-ui/core/Box";
import { groupBy, maxBy, minBy, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { noop } from "lib/fn/noop";
import { formatDataPlanSize, formatMinutes } from "lib/helpers/format";
import { SliderSelect } from "components/SliderSelect";
import { Subheading } from "components/Subheading";
import { Text } from "components/Text";
import { PricePreview } from "../shared/PricePreview";

const findMatchingTariff = ({ minutes, data, tariffs }) => {
  if (minutes === null || data === null) {
    return null;
  }

  return tariffs.find(
    (tariff) => tariff.data === data && tariff.minutes === minutes
  );
};

export const MobileTariffPicker = ({
  tariffs,
  onChange = noop,
  initialTariffCode,
  showSubmit = false,
}) => {
  const { t } = useTranslation();

  const mobileTariffs = tariffs.filter(({ category }) => category === "mobile");

  const initialTariff = mobileTariffs.find(
    ({ code }) => code === initialTariffCode
  );

  const defaultMinutes =
    initialTariff?.minutes || minBy(tariffs, "minutes").minutes;
  const defaultDataPlan =
    initialTariff?.data ||
    minBy(
      tariffs.filter((tariff) => tariff.minutes === defaultMinutes),
      "data"
    ).data;

  const [selectedMinutes, setSelectedMinutes] = useState(defaultMinutes);
  const [selectedDataPlan, setSelectedDataPlan] = useState(defaultDataPlan);
  const [isOfferSelected, setIsOfferSelected] = useState(false);
  const tariffsByMinutes = groupBy(mobileTariffs, "minutes");
  const tariffsByData = groupBy(mobileTariffs, "data");

  const minutes = sortBy(
    Object.keys(tariffsByMinutes).map((key) => ({
      value: Number(key),
      label: formatMinutes(key),
    })),
    "minutes"
  );

  const availableDataPlans = sortBy(
    Object.keys(tariffsByData).map((key) => ({
      value: Number(key),
      label: formatDataPlanSize(key),
    })),
    "data"
  );

  const matchingTariff = useMemo(
    () =>
      findMatchingTariff({
        minutes: Number(selectedMinutes),
        data: Number(selectedDataPlan),
        tariffs: mobileTariffs,
      }),
    [selectedDataPlan, selectedMinutes, mobileTariffs]
  );

  const { data: maxAllowedData } = maxBy(
    tariffsByMinutes[selectedMinutes],
    "data"
  );

  useEffect(() => {
    if (!matchingTariff) {
      return;
    }
    setIsOfferSelected(matchingTariff?.is_offer_tariff);
    onChange(matchingTariff);
  }, [matchingTariff]);

  useEffect(() => {
    setSelectedMinutes(defaultMinutes);
    setSelectedDataPlan(defaultDataPlan);
  }, [defaultMinutes, defaultDataPlan]);

  return (
    <Box>
      <Subheading color="text.main">
        {t("funnel.tariffs.mobile.subtitle")}
      </Subheading>
      <Box mt={2} mb={6}>
        <Box pb={1}>
          <Text size="xs">{t("funnel.tariffs.mobile.how_many_minutes")}</Text>
        </Box>
        <SliderSelect
          items={minutes}
          value={selectedMinutes}
          onChange={({ value }) => {
            setSelectedMinutes(value);

            const { data: nextDataPlan } = minBy(
              tariffsByMinutes[value],
              "data"
            );

            const isValidTariff = tariffs.find(
              (tariff) =>
                tariff.minutes === value && tariff.data === selectedDataPlan
            );

            if (nextDataPlan < selectedDataPlan || !isValidTariff) {
              setSelectedDataPlan(nextDataPlan);
            }
          }}
        />
      </Box>
      <Box mb={6}>
        <Box pb={1}>
          <Text size="xs">{t("funnel.tariffs.mobile.how_many_megabytes")}</Text>
        </Box>
        <SliderSelect
          value={selectedDataPlan}
          items={availableDataPlans.filter(({ value: data }) =>
            Boolean(
              data >= maxAllowedData ||
                tariffs.find(
                  (tariff) =>
                    tariff.minutes === selectedMinutes && tariff.data === data
                )
            )
          )}
          maxAllowedValue={maxAllowedData}
          onChange={({ value }) => {
            setSelectedDataPlan(value);
          }}
        />
      </Box>
      <PricePreview
        legend={
          isOfferSelected
            ? t("price_tag.category.mobile_offer")
            : t("price_tag.category.mobile")
        }
        details={["minutes", "data"]}
        tariff={matchingTariff}
        showSubmit={showSubmit}
      />
    </Box>
  );
};

MobileTariffPicker.FormField = ({ name, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <MobileTariffPicker
        {...props}
        initialTariffCode={input.value}
        onChange={({ code }) => input.onChange(code)}
      />
    )}
  </Field>
);
