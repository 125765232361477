import { theme } from "theme";
// TODO Review fontSize in mobile
export const Fiber3Mobiles = ({ fontSize }) => (
  <svg
    focusable="false"
    viewBox="0 0 60 20"
    width="1em"
    height="1em"
    aria-hidden="true"
    title="fiber_3_mobiles"
    fontSize={fontSize}
    display="inline-block"
  >
    <path
      d="M25.456 4.292l1.184-1.184C24.568 1.036 21.904 0 19.24 0s-5.328 1.036-7.4 3.108l1.184 1.184C14.8 2.664 17.02 1.776 19.24 1.776s4.44.888 6.216 2.516zm-1.332 1.184c-1.332-1.332-3.108-2.072-4.884-2.072s-3.552.74-4.884 2.072l1.184 1.184c1.036-1.036 2.368-1.48 3.7-1.48 1.332 0 2.664.444 3.7 1.48l1.184-1.184zM23.68 14.8h-2.96V8.88h-2.96v5.92H2.96c-1.628 0-2.96 1.332-2.96 2.96v5.92c0 1.628 1.332 2.96 2.96 2.96h20.72c1.628 0 2.96-1.332 2.96-2.96v-5.92c0-1.628-1.332-2.96-2.96-2.96zM7.4 22.2H4.44v-2.96h2.96v2.96zm5.18 0h-2.96v-2.96h2.96v2.96zm5.18 0h-2.96v-2.96h2.96v2.96M31.716 9.1575m.0925 0 .6938 0q.0925 0 .0925.0925l0 8.14q0 .0925-.0925.0925l-.6938 0q-.0925 0-.0925-.0925l0-8.14q0-.0925.0925-.0925ZM28.1779 12.8806m.0925 0 7.77 0q.0925 0 .0925.0925l0 .6938q0 .0925-.0925.0925l-7.77 0q-.0925 0-.0925-.0925l0-.6938q0-.0925.0925-.0925ZM55.056 7.104h-7.104c-1.4741 0-2.664 1.1899-2.664 2.664v14.208c0 1.4741 1.1899 2.664 2.664 2.664h7.104c1.4741 0 2.664-1.1899 2.664-2.664v-14.208c0-1.4741-1.1899-2.664-2.664-2.664zm-1.776 17.76h-3.552v-.888h3.552v.888zm2.886-2.664h-9.324v-12.432h9.324v12.432zm-1.11-15.318v-.666c0-1.4741-1.1899-2.664-2.664-2.664h-7.104c-1.4741 0-2.664 1.1899-2.664 2.664v14.208c0 1.4741 1.1899 2.664 2.22 2.664v-4.44h-.666v-12.432h9.324v.666zm-2.664-3.552v-.666c0-1.4741-1.1899-2.664-2.664-2.664h-7.104c-1.4741 0-2.664 1.1899-2.664 2.664v14.208c0 1.4741 1.1899 2.664 2.22 2.664v-4.44h-.666v-12.432h9.324v.666z"
      fill={theme.palette.primary.main}
    ></path>
  </svg>
);
