import React, { useState, useEffect } from "react";
import { SidebarLayout } from "components/layouts/SidebarLayout";
import { Trans, useTranslation } from "react-i18next";
import { theme } from "../theme";
import {
  Typography,
  Box,
  makeStyles,
  Stepper,
  Step,
  StepContent,
  Grid,
  ListItem,
} from "@material-ui/core";
import { CircularGraph } from "components/CircularGraph";
import { Tiles } from "components/layouts/Tiles";
import { Text } from "components/Text";
import { Spinner } from "components/Spinner";
import { Separator } from "components/Separator";
import { IconButton } from "components/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useCopyToClipboard } from "react-use";
import { getJoinUrl } from "lib/helpers/urls";
import { AccordionLayout } from "components/layouts/AccordionLayout";
import { useSponsor } from "hooks/queries/useSponsor";

const useStyles = makeStyles((theme) => ({
  codeToSponsor: {
    backgroundColor: theme.palette.background.dark,
    fontWeight: 600,
    padding: theme.spacing(1, 2),
  },
  AccordionSummary: {
    textTransform: "uppercase",
  },
  AccordionDetail: {
    display: "grid",
  },
  Accordion: {
    boxShadow: "none",
  },
  AccordionIcon: {
    color: theme.palette.primary.main,
  },
}));

function TextDescriptionSponsoreds({ sponseesNumber, sponseesMax }) {
  const { t } = useTranslation();

  const remainingSponsoreds = sponseesMax - sponseesNumber;

  return (
    <Tiles spacing={0} columns={1}>
      <Box position="relative">
        <Typography variant="h4">
          {t("sponsor.have_got", {
            sponseesNumber: sponseesNumber,
            sponseesMax: sponseesMax,
          })}
        </Typography>
      </Box>
      <Box position="relative">
        <Typography variant="h5">{t("sponsor.sponsoreds_persons")}</Typography>
      </Box>
      <Box position="relative">
        <Typography variant="subtitle1" color="textSecondary">
          <Trans
            i18nKey={
              remainingSponsoreds !== 0
                ? "sponsor.there_are_left"
                : "sponsor.there_arent_left"
            }
            count={remainingSponsoreds}
          />
        </Typography>
      </Box>
    </Tiles>
  );
}

function FractionCircularGraph({ sponseesNumber, sponseesMax }) {
  return (
    <Box
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      <Text
        size="3xl"
        bold={true}
        color={theme.palette.primary.main}
        textAlign="right"
      >
        {sponseesNumber}
      </Text>
      <Box pt={4}>
        <Text size="md" semibold={true} color={theme.palette.primary.main}>
          {`/${sponseesMax}`}
        </Text>
      </Box>
    </Box>
  );
}

function LabelCircularGraph({ ...props }) {
  const { t } = useTranslation();
  return (
    <>
      <FractionCircularGraph {...props} />
      <Box pt={9}>
        <Text size="md" color={theme.palette.primary.main} bold={true}>
          {t("sponsor.sponsoreds")}
        </Text>
      </Box>
    </>
  );
}

function StepsToSponsor({ codeToSponsor }) {
  const [isCodeSponsorCopied, setIsCodeSponsorCopied] = useState(false);
  const [isUrlSponsorCopied, setIsUrlSponsorCopied] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();
  const { t } = useTranslation();
  const { preferred_locale } = useApplicationContext().currentUser;

  const getUrlJoinSponsored = (app) => {
    if (process.env.NODE_ENV === "production") {
      switch (app) {
        case "telegram":
          return preferred_locale === "es"
            ? "https://somconnexio.coop/apadrinarse"
            : "https://somconnexio.coop/apadrinar-se";
        case "whatsApp":
          return preferred_locale === "es"
            ? "https://somconnexio.coop/te-quiero-apadrinar"
            : "https://somconnexio.coop/et-vull-apadrinar";
        default:
          return preferred_locale === "es"
            ? "somconnexio.coop/quiero-apadrinarte"
            : "somconnexio.coop/vull-apadrinar-te";
      }
    }
    return getJoinUrl(preferred_locale, "sponsored", undefined);
  };

  const getEncodedTextParam = (app) => {
    return encodeURIComponent(
      t("sponsor.message_messenger_service", {
        codeToSponsor: codeToSponsor,
        urlJoinSponsored: getUrlJoinSponsored(app),
      })
    );
  };

  function handleOnClickClipboardUrl(e) {
    e.preventDefault();
    copyToClipboard(e.currentTarget.innerText);
    state.error ? setIsUrlSponsorCopied(false) : setIsUrlSponsorCopied(true);
  }

  function handleOnClickClipboardCode(e) {
    e.preventDefault();
    copyToClipboard(codeToSponsor);
    state.error ? setIsCodeSponsorCopied(false) : setIsCodeSponsorCopied(true);
  }

  function handleOnClickWhatsApp(e) {
    e.preventDefault();
    window.open("https://wa.me/?text=" + getEncodedTextParam("whatsApp"));
  }

  function handleOnClickTelegram(e) {
    e.preventDefault();
    window.open(
      "https://t.me/share/url?url= &text=" + getEncodedTextParam("telegram")
    );
  }

  useEffect(() => {
    isCodeSponsorCopied &&
      setTimeout(() => {
        setIsCodeSponsorCopied(false);
      }, 3000);
  }, [isCodeSponsorCopied]);

  useEffect(() => {
    isUrlSponsorCopied &&
      setTimeout(() => {
        setIsUrlSponsorCopied(false);
      }, 3000);
  }, [isUrlSponsorCopied]);

  return (
    <Stepper orientation="vertical">
      <Step active>
        <StepContent>
          <Grid container>
            <Grid item>
              <Typography>{t("sponsor.steps.first.description")}</Typography>
            </Grid>
            <Grid item>
              <ul>
                <li>
                  <Typography>
                    <Trans i18nKey="sponsor.steps.first.first_action">
                      <IconButton
                        tooltipTitle={t("sponsor.steps.first.url_copied")}
                        onClick={(e) => handleOnClickClipboardUrl(e)}
                        open={isUrlSponsorCopied}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement={"right-end"}
                        size="small"
                      >
                        {{ urlJoinSponsored: getUrlJoinSponsored() }}
                      </IconButton>
                    </Trans>
                  </Typography>
                </li>
                <li>
                  <Text bold color="black">
                    {t("sponsor.steps.first.second_action")}
                  </Text>
                </li>
                <li>
                  <Typography>
                    <Trans i18nKey="sponsor.steps.first.third_action" />
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid container item direction="column" spacing={2}>
              <Grid item container spacing={2} alignItems="flex-end">
                <Grid item>
                  <Typography variant="h5">
                    {t("sponsor.steps.first.code_to_sponsor")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h4"
                    className={useStyles().codeToSponsor}
                  >
                    {codeToSponsor}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    tooltipTitle={t("sponsor.steps.first.code_copied")}
                    onClick={(e) => handleOnClickClipboardCode(e)}
                    open={isCodeSponsorCopied}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement={"right-end"}
                  >
                    <FileCopyIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" color="textSecondary">
                  {t("sponsor.steps.first.small_print")}
                </Typography>
              </Grid>
              <Grid item container spacing={4} alignItems="center">
                <Grid item>
                  <Typography variant="body2">
                    {t("sponsor.steps.first.share_via_social_media")}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    isOutlined
                    onClick={(e) => handleOnClickWhatsApp(e)}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    isOutlined
                    onClick={(e) => handleOnClickTelegram(e)}
                  >
                    <TelegramIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StepContent>
      </Step>
    </Stepper>
  );
}

function SponsorInfo({ sponsorshipInfo }) {
  const { sponsees_number: sponseesNumber, sponsees_max: sponseesMax } =
    sponsorshipInfo;

  return (
    <>
      <Tiles spacing={0}>
        <Box textAlign="center">
          <CircularGraph
            value={sponseesNumber}
            maxValue={sponseesMax}
            labelGenerator={() =>
              LabelCircularGraph({ sponseesNumber, sponseesMax })
            }
            marginBottomLabel={40}
          />
        </Box>
        <Box pt={4}>
          <TextDescriptionSponsoreds
            sponseesNumber={sponseesNumber}
            sponseesMax={sponseesMax}
          />
        </Box>
      </Tiles>
    </>
  );
}

function SponsoredPeople({ sponsees }) {
  const { t } = useTranslation();
  return (
    <AccordionLayout
      accordionTitle={t(`sponsor.sponsoreds_persons`)}
      dataQuery={() => Promise.resolve(sponsees)}
      queryKey={"/api/sponsor/"}
      listItem={(sponsee) => (
        <ListItem divider={true}>
          <Grid>
            <Grid sponsee>
              <Typography display="inline" variant="overline">
                {sponsee}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
    />
  );
}

export const Sponsor = () => {
  const { t } = useTranslation();
  const currentUser = useApplicationContext().currentUser;
  const {
    data: sponsorshipInfo,
    isSuccess,
    isLoading,
  } = useSponsor({
    userName: currentUser.username,
  });
  return (
    <SidebarLayout>
      {isLoading && <Spinner />}
      {isSuccess && (
        <Tiles spacing={4} columns={1}>
          <Typography variant="h4" component="h1" align="left">
            {t("sponsor.description")}
          </Typography>
          <SponsorInfo sponsorshipInfo={sponsorshipInfo.data} />
          {!sponsorshipInfo.data.is_full_sponsees && (
            <Box textAlign="left">
              <StepsToSponsor
                codeToSponsor={sponsorshipInfo.data.sponsorship_code}
              />
            </Box>
          )}
          {Boolean(sponsorshipInfo.data.sponsees.length) && (
            <SponsoredPeople sponsees={sponsorshipInfo.data.sponsees} />
          )}
        </Tiles>
      )}
    </SidebarLayout>
  );
};
