import React, { useEffect, useRef, useContext, createContext } from "react";
import MaterialSlider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core";
import { Tooltip } from "components/Tooltip";

const step = 10;

const LabelContext = createContext({});

const getItemFromSliderValue = (marks, sliderValue) => {
  const { item } = marks.find(mark => mark.value === sliderValue);

  return item;
};

const getPercentageFromValue = (marks, value) => {
  const index = marks.findIndex(({ item }) => item.value === value);

  return (index / (marks.length - 1)) * 100;
};

const styles = theme => ({
  root: {
    height: 4,
    paddingBottom: theme.spacing(7),
    marginBottom: 0
  },
  mark: {
    backgroundColor: theme.palette.text.main,
    borderRadius: 0,
    marginTop: 1
  },
  markActive: {
    backgroundColor: "white"
  },

  //horrendous hack to align first/last mark
  markLabel: {
    paddingTop: theme.spacing(3),
    opacity: 0.5,
    color: theme.palette.text.main,
    "&[data-index='0']": {
      transform: "translateX(-10px)"
    },
    "&[data-index][style='left: 100%;']": {
      transform: "translateX(calc(-100% + 4px))"
    },
    [theme.breakpoints.down("sm")]: {
      "&[data-index]": {
        visibility: "hidden"
      },
      "&[data-index='0']": {
        visibility: "visible"
      },
      "&[data-index][style='left: 100%;']": {
        visibility: "visible"
      }
    }
  },
  markLabelActive: {
    "&[data-mark-active]": {
      opacity: 1.0
    }
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: -8,
    marginLeft: -10
  },
  track: {
    marginLeft: -1,
    height: 4,
    borderRadius: 4
  },
  rail: props => ({
    backgroundColor: theme.palette.background.dark,
    paddingRight: 3,
    borderRadius: 4,
    height: 4,
    "&::after": {
      position: "absolute",
      left: 0,
      width: props.maxAllowedValue
        ? `${getPercentageFromValue(props.marks, props.maxAllowedValue)}%`
        : "100%",
      height: 4,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
      content: "''"
    }
  })
});

const StyledSlider = withStyles(styles)(MaterialSlider);

const getSliderProps = items => {
  return {
    step,
    max: (items.length - 1) * step,
    marks: items.map((item, index) => ({
      value: index * step,
      label: item.label,
      item
    }))
  };
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <LabelContext.Provider value={{ open, value }}>
      {children}
    </LabelContext.Provider>
  );
}

function ThumbComponent(props) {
  const { open, value } = useContext(LabelContext);

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      title={value}
      arrow
      placement="top"
      PopperProps={{
        disablePortal: true,
      }}
    >
      <div {...props} style={{...props.style }}/>
    </Tooltip>
  );
}

export const SliderSelect = ({
  value,
  items,
  maxAllowedValue = null,
  onChange
}) => {
  const ref = useRef(null);

  const sliderProps = getSliderProps(items);

  const selectedMark =
    sliderProps.marks.find(mark => {
      return mark.item.value === value;
    }) || sliderProps.marks[0];

  /**
   * We need to modify markup to be able to highlight current mark :see-no-evil:
   *
   * Data attributes are safe to modify though
   */
  useEffect(() => {
    const labels = ref.current.querySelectorAll(".MuiSlider-markLabelActive");

    if (labels.length === 0) {
      return;
    }

    labels.forEach(el => el.removeAttribute("data-mark-active"));

    labels[labels.length - 1].setAttribute("data-mark-active", "");
  }, [value]);

  return (
    <StyledSlider
      ref={ref}
      valueLabelFormat={value => {
        return getItemFromSliderValue(sliderProps.marks, value).label;
      }}
      valueLabelDisplay="auto"
      ValueLabelComponent={ValueLabelComponent}
      ThumbComponent={ThumbComponent}
      value={selectedMark.value}
      onChange={(_, sliderValue) => {
        const item = getItemFromSliderValue(sliderProps.marks, sliderValue);

        if (maxAllowedValue !== null && item.value > maxAllowedValue) {
          return;
        }

        onChange(item);
      }}
      maxAllowedValue={maxAllowedValue}
      {...sliderProps}
    />
  );
};
