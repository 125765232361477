import { filter, find, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { useStore } from "hooks/useStore";
import { noop } from "lib/fn/noop";
import { formatBandwith } from "lib/helpers/format";
import { Tiles } from "components/layouts/Tiles";
import { Select } from "components/Select";
import { SliderSelect } from "components/SliderSelect";
import { Subheading } from "components/Subheading";
import { Text } from "components/Text";
import { PricePreview } from "../shared/PricePreview";

const PRODUCT_QUERIES = {
  fiber: {
    category: "fiber",
    has_landline_phone: true,
  },
  fiber_no_landline: {
    category: "fiber",
    has_landline_phone: false,
  },
  /* N's team have agreed to stop offering ADSL, 
     as it is an outdated service that providers do not support.
  adsl: {
    category: "adsl",
    has_landline_phone: false,
  },
  */
  router4G: {
    category: "4G",
    has_landline_phone: false,
  },
};

/**
 * Filters products from catalog from a set of predicates
 */
const ProductQuerySelector = ({ onChange, value, error }) => {
  const { t } = useTranslation();

  return (
    <Select
      onChange={onChange}
      value={value}
      error={error}
      helperText={
        value === undefined ? t("common.errors.validation.required") : null
      }
      label={t("funnel.tariffs.internet.what_do_you_want")}
      options={Object.entries(PRODUCT_QUERIES).map(([key, value]) => ({
        value,
        label: t(`funnel.tariffs.internet.categories.${key}`),
      }))}
    />
  );
};

const BandwidthSelector = ({ tariffs, value, onChange }) => {
  const items = sortBy(
    tariffs.map((tariff) => ({
      value: Number(tariff.bandwidth),
      label: formatBandwith(tariff.bandwidth),
      code: tariff.code,
    })),
    "value"
  );

  const { t } = useTranslation();

  return (
    <>
      <Box pb={1}>
        <Text size="xs">{t("funnel.tariffs.internet.what_speed")}</Text>
      </Box>
      <SliderSelect items={items} value={value} onChange={onChange} />
    </>
  );
};

const legendForPriceTag = (productQuery) => {
  switch (productQuery.category) {
    case "adsl":
      return "price_tag.category.adsl";
    case "4G":
      return "price_tag.category.4G";
    // fiber
    default:
      return (
        "price_tag.category." +
        "fiber" +
        (productQuery.has_landline_phone ? "" : "_no_landline")
      );
  }
};

export const InternetTariffPicker = ({
  tariffs,
  initialTariffCode,
  onChange = noop,
  showSubmit = false,
  error = false,
}) => {
  const initialTariff = tariffs.find(({ code }) => code === initialTariffCode);

  const [productQuery, setProductQuery] = useState(
    initialTariff
      ? find(PRODUCT_QUERIES, {
          category: initialTariff.category,
          has_landline_phone: initialTariff.has_landline_phone,
        })
      : undefined
  );

  const filteredTariffs = filter(tariffs, productQuery);

  const [tariffCode, setTariffCode] = useState(
    initialTariff?.code || filteredTariffs[0].code
  );
  const [bandwidth, setBandwidth] = useState(
    initialTariff?.bandwidth || filteredTariffs[0].bandwidth
  );

  const matchingTariff = useMemo(() => {
    let result;

    if (!productQuery) {
      return { code: undefined, price: 0, bandwidth: 0 };
    }

    if (productQuery.category === "fiber") {
      result = tariffs.find(
        (tariff) =>
          tariff.has_landline_phone === productQuery.has_landline_phone &&
          tariff.bandwidth === bandwidth
      );
    } else {
      result = tariffs.find((tariff) => tariff.code === tariffCode);
    }
    return result || initialTariff;
  }, [tariffs, tariffCode, bandwidth, productQuery, initialTariff]);

  const setHasLandlinePhone = useStore((state) => state.setHasLandlinePhone);
  const setInternetTechnology = useStore(
    (state) => state.setInternetTechnology
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!matchingTariff) {
      return;
    }
    onChange(matchingTariff);
  }, [matchingTariff]);

  // Decide if a tariff variant selector component is needded and which to use
  function tariffVariantSelectors() {
    // Skip if no variants
    if (filteredTariffs.length <= 1) return;

    // Switch selector by category
    if (productQuery.category === "adsl") {
      return (
        <Box mt={4}>
          <Tiles spacing={4} columns={2}>
            <Select
              label="Selecciona tarifa de ADSL"
              value={initialTariffCode || filteredTariffs[0].code}
              options={filteredTariffs.map((tariff) => ({
                value: tariff.code,
                label: tariff.name,
              }))}
              onChange={(event) => {
                setTariffCode(event.target.value);
              }}
            />
          </Tiles>
        </Box>
      );
    } else {
      return (
        <Box mt={4}>
          <BandwidthSelector
            value={bandwidth}
            onChange={({ value, code }) => {
              setBandwidth(value);
              setTariffCode(code);
            }}
            tariffs={filteredTariffs}
          />
        </Box>
      );
    }
  }

  return (
    <>
      <Box mb={4}>
        <Subheading color="text.main">
          {t("funnel.tariffs.internet.subtitle")}
        </Subheading>
      </Box>
      <Tiles spacing={4} columns={2}>
        <ProductQuerySelector
          value={productQuery}
          error={error}
          onChange={(event) => {
            const nextQuery = event.target.value;
            const nextDefaultTariff = find(tariffs, nextQuery);

            setTariffCode(nextDefaultTariff.code);
            setBandwidth(nextDefaultTariff.bandwidth);
            setProductQuery(nextQuery);
          }}
        />
        <div />
      </Tiles>
      {productQuery ? (
        <>
          {tariffVariantSelectors()}
          <Box mt={4}>
            <PricePreview
              legend={t(legendForPriceTag(productQuery))}
              details={
                matchingTariff.category === "4G" ? ["data"] : ["bandwidth"]
              }
              tariff={matchingTariff}
              showSubmit={showSubmit}
              onSubmit={() => {
                setHasLandlinePhone(matchingTariff.has_landline_phone);
                setInternetTechnology(matchingTariff.category);
              }}
            />
          </Box>
        </>
      ) : (
        void 0
      )}
    </>
  );
};

InternetTariffPicker.FormField = ({ name, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <InternetTariffPicker
        {...props}
        initialTariffCode={input.value}
        onChange={({ code }) => input.onChange(code)}
      />
    )}
  </Field>
);
